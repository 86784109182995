import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AdminUsers({ users, regions }) {
  const [showUsers, setShowUsers] = useState(false);

  const displayRegion = (user) => {
    const region = regions.filter(region => region.id === user.region || (user.region.id && user.region.id === region.id));
    if (!region.length > 0) {
      return <span>Region not found for {user.region}</span>;
    }
    return <a className="underline" href={`/region/${region[0].urlName}`}>{region[0].name}</a>;
  };

  const handleDeleteUser = async (user) => {
    if (!window.confirm(`Confirm delete user @${user.username} with ${user.manages?.length || 0} managed and ${user.owns?.length || 0} owned.`)) return;
    if (!window.confirm(`Again confirm delete user @${user.username}`)) return;

    try {
      const response = await fetch(`/api/user/${user.id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.ok) {
        alert('User deleted, refresh page');
      } else {
        const data = await response.json();
        alert('Error');
        console.error(data);
      }
    } catch (error) {
      console.error(error);
      alert('Error');
    }
  };

  const handleResetPassword = async (user) => {
    if (!window.confirm(`Confirm reset password for user @${user.username}.`)) return;

    try {
      const response = await fetch(`/api/newpassword/${user.id}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.ok) {
        alert('Password reset');
      } else {
        alert('Error');
      }
    } catch (error) {
      console.error(error);
      alert('Error');
    }
  };

  const handleReconfirmEmail = async (user) => {
    if (user.isConfirmed) return;

    try {
      const response = await fetch(`/api/reconfirm/${user.id}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.ok) {
        alert('Confirmation email sent');
      } else {
        alert('Error');
      }
    } catch (error) {
      console.error(error);
      alert('Error');
    }
  };

  return (
    <div>
      <button onClick={() => setShowUsers(!showUsers)} className="underline text-blue-600">
        {showUsers ? 'Close Users' : 'Show Users'}
      </button>

      {showUsers && users && (
        <div className="mt-5">
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">Username</th>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Region</th>
                <th className="border border-gray-300 px-4 py-2">Email</th>
                <th className="border border-gray-300 px-4 py-2">Owns</th>
                <th className="border border-gray-300 px-4 py-2">Manages</th>
                <th className="border border-gray-300 px-4 py-2">Edits</th>
                <th className="border border-gray-300 px-4 py-2">API Key</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id} className="bg-white">
                  <td className="border border-gray-300 px-4 py-2">
                    <Link to={`/user/${user.username}`} className="underline text-blue-600">
                      @{user.username}
                    </Link>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">{user.name || 'N/A'}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {user.region ? displayRegion(user) : 'Role: ' + user.role}
                  </td>
                  <td className={`border border-gray-300 px-4 py-2 ${user.isConfirmed ? 'text-green-500' : 'text-red-500'}`}>
                    {user.email || 'N/A'}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">{user.owns?.length || 0}</td>
                  <td className="border border-gray-300 px-4 py-2">{user.manages?.length || 0}</td>
                  <td className="border border-gray-300 px-4 py-2">{user.numberOfEdits || 0}</td>
                  <td className="border border-gray-300 px-4 py-2">{user.apiKey ? 'Yes' : 'No'}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="space-y-2">
                      {!user.isConfirmed && (
                        <button
                          className="bg-green-200 p-1 m-1 shadow-md rounded-full hover:bg-green-600"
                          onClick={() => handleReconfirmEmail(user)}
                        >
                          Send Confirmation Email
                        </button>
                      )}
                      <button
                        className="bg-yellow-200 p-1 m-1 shadow-md rounded-full hover:bg-yellow-600"
                        onClick={() => handleResetPassword(user)}
                      >
                        Reset Password
                      </button>
                      {user.role !== 'superadmin' && (
                        <button
                          className="bg-red-200 p-1 m-1 shadow-md rounded-full hover:bg-red-600"
                          onClick={() => handleDeleteUser(user)}
                        >
                          Delete User
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AdminUsers;
