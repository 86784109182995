import React, { useState } from 'react';
import ConfirmModal from '../utilities/ConfirmModal';
function DeletePlace ({ place, onChange }) {
  const [showModal, setShowModal] = useState(false); // State for controlling modal visibility

  const handleDelete = async (placeId) => {

    try {
      const response = await fetch('/api/place/' + placeId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include', // Include cookies
        },
      });
  
      if (response.status === 201) {
        //setErrorMessage('Place Deleted');
        onChange();
      } else if (response.status === 401) {
        // Handle unauthorized access
      } else {
        // Handle other errors
      }
    } catch (error) {
      //setErrorMessage('Error deleting place');
      console.error('Error deleting place:', error);
    }
  };


  const handleDeleteClick = (placeId) => {
    setShowModal(true); // Open the modal
  };

  const handleConfirmDelete = () => {
    if (place) {
      handleDelete(place.id); // Call the delete function when confirmed
    }
    setShowModal(false); // Close the modal after deletion
  };

  return (
    <div className="text-center">
     
        <span>
          <button
            onClick={() => handleDeleteClick(place.id)} // Show the modal instead of deleting immediately
            className="inline-block m-2 bg-red-500 hover:bg-red-900 hover:text-white border rounded-full border-black p-1 py-1 px-3 text-sm"
          >
            Delete {place.name}
          </button>
        </span>
    

      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)} // Close the modal without deleting
        onConfirm={handleConfirmDelete} // Handle actual deletion
        title={`Confirm Delete ${place.name}`}
        message={`Are you sure you want to delete ${place.name} and all attached information?`}
        verifyText={place.name} // Verify the deletion by typing the place name
      />
    </div>
  );
};

export default DeletePlace;
