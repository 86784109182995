import React from 'react';

function ProgressBar({ progress }) {
  const progressBarStyle = {
    width: `${progress * (1/6)*100}%`,
    backgroundColor: 'black',
    height: '20px',
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#ddd' }}>
      <div style={progressBarStyle}></div>
    </div>
  );
}
export default ProgressBar;