import React, {useEffect} from 'react';
function About() {
  useEffect(() => {
    document.title = `About - Zeitgeists`;
    window.scrollTo(0, 0);

  }, []);
  return (
<div className="max-w-3xl mx-auto p-6 mt-20">
      <h1 className="text-3xl font-bold mb-6">About</h1>
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Background</h2>
        <p></p>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Now</h2>
        <p></p>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Future</h2>
        <p></p>
      </section>
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Follow</h2>
        <p><a href="https://www.instagram.com/zeitgeistsorg/" className="text-blue-600 underline">Instagram</a></p>
        <p><a href="https://x.com/zeitgeistsorg" className="text-blue-600 underline">Twitter</a></p>
        <p><a href="https://github.com/zeitgeistsorg/" className="text-blue-600 underline">Github</a></p>
      </section>
      <section className="mb-8" id="contact">
        <h2 className="text-xl font-semibold mb-2">Contact</h2>
        <p><a href="mailto:contact@zeitgeists.org" className="text-blue-600 underline">contact@zeitgeists.org</a>.</p>
      </section>

    </div>

  );
}

export default About;



