import React, { useState,useEffect } from 'react';
import { useForm } from "react-hook-form"
import ProfileImage from '../user/ProfileImage';
//add confirm and fix external links
function EditPlace({onChange, place,region}) {
    const [errorMessage,setErrorMessage] = useState('');
    const {  register, handleSubmit, setValue } = useForm({
  });

  function SuccessMessage() {
    return (
      <div className="border text-xl border-green-400 rounded  bg-green-100 px-4 py-3 text-green-700">

Place details edited successfully      
</div>
    );
  }
  function FailMessage() {
    return (
      <div className="border text-xl border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
      Error editing place.
      </div>
    );
  }

      useEffect(() => {
        if (place) {
          // Set all values on form initialization
          Object.entries(place).forEach(([key, value]) => {
            if (key === 'geo') {
              setValue('latitude', value.latitude);
              setValue('longitude', value.longitude);
            } else if (key !== 'sameAs' && value !== null) {
              setValue(key, value);
            }
          });
         
        }
      }, [place, setValue]);

      const [selectedFile, setSelectedFile] = useState(null);
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          if (file.size <= 5 * 1024 * 1024) {
            // File size is within the limit
            setSelectedFile(file);
          } else {
            // File size exceeds the limit
            alert('File size exceeds the 5MB limit');
          }
        }
      };
      const onSubmit = async (data) => {    
        if (window.confirm("Confirm edit place")!==true) {
          return;
      }
       

        try {
          const formDataToSend = new FormData();
    
          // Append all data entries to formDataToSend
       // Append all data entries except for 'sameAs' to formDataToSend
  for (const [key, value] of Object.entries(data)) {
    if (value !== 'null') {
      formDataToSend.append(key, value);
    }
  }

  // Append 'sameAs' array as a JSON string

          // Append geo object if latitude and longitude are provided
         
          if (selectedFile) {
            formDataToSend.append('image', selectedFile);
          }
          
          // Send a POST request to your server to save the data
          const response = await fetch(`/api/region/${region.urlName}/place/${place.urlName}`, {
            method: 'POST',
            credentials: 'include', // Include cookies
            body: formDataToSend,
          });
          
          if (response.ok) {
            // Reset the form after successful submission
            setErrorMessage(<SuccessMessage />);
            onChange();
    
            // Handle success or navigate to a different page
          } else {
            setErrorMessage(<FailMessage/>)
            console.error('Error editing place:', response.statusText);
          }
        } catch (error) {
          setErrorMessage(<FailMessage/>)
          console.error('Error editing place:', error);
        }

      }


  return (
    <div className='m-4'>
            <p className="text-lg pb-4">Settings:</p>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">

        <div className="mb-6">

     <label className="block mb-2 text-sm font-medium text-gray-900 "> Place Name: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("name", { required: true })} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url", { required: true })} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Description: </label>
        <textarea  rows="5" className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("description")} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Street Address: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("streetAddress", {required: true})} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">City: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("addressLocality", {required: true})} />   
     </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">State Acronym: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("addressRegion", { pattern: /^[A-Za-z]{2}$/ } , {required: true})} />   
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Postal Code: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("postalCode", { pattern: /^\d{5}$/ }, {required: true})} />   
        </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Neighborhood: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lgrounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("area")} />   
     </div>

     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Email: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("email")} />   
     </div>

        <div>
          <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 ">
            {place.image ? "Select a new place image (5MB max):" : "Select place image (5MB max):"}
          </label>
          {place.image && <div className='text-center'>Current Place Image:<div className='flex items-center text-center justify-center'><ProfileImage url={place.image} alt={place.alt} /></div></div>}

          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleFileChange}
            className="input-style mt-2"
          />
          {selectedFile && (
            <p className="mt-1">Selected File: {selectedFile.name}</p>
          )}
        </div>

        {/* Alt Text */}
        <div>
          <label htmlFor="alt" className="block mb-2 text-sm font-medium text-gray-900 ">
            Alt text for image:
          </label>
          <input
            type="text"
            id="alt"
            name="alt"
            {...register("alt")}
            className="input-style"
          />
        </div>
        <div className="mb-6">
        <label  className="block mb-2 text-sm font-medium text-gray-900 ">
            Coordinates:
          </label>
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-2 sm:col-span-1">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">Latitude: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("latitude")} />   
            </div>
            <div className="col-span-2 sm:col-span-1">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">Longitude: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("longitude")} />   
            </div>
          </div>
    
     </div>


     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Accessibility Information: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("accessibility")} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Scraper Repository: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("repo")} />   
     </div>
        {/* Submit Button */}
        <button type="submit" className="button-style  font-bold text-xl border p-2 border-black rounded hover:bg-black hover:text-white">
          Save Place Detail Edits
        </button>
      </form>

      {/* Error Message */}
      {errorMessage}
    </div>
  )};
  export default EditPlace