 import React from "react";
 import { Link } from "react-router-dom";
 import DisplayUsers from "../utilities/DisplayUsers";
 function RegionInfo({region,home}) {
    return (
        <div className="m-4 text-center">

            {!home&&<div className="text-right">   
            <Link to={`/region/${region.urlName}/edit`} className="inline-block mt-2 bg-yellow-500 hover:bg-yellow-900 hover:text-white  border rounded-full border-black p-1 ">
              Edit Region
            </Link>
            </div>}
                  
            <h2 className="text-3xl font-semibold mt-4"><Link to={`/region/${region.urlName}`}>{region.name}</Link></h2>
      
            {region.council && region.council.length > 0 && (
              <div className="mt-2">
                Council:
                <DisplayUsers ids={region.council}/>
              </div>
            )}
                      {region.timezone && (
              <p className="mt-2">Timezone: {region.timezone}</p>
            )}
   
                {region.description && (
              <p className="mt-2">Description: {region.description}</p>
            )}
           
        </div>
    )
}
export default RegionInfo;