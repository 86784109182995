import React, { useState, useEffect, useRef,useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const { isAuthenticated, role } = useContext(AuthContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false); // Close menu on location change
  }, [location]);




  return (
    <nav ref={navRef} className="bg-black text-white fixed top-0 z-50 w-full p-3">
      <div className="flex justify-between items-center">
        <div>
          {location.pathname !== '/' &&
            <Link to="/" className="">
            <p className="text-lg font-medium">Zeitgeists.org</p>
          </Link>
}
        </div>
        <div className={`${!isOpen && `md:hidden`}`}>
          <button onClick={toggleMenu} className="font-light text-2xl m-2">
            {isOpen ? '↓' : '↑'}
          </button>
        </div>
        <div
          className={`${
            isOpen
              ? 'flex absolute top-14 left-0 w-full bg-black flex-col items-start py-3 pl-2 border-white border-b-2 '
              : 'hidden'
          } md:flex md:items-center md:justify-center md:space-x-4 md:relative text-xl`}
        >
              <Link
            to="/faq"
            className={`hover:underline ${location.pathname === '/faq' && 'underline'}`}
            onClick={() => isOpen && toggleMenu()}
          >
            FAQ
          </Link>
          <Link
            to="/api"
            className={`hover:underline ${location.pathname === '/api' && 'underline'}`}
            onClick={() => isOpen && toggleMenu()}
          >
            API
          </Link>
          <Link
            to="/listings"
            className={`hover:underline ${location.pathname.includes('/listings') && ' underline'}`}
            onClick={() => isOpen && toggleMenu()}
          >
            Listings
          </Link>

          <Link
            to="/regions"
            className={`hover:underline ${location.pathname === '/regions' && ' underline'}`}
            onClick={() => isOpen && toggleMenu()}
          >
            Regions
          </Link>
          {isAuthenticated !== false ? (
            <>
              {(role === 'admin' || role === 'superadmin') && (
                <Link
                  to="/admin"
                  className={`hover:underline ${location.pathname === '/admin' && ' underline'}`}
                  onClick={() => isOpen && toggleMenu()}
                >
                  Admin
                </Link>
              )}
              <Link
                to="/dashboard"
                className={`hover:underline ${location.pathname === '/dashboard' && 'underline'}`}
                onClick={() => isOpen && toggleMenu()}
              >
                Dashboard
              </Link>
              <Link
                to="/user"
                className={`hover:underline ${location.pathname === '/user' && 'underline'}`}
                onClick={() => isOpen && toggleMenu()}
              >
                Profile
              </Link>
              <Link
                to="/logout"
                className={`hover:underline ${location.pathname === '/logout' && 'underline'}`}
                onClick={() => isOpen && toggleMenu()}
              >
                Sign Out
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className={`hover:underline ${location.pathname === '/login' && 'underline'}`}
                onClick={() => isOpen && toggleMenu()}
              >
                Login
              </Link>
              <Link
                to="/signup"
                className={`hover:underline ${location.pathname === '/signup' && 'underline'}`}
                onClick={() => isOpen && toggleMenu()}
              >
                Sign Up
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
