import React, { useEffect, useState } from 'react';
import OnBoardingBlobs from '../../components/utilities/OnBoardingBlobs';
import { useNavigate,useLocation } from 'react-router-dom';


function OnBoarding() {
    const [currentScreen, setCurrentScreen] = useState(1);
    const totalScreens = 6;
    const [currentBlobIndex, setCurrentBlobIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const onboardingScreensData = {
        1: {
            title: 'Welcome! Thank you for joining Zeitgeists.',
            description: 'Let’s get started with a quick overview.',
            body: 'These tools are designed for optimal use on a computer. If you’re on a mobile device, we recommend switching to a desktop for the best experience.',
            buttonText: 'Next',
            blobs: [
                {
                    label: 'Accessing',
                    color: 'bg-sky-400',
                    text: "Zeitgeists is a nonprofit cultural events aggregator open to all. Most people using our tools won’t need an account! Open access to structured cultural event data is now available."
                },
                {
                    label: 'Demonstrating',
                    color: 'bg-emerald-400',
                    text: "There’s much to explore. This is an evolving experiment, that includes your interaction."
                },
                {
                    label: 'Curating',
                    color: 'bg-indigo-400',
                    text: "Not every event is here. Our focus is on capturing the cultural spirit of our places and time. We’re developing new ideas, both mental and technical, to find clarity. These efforts take time and a thoughtful community, and we thank you for contributing to both."
                }
            ],
        },
        2: {
            title: 'Accessing Data',
            description: 'Discover how to access and utilize data.',
            body: 'Free data is provided through interfaces, feeds, and an API. Hopefully, this will lead to more meaningful cultural experiences.',
            buttonText: 'Next',
            blobs: [ 
                { label: 'Interfaces', color: 'bg-lime-300', text: "The event data is primarily accessed through third-party interfaces such as websites and apps, facilitating a wide range of projects." },
                { label: 'Feeds', color: 'bg-teal-300', text: "Similar to RSS or iCal feeds, we offer JSON-LD feeds using schema.org vocabulary for our cultural events. Simply append '/feed' to the relevant endpoint, or use our tool to create a custom feed of events. This approach reduces computational resources while providing rich, structured data." },
                { label: 'API', color: 'bg-orange-300', text: "Our API provides JSON-LD data and allows for detailed queries. An account and API authentication are required to use the API." },],
        },
        3: {
            title: 'Vocabulary',
            description: 'These terms frame how data is organized and how the application is structured, following global standards maintained by schema.org.',
            body: 'Here are some key terms to help you navigate the platform:',
            buttonText: 'Next',
            blobs: [
                { label: 'Listings', color: 'bg-yellow-300', text: "Listings are the individual events hosted by Places, like exhibitions or concerts. They provide details about what's happening, where, and when. If an event is on a Place's calendar, it automatically appears in our Listings." },
                { label: 'Places', color: 'bg-purple-300', text: "Places are specific venues like museums, theaters, or book stores. They host the events that make up our Listings." },
                { label: 'Organizers', color: 'bg-blue-300', text: "Organizers play a key role in the cultural landscape. They setup events, whether it's independently or in collaboration at Places." },
                { label: 'Regions', color: 'bg-green-300', text: "Regions are geographical areas that manage the Places and Organizers within them." },
            ],
        },
        
        4: {
            title: 'Managing Data',
            description: 'Explore how to contribute and manage the platform’s data.',
            body: "",
            buttonText: 'Next',
            blobs: [
                { 
                    label: 'Manual Entry', 
                    color: 'bg-pink-300', 
                    text: "Authorized users have access to a powerful interface for easily adding and editing Listings, Series, Organizers, Places, and Regions. All users can suggest edits, while authorized users have direct control over data management."
                },
                { 
                    label: 'Community Council', 
                    color: 'bg-yellow-300', 
                    text: "Regional Community Councils select and maintain managers/owners for Places and Organizers, ensuring the data remains accurate and relevant." 
                },
                { 
                    label: 'Integrations', 
                    color: 'bg-lime-300', 
                    text: "Zeitgeists connects with popular event systems like Eventbrite and iCal for event data synchronization. Much of our data is collected through community-developed scrapers, and bots check for issues and add more details."
                }
            ],
        }, 
        5: {
            title: '',
            description: '',
            body: 'There are many kind people who have helped make this project possible. ',
            buttonText: 'Next',
            blobs: [],
        },
        6: {
            title: 'Thank you!',
            description: 'You are all set to start using Zeitgeists.',
            body:'',
            buttonText: 'Finish',
            blobs: [],
        },
    };
    useEffect(() => {
        document.title = `Welcome - Zeitgeists`;
    
    }, []);
    const screenData = onboardingScreensData[currentScreen];

    const handleNext = () => {
        if (screenData.blobs && currentBlobIndex < screenData.blobs.length - 1) {
            setCurrentBlobIndex(currentBlobIndex + 1);
        } else if (currentScreen < totalScreens) {
            setCurrentScreen(currentScreen + 1);
            setCurrentBlobIndex(0);
        } else {
            updateOnboarding();
        }
    };


    const handleBack = () => {
        if (currentBlobIndex > 0) {
            setCurrentBlobIndex(currentBlobIndex - 1);
        } else if (currentScreen > 1) {
            setCurrentScreen(currentScreen - 1);
            setCurrentBlobIndex(0);
        }
    };
    const updateOnboarding = async () => {
        try {
            const response = await fetch('/api/onboarding', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (response.ok) {
                console.log('Onboarding completed');
                const nextPage = location.state?.nextPage || '/dashboard';
                navigate(nextPage);

            } else {
                console.error('Failed to update onboarding');
            }

        } catch (error) {
            console.error(error)
    }
}
    return (
        <div className="min-h-screen bg-black flex flex-col justify-between items-center text-white">
            <div className="w-full text-center mt-20 ">
        
                <p className="text-4xl font-bold">{screenData.title}</p>
                <p className="text-2xl mt-4">{screenData.description}</p>
            </div>

     
            <div className="w-full text-left">
                <p className="text-xl font-light m-5">{screenData.body}</p>
            </div>
            {currentScreen === 5 && <div className=""></div>}
            {currentScreen === 60 && <div className="text-left">
                <p className="text-xl mt-4 font-bold">Credits:</p>
                <p className="text-xl mt-4">Thank you to all the contributors who have helped make this project possible.</p>
         
            
                </div>}

            <div className="grid grid-cols-1 sm:grid-cols-2 w-full justify-center items-stretch mb-4">
    <div className="hidden sm:block sm:col-span-1">
        <OnBoardingBlobs data={screenData.blobs} setBlobIndex={setCurrentBlobIndex} currentBlobIndex={currentBlobIndex} />
    </div>
    
    {screenData.blobs && screenData.blobs.length > 0 &&<div className={`${screenData.blobs[currentBlobIndex].color} flex-grow p-4 sm:p-8 sm:rounded-md flex items-stretch relative overflow-hidden  mr-4`} onClick={() => handleNext()}>
    <div className="absolute top-2 right-2 text-black text-xl">
        {currentBlobIndex + 1}/{screenData.blobs.length}   &#8594; 
    </div>

    <div className="ml-10 mr-10 mb-4 text-black">
        <div className="text-4xl underline">
            {screenData.blobs[currentBlobIndex].label}
        </div>

        <div className="text-2xl mt-4 mr-4 mb-4">
            {screenData.blobs[currentBlobIndex].text}
        </div>

    </div>

</div>}


</div>
<div className="w-full text-center mb-10">
                <div className="relative w-3/4 mx-auto h-2 bg-gray-800 rounded-full overflow-hidden mb-4">
                    <div
                        className="bg-white h-full rounded-full"
                        style={{ width: `${(currentScreen / totalScreens) * 100}%` }}
                    ></div>
                </div>

                <div>
                    {currentScreen > 1 && (
                        <button
                            className="bg-white text-black px-4 py-2 mr-4 rounded"
                            onClick={handleBack}
                        >
                            Back
                        </button>
                    )}
                    <button
                        className="bg-white text-black px-8 py-4 rounded text-lg font-bold"
                        onClick={handleNext}
                    >
                        {screenData.buttonText}
                    </button>
                </div>
            </div>

        </div>
    );
}

export default OnBoarding;