import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file for styling

function Home() {
  useEffect(() => {
    document.title = `Home - Zeitgeists`;
    window.scrollTo(0, 0);

  }, []);

  return (
    <div className="home-container">
      <div className="animation-wrapper">
        <div className="white-circle"></div>
      </div>
      <div className="hero-text">
        <h1 className="mission-statement">
        Zeitgeists.org is a collaborative demonstration dedicated to curating, maintaining, and promoting open access to <Link to={'https://schema.org'}>structured data</Link> for cultural events.
        </h1>
        <div className="buttons-container">
          <Link to="/faq" className="button">
            Learn More
          </Link>
          <Link to="/api" className="button">
            Data Feeds
          </Link>
          <Link to="/dashboard" className="button">
            Manage & View
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
