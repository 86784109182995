import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file for styling

function NotFound() {
  useEffect(() => {
    document.title = `404 - Zeitgeists`;
    window.scrollTo(0, 0);

  }, []);

  return (
    <div className="home-container">
      <div className="animation-wrapper">
        <div className="white-circle"></div>
      </div>
      <div className="hero-text">
        <h1 className="mission-statement">
          Page Not Found
        </h1>
        <div className="buttons-container">
          <Link to="/faq" className="button">
            Learn More
          </Link>
          <Link to="/api" className="button">
            Data Feeds
          </Link>
          <Link to="/dashboard" className="button">
            Manage & View
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
