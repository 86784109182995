import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ProfileImage from '../user/ProfileImage';

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function SeriesInfo({ series, home, deleteSeries, handleDelete }) {
  return (
    <div className={'m-3 text-center'}>
      {series && (
        <div key={series.uuid}>
          {series.image && (
            <div className='m-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ProfileImage url={series.image} alt={series.alt} />
            </div>
          )}
          <h3 className='font-light text-lg'>SERIES</h3>
          <h2 className='text-2xl font-semibold mt-2'>
            <Link to={`/series/${series.uuid}`} className='hover:underline'>
              {series.name}
            </Link>
          </h2>
          {series.url && (
            <a href={series.url} target='_blank' rel='noopener noreferrer' className='text-blue-500 hover:underline break-all'>
              {series.url}
            </a>
          )}
          <br></br>
          {series.organizers.map((organizer) => (
            <span className='inline-block mt-2' key={organizer.organizer.uuid}>
              <Link to={`/region/${organizer.organizer.regionId}/organizer/${organizer.organizer.urlName}`} className='mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black'>
                {organizer.organizer.name}
              </Link>
            </span>
          ))}
          {series.places.map((place) => {
            if (place) {
              return (
                <span className='inline-block mt-2 ' key={place.place.uuid}>
                  <Link key={place.id} to={`/region/${place.place.regionId}/place/${place.place.urlName}`} className='mr-1 rounded-lg border border-1 border-black p-1 hover:text-white hover:bg-black'>
                    {place.place.name}
                  </Link>
                </span>
              );
            }
          })}
          {series.region && (
            <p className='mt-2'>
              <strong>Region:</strong>{' '}
              <Link className='text-blue-500 hover:underline' to={`/region/${series.region.urlName}`}>
                {series.region.name}
              </Link>
            </p>
          )}
          {series.hide && <p className='mt-2 text-red-500'>HIDDEN</p>}
          {series.description && (
            <p className='mt-2'>
              <strong>Description:</strong> {series.description}
            </p>
          )}
          {series.type && (
            <p className='mt-2'>
              <strong>Type:</strong> {series.type}
            </p>
          )}
          {/* think description how I want to render each type */}
          {series.alternateName && (
            <p className='mt-2'>
              <strong>Alternate Name:</strong> {series.alternateName}
            </p>
          )}
          {series.note && (
            <p className='mt-2'>
              <strong>Note:</strong> {series.note}
            </p>
          )}
          {series.startDate && (
            <p className='mt-2'>
              <strong>Start Date:</strong> {formatDate(series.startDate)}
            </p>
          )}
          {series.endDate && (
            <p className='mt-2'>
              <strong>End Date:</strong> {formatDate(series.endDate)}
            </p>
          )}
          {series.createdAt && (
            <p className='mt-2'>
              <strong>Created:</strong> {formatDate(series.createdAt)}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default SeriesInfo;
