import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import PlaceInfo from '../../components/place/PlaceInfo';
import GetListings from '../../components/listing/GetListings';
import LoadingScreen from '../../components/utilities/LoadingScreen';
function PlaceDetail() {
  const navigate = useNavigate();
  const [place, setPlace] = useState(false);
  const [organizers, setOrganizers] = useState(false);
  const { regionName,placeName } = useParams();
  const [loading, setLoading] = useState(true);
  const fetchPlaceDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/place/${placeName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setPlace(data.place);
        setOrganizers(data.organizers);
        navigate(`/region/${data.place.region.urlName}/place/${data.place.urlName}`, { replace: true });
        }
        setLoading(false);
    } catch (error) {
      // Handle error
      console.error('Network or other error:', error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchPlaceDetails();
  }, []);
  useEffect(() => {
    if(place) {
      document.title = `${place.name} - Zeitgeists`;
    } else {
      document.title = `Place - Zeitgeists`;
    }
  }, [place]);

 

  return (
    <LoadingScreen isLoading={loading}  message="Fetching place ...">

    <div className="grid grid-cols-3 gap-4 mt-16">
      <div className="col-span-3 md:col-span-1">
      {place && <PlaceInfo place={place} organizers={organizers} />}
      </div>
  <div className="col-span-3 md:col-span-2">
      {place && (
        <div className="m-4">
          <GetListings places={[place.id]} placePage={true}  />
        </div>
      )}
</div>

    </div>
    </LoadingScreen>
  );
}

export default PlaceDetail;
