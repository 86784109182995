import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConfirmModal from '../utilities/ConfirmModal';
function ManageSeries({ series, onChange }) {
    const [sortedSeries, setSortedSeries] = useState([]);
    useEffect(() => {
        setSortedSeries(Array.isArray(series)
        ? series.slice().sort((a, b) => {
            // Sort hide series last
            if (a.hide && !b.hide) return 1;
            if (!a.hide && b.hide) return -1;
    
            // Sort by number of listings first (descending)
            if(a.listings && b.listings)  {
            if (b.listings.length !== a.listings.length) {
              return b.listings.length - a.listings.length;
            }
          }
            // If listings count is equal, sort by creation date (most recent first)
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
        : [])
    },[series])


  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [confirmModal, setConfirmModal] = useState({ isOpen: false, action: null, seriesUuid: null });

  const handleEdit = (uuid, key, newValue) => {
    setSelectedSeries({ ...selectedSeries, [key]: newValue });
  };

  const handleSave = (uuid) => {
    const updatedSeries = sortedSeries.map((s) => (s.uuid === uuid ? { ...selectedSeries } : s));
    onChange(updatedSeries, updatedSeries.filter((s) => s.uuid === uuid)[0],'update');
  };

  const toggleExpandRow = (seriesUuid) => {
    if (expandedRow === seriesUuid) {
      setExpandedRow(null);
      setSelectedSeries(null);
    } else {
      setExpandedRow(seriesUuid);
      setSelectedSeries(sortedSeries.find((s) => s.uuid === seriesUuid));
    }
  };
  const handleDelete = (uuid) => {
    setConfirmModal({ isOpen: true, action: 'delete', seriesUuid: uuid });
  };

  const handleHide = (uuid) => {
    setConfirmModal({ isOpen: true, action: 'hide', seriesUuid: uuid });
  };

  const confirmAction = () => {
    const { action, seriesUuid } = confirmModal;

    if (action === 'delete') {
      const updatedSeries = sortedSeries.filter((s) => s.uuid !== seriesUuid);
      onChange(updatedSeries, sortedSeries.filter((s) => s.uuid === seriesUuid)[0],'delete'); //deleted from updated

    } else if (action === 'hide') {
      const updatedSeries = sortedSeries.map((s) => (s.uuid === seriesUuid ? { ...s, hide: !s.hide } : s));
      onChange(updatedSeries, updatedSeries.filter((s) => s.uuid === seriesUuid)[0],'hide');
    }

    setConfirmModal({ isOpen: false, action: null, seriesUuid: null });
  };

  const cancelAction = () => {
    setConfirmModal({ isOpen: false, action: null, seriesUuid: null });
  };

  return (
    <div className="p-4">
      {sortedSeries && sortedSeries.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow overflow-hidden rounded-md hidden md:table">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Series Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Listings</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedSeries.map((s) => (
                <React.Fragment key={s.uuid}>
                  <tr className="border-b">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Link to={`/series/${s.uuid}`} className="text-blue-600 hover:underline">
                        <span className="font-bold">{s.name}</span>
                        <br />
                        {s.alternateName}
                      </Link>
                    </td>
                    <td className="px-6 py-4" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                      {s.note}
                      <br />
                      {s.description && s.description.length > 100 ? `${s.description.substring(0, 100)}...` : s.description}
                      <br />
                      <a className="text-blue-500 underline" target="_blank" href={s.url}>
                        {s.url}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{s.listings && s.listings.length}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{new Date(s.createdAt).toLocaleDateString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button className="px-2 py-1 text-white bg-blue-500 hover:bg-blue-700 rounded-md text-sm" onClick={() => toggleExpandRow(s.uuid)}>
                        {expandedRow === s.uuid ? 'Collapse' : 'Manage'}
                      </button>
                      <button className="ml-2 px-2 py-1 text-white bg-orange-500 hover:bg-orange-700 rounded-md text-sm" onClick={() => handleHide(s.uuid)}>
                        {s.hide ? 'Unhide' : 'Hide'}
                      </button>
                      <button className="ml-2 px-2 py-1 text-white bg-red-500 hover:bg-red-700 rounded-md text-sm" onClick={() => handleDelete(s.uuid)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                  {expandedRow === s.uuid && (
                    <tr>
                      <td colSpan="5" className="px-6 py-4 bg-gray-50">
                        <div className="flex flex-col space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                              type="text"
                              value={selectedSeries.name}
                              onChange={(e) => handleEdit(s.uuid, 'name', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Subtitle</label>
                            <input
                              type="text"
                              value={selectedSeries.alternateName}
                              onChange={(e) => handleEdit(s.uuid, 'alternateName', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">URL</label>
                            <input
                              type="text"
                              value={selectedSeries.url}
                              onChange={(e) => handleEdit(s.uuid, 'url', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Note</label>
                            <input
                              type="text"
                              value={selectedSeries.note}
                              onChange={(e) => handleEdit(s.uuid, 'note', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                              rows={10}
                              value={selectedSeries.description}
                              onChange={(e) => handleEdit(s.uuid, 'description', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="mt-4">
                            <button onClick={() => handleSave(s.uuid)} className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded-md">
                              Save
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {/* Mobile Version */}
          <div className="md:hidden">
            {sortedSeries.map((s) => (
              <div key={s.uuid} className="border border-gray-200 rounded-md mb-4 p-4 shadow-sm">
                {/* Header with Series Name and Manage button */}
                <div className="flex justify-between items-center">
                  <Link to={`/series/${s.uuid}`} className="text-blue-600 hover:underline font-bold">
                    {s.name}
                    <br></br>
                  </Link>
                  <button className="text-xs text-blue-500 underline" onClick={() => toggleExpandRow(s.uuid)}>
                    {expandedRow === s.uuid ? 'Collapse' : 'Manage'}
                  </button>
                </div>

                {/* Series details */}
                <div className="text-sm text-gray-500 mt-2">
                  {s.alternateName && <p>Subtitle: {s.alternateName}</p>}
                  {s.url && (
                    <p>
                      URL:
                      <a className="text-blue-500 underline" target="_blank" href={s.url}>
                        {s.url}
                      </a>
                    </p>
                  )}
                  {s.note && <p>Note: {s.note}</p>}
                  {s.description && (
                    <p>
                      description: {s.description.length > 100 ? `${s.description.substring(0, 100)}...` : s.description}
                    </p>
                  )}
                </div>
                <div className="text-sm text-gray-500 mt-2">Listings: {s.listings && s.listings.length}</div>
                <div className="text-sm text-gray-500">Created: {new Date(s.createdAt).toLocaleDateString()}</div>

                {/* Expanded Row for editing and managing */}
                {expandedRow === s.uuid && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-md">
                    {/* Title */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">Title</label>
                      <input
                        type="text"
                        value={selectedSeries.name}
                        onChange={(e) => handleEdit(s.uuid, 'name', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    {/* Subtitle */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">Subtitle</label>
                      <input
                        type="text"
                        value={selectedSeries.alternateName}
                        onChange={(e) => handleEdit(s.uuid, 'alternateName', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    {/* Note */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">Note</label>
                      <input
                        type="text"
                        value={selectedSeries.note}
                        onChange={(e) => handleEdit(s.uuid, 'note', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    {/* Url */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">External URL</label>
                      <input
                        type="text"
                        value={selectedSeries.note}
                        onChange={(e) => handleEdit(s.uuid, 'url', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    {/* description */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">Description</label>
                      <textarea
                        rows={10}
                        value={selectedSeries.description}
                        onChange={(e) => handleEdit(s.uuid, 'description', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    {/* Action Buttons */}
                    <div className="mt-4 flex space-x-2">
                      <button
                        onClick={() => handleSave(s.uuid)}
                        className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded-md"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => handleHide(s.uuid)}
                        className="px-4 py-2 bg-orange-500 hover:bg-orange-700 text-white rounded-md"
                      >
                        {s.hide ? 'Unhide' : 'Hide'}
                      </button>
                      <button
                        onClick={() => handleDelete(s.uuid)}
                        className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded-md"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* Confirm modal for delete/hide actions */}
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        onClose={cancelAction}
        onConfirm={confirmAction}
        title={confirmModal.action === 'delete' ? 'Confirm Delete' : 'Confirm Hide'}
        message={
          confirmModal.action === 'delete'
            ? 'Are you sure you want to delete this series? This action cannot be undone.'
            : 'Are you sure you want to hide this series?'
        }
      />
    </div>
  );
}

export default ManageSeries;
