import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

function SetPlaceOrgs({ control, setValue, watch }) {
    const regionId = watch('regionId');
    const place = watch('place');
    const organizers = watch('organizers');
    const possibles = watch('possibles');

    useEffect(() => {
        if (!possibles) {
            setValue('possibles', []);
        }
    }, [possibles, setValue]);

    useEffect(() => {
        const listingPlaces = async () => {
            try {
                const fetchUrl = `/api/region/${regionId}/listing/place/${place.id}`;
                const response = await fetch(fetchUrl, { credentials: 'include' });
                const data = await response.json(); 
                setValue('possibles', data  && data.organizers); // Optionally update form state if necessary
            } catch (error) {
                console.error(error);
            }
        };

        if (regionId && place) {
            listingPlaces();
        }
    }, [regionId, place, setValue]);

    const handleOrganizerChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setValue('organizers', selectedOptions);
    };

    return (
        <div className="text-black">
            {control && possibles && possibles.length > 0 ? (
                <>
                    <Controller
                        control={control}
                        name="organizers"
                        render={({ field }) => (
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Assign Organizers:</label>
                                <select
                                    id="organizerSelect"
                                    {...field}
                                    multiple
                                    onChange={handleOrganizerChange}
                                    value={organizers}
                                    className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline overflow-auto"
                                >
                                    {possibles.map(organizer => (
                                        <option key={organizer.id} value={organizer.id}>
                                            {organizer.name}
                                        </option>
                                    ))}
                                </select>
                                <p>Choose organizers from the list (scroll) and click the red dot to remove a selection.</p>
                            </div>
                        )}
                    />
                    <div className="flex flex-wrap gap-2">
                        {organizers && organizers.map(organizerId => {
                            const organizer = possibles.find(o => o.id.toString() === organizerId);
                            return (
                                <div key={organizerId} className="flex items-center gap-2 bg-blue-200 rounded px-4 py-2">
                                    {organizer?.name}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newSelectedOrganizers = organizers.filter(id => id !== organizerId);
                                            setValue('organizers', newSelectedOrganizers);
                                        }}
                                        className="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-white"
                                    >
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="mb-3">No Organizers</div>
            )}
        </div>
    );
}

export default SetPlaceOrgs;
