import React, { useEffect,useState } from 'react';
import { useForm } from 'react-hook-form';
import ListingBox from './ListingBox';
import { set } from 'date-fns';
import PlaceInfo from '../../components/place/PlaceInfo';
import OrganizerInfo from '../../components/organizer/OrganizerInfo';
import { pl } from 'date-fns/locale';

function PreviewListing({ getValues,region,place,organizers,additionalData, organizer}) {
    const [listing, setListing] = useState(null)
    useEffect(() => {
        const values = getValues(); // { title: '...', description: '...', price: ... }
        const gm = { ...values, additionalData }
        if(gm.organizers) {
          gm.organizersData = gm.possibles.filter(possible => gm.organizers.includes(possible.id.toString()));
        }
        if(gm.series) {
          gm.seriesData = gm.series.map(series => gm.allSeries.find(s => s.uuid === series.uuid));
        }
        if(gm.sameAs) {
          gm.sameAs = gm.sameAs.map(sameAs =>({'url': sameAs.value}));
        }
        //qa free 
        if(organizer) {
          gm.organizersData = [organizer];
          gm.organizers = [organizer.id];
        }
        gm.worksPresented = gm.additionalData.worksPresented;
        gm.region = region;

        gm.lastEdit = new Date().getTime();
        setListing(gm)
        console.log(gm)
    },[])
  
  return (
    <div >
     Preview
    {listing&&<ListingBox listing={listing}  preview={true} place={place||listing.place} region={region}/>}

  </div>
)};

export default PreviewListing;
