import { Link } from "react-router-dom";

const EditOrganizerBar = ({ active, organizer, region }) => {
  // Function to style the active link
  const style = (url) => {
    return active !== url
      ? "p-2 rounded-lg border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform"
      : "p-2 rounded-lg border-black border-2 bg-black text-white hover:scale-105 transition-transform";
  };

  return (
    <div className="text-center mt-16">
      {region && (
        <p className="text-lg mb-4">
          <Link
            to={`/region/${region.urlName}/`}
            className="text-blue-600 hover:underline"
          >
            {region.name}
          </Link>
        </p>
      )}

      {organizer && region && (
        <p className="font-bold text-2xl underline mb-6">
          <Link
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/`}
            className="text-blue-600 hover:underline"
          >
            {organizer.name}
          </Link>
        </p>
      )}

      {/* Static list of menu links */}
      <div className="border-black border p-6 rounded-lg shadow-lg">
      <span className="inline-block m-3">
          <Link
            className={style("/dashboard")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/`}
          >
            Dashboard
          </Link>
        </span>
        <span className="inline-block m-3">
          <Link
            className={style("/listings")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/listings`}
          >
            Add Listing
          </Link>
        </span>

        <span className="inline-block m-3">
          <Link
            className={style("/series")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/series`}
          >
            Series
          </Link>
        </span>


        <span className="inline-block m-3">
          <Link
            className={style("/people")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/people`}
          >
            People
          </Link>
        </span>

    

        <span className="inline-block m-3">
          <Link
            className={style("/places")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/places`}
          >
            Places
          </Link>
        </span>
        <span className="inline-block m-3">
          <Link
            className={style("/locations")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/locations`}
          >
            Locations
          </Link>
        </span>


        <span className="inline-block m-3">
          <Link
            className={style("/settings")}
            to={`/region/${region.urlName}/organizer/${organizer.urlName}/edit/settings`}
          >
            Settings
          </Link>
        </span>
      </div>
    </div>
  );
};

export default EditOrganizerBar;
