import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from "react-hook-form";

function ManageIntegration({ place, selectedIntegration, setSelectedIntegration, onChange }) {
  const [errorMessage, setErrorMessage] = useState(false);

  function SuccessMessage({ data }) {
    return (
      <div className="border text-xl border-green-400 bg-green-100 px-4 py-3 text-green-700">
        {data}
      </div>
    );
  }

  function FailMessage({ data }) {
    return (
      <div className="border text-xl border-red-400 bg-red-100 px-4 py-3 text-red-700">
        {data ? data : 'Error Editing Integration'}
      </div>
    );
  }

  const {
    watch,
    control,
    register,
    handleSubmit, formState: { errors }, setError, setValue
  } = useForm({
    defaultValues: {
      type: selectedIntegration.type,
      name: selectedIntegration.name,
      action: selectedIntegration.action,
      key: selectedIntegration.key || null,
      organizer: selectedIntegration.organizerId || null,
      url: selectedIntegration.url || null,
      listingType: selectedIntegration.key || null,
    }
  });

  useEffect(() => {
    setValue('type', selectedIntegration.type);
    setValue('name', selectedIntegration.name);
    setValue('action', selectedIntegration.action);
    setValue('key', selectedIntegration.key || null);
    setValue('organizer', selectedIntegration.organizerId || null);
    setValue('url', selectedIntegration.url || null);
    setValue('listingType', selectedIntegration.key || null);
  }, [selectedIntegration, setValue]);

  const onSubmit = async (data) => {
    if (window.confirm("Confirm Edit Integration") !== true) {
      return;
    }
    try {
      const response = await fetch(`/api/region/${place.region.urlName}/place/${place.urlName}/edit/integration/${selectedIntegration.uuid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',

        body: JSON.stringify(data),
      });

      if (response.ok) {
        const data = await response.json();
        setErrorMessage(<SuccessMessage data={data.message} />);
        setSelectedIntegration({...selectedIntegration, name: data.placeIntegration.name, action: data.placeIntegration.action});
        onChange();
      } else {
        setErrorMessage(<FailMessage />);
        console.error('Error editing integration:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage />);
      console.error('Error editing integration:', error);
    }
  };

  return (
    <div className="m-4">      
      {/* Display the current integration details */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <div className="grid grid-cols-2 gap-4 text-lg">
          <div>
            <span className="font-semibold">Name:</span> {selectedIntegration.name}
          </div>
          <div>
            <span className="font-semibold">Type:</span> {selectedIntegration.integrationType.name}
          </div>
          <div>
            <span className="font-semibold">Status:</span>
            <span
              className={`inline-flex ml-2 px-3 py-1 rounded-full text-lg font-semibold ${
                selectedIntegration.status === 'active' ? 'bg-green-200 text-green-800' :
                selectedIntegration.status === 'paused' ? 'bg-yellow-200 text-yellow-800' :
                selectedIntegration.status === 'new' ? 'bg-blue-200 text-blue-800' :
                selectedIntegration.status === 'disabled' ? 'bg-gray-200 text-gray-800' :
                'bg-red-200 text-red-800'
              }`}
            >
              {selectedIntegration.status.toUpperCase()}
            </span>
          </div>
          <div>
            <span className="font-semibold">Frequency:</span>
            <span
              className={`inline-flex ml-2 px-3 py-1 rounded-full text-lg font-semibold ${
                selectedIntegration.frequency === 'daily' ? 'bg-green-200 text-green-800' :
                selectedIntegration.frequency === 'weekly' ? 'bg-blue-200 text-blue-800' :
                'bg-gray-200 text-gray-800'
              }`}
            >
              {selectedIntegration.frequency ? selectedIntegration.frequency.toUpperCase() : 'N/A'}
            </span>
          </div>
          <div>
            <span className="font-semibold">Permissions:</span>
            <span
              className={`inline-flex ml-2 px-3 py-1 rounded-full text-lg font-semibold ${
                selectedIntegration.action === 'add' ? 'bg-blue-200 text-blue-800' :
                selectedIntegration.action === 'disabled' ? 'bg-gray-200 text-gray-800' :
                selectedIntegration.action === 'suggest' ? 'bg-purple-200 text-purple-800' :
                'bg-red-200 text-red-800'
              }`}
            >
              {selectedIntegration.action.toUpperCase()}
            </span>
          </div>

          {selectedIntegration.key && <div>
            <span className="font-semibold">KEY:</span>
            <span
              className="inline-flex ml-2 px-3 py-1 rounded-full text-lg font-semibold bg-gray-200 text-gray"
            >
              {selectedIntegration.key}
            </span>
            <p className='mt-4 font-bold'>Make a POST to /api/data/{selectedIntegration.id} pass KEY as x-api-key with Events as JSON array body  </p>

          </div>
          }
        </div>
      </div>

      {/* Form for editing the integration */}
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
    
        <div className="mb-6">
          <label htmlFor="actionsSelect" className="block mb-2 text-sm font-medium text-gray-900">Change Permissions Level:</label>
          <select
  {...register("action", { required: 'Permissions level is required' })}
  className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
>
  <option key="" value="" disabled>
    -- Select Permissions Level --
  </option>
  <option key="add" value="add">
    Full Permissions - Manage & Edit Live Listings
  </option>
  <option key="suggest" value="suggest">
    Suggest Changes - Propose Edits & Add New Listings
  </option>
  <option key="disabled" value="disabled">
    Disabled - Temporarily Inactive
  </option>
</select>

        </div>
        {errors.action && (
          <div className="mt-5">
            <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
              <p>{errors.action.message}</p>
            </div>
          </div>
        )}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">Integration Label:</label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" {...register("name", { required: 'Label is required' })} />
        </div>
        {errors.name && (
          <div className="mt-5">
            <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
              <p>{errors.name.message}</p>
            </div>
          </div>
        )}

        {errorMessage}

        <button type="submit" className="bg-gray-50 hover:bg-orange-400 text-orange-400 font-semibold hover:text-white py-2 px-4 border border-orange-400 hover:border-transparent rounded">
          Update
        </button>
      </form>
    </div>
  );
}

export default ManageIntegration;
