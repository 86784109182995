import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import SeriesInfo from '../../components/series/SeriesInfo';
import GetListings from '../../components/listing/GetListings';
import LoadingScreen from '../../components/utilities/LoadingScreen';
function SeriesDetail({  seriesUUID}) {
  const navigate = useNavigate();
//place setPlace
  const [series, setSeries] = useState(null);
  const [organizers, setOrganizers] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchSeriesDetails = async () => {
    try {
      const response = await fetch(`/api/series/${seriesUUID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        window.scrollTo(0, 0);
        const data = await response.json();
        setSeries(data.series); //?
        setOrganizers(data.organizers);
        setLoading(false);
        navigate(`/series/${seriesUUID}`, { replace: true });
        }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchSeriesDetails();
  }, [seriesUUID]);
  useEffect(() => {
    if(series) {
      document.title = `${series.name} - Zeitgeists`;
    } else {
      document.title = `Series - Zeitgeists`;
    }
  }, [series]);

 

  return (
    <LoadingScreen isLoading={loading}  message="Loading series ...">
    <div className="grid grid-cols-3 gap-4 mt-16">
      <div className="col-span-3 md:col-span-1">
      {series && <SeriesInfo series={series}  />} 
      </div>
  <div className="col-span-3 md:col-span-2">
      {series && (
        <div className="m-4">
         <GetListings series={[series.id]} placePage={true} />
        </div>
      )}
</div>

    </div>
    </LoadingScreen>
  );
}

export default SeriesDetail;
