import React, {useContext} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);

    const location = useLocation();

    if (isAuthenticated === null) {
        // While checking authentication status, you might show a loading spinner
        return <div>Loading...</div>;
      }
  // Only redirect if isAuthenticated is explicitly false
  if (isAuthenticated === false) {
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} replace />;
  }



  // Otherwise, render the children components (protected content)
  return children;
};

export default ProtectedRoute;
