import React from 'react';
import { Link } from 'react-router-dom';

const PlacesCTA = ({ place,region }) => {
  return (
    <div className="p-4 bg-white shadow-md rounded border-black border mt-2">
      <h2 className="text-xl font-semibold mb-3">Manage and Contribute to {place.name}</h2>
      <p className="mb-3">
        Zeitgeists.org allows you to manage and update event listings for {place.name}. Help us keep the information accurate and up-to-date.
      </p>
      <div className="flex flex-wrap justify-center gap-2">
        {place?.owns?.length < 1 ? (
          <a 
            href={`mailto:contact@zeitgeists.org?subject=Claim: ${place.name}`} 
            className="px-3 py-2 bg-green-200 text-black text-sm font-medium rounded hover:bg-green-500">
            Claim {place.name}
          </a>
        ) : (
          <span className="px-3 py-2 bg-green-200 text-black text-sm font-medium rounded">
            {place.name} Claimed
          </span>
        )}
        <Link 
          to={`/region/${(place.region&&place.region.urlName) || (region && region.urlName) || null}/place/${place.urlName}/edit`} 
          className="px-3 py-2 bg-orange-200 text-black text-sm font-medium rounded hover:bg-orange-500">
          Update Listings
        </Link>
        <Link 
          to={place.repo || 'https://github.com/zeitgeistsorg'} 
          className="px-3 py-2 bg-blue-200 text-black text-sm font-medium rounded hover:bg-blue-500">
          {place.repo ? 'Modify Scraper on GitHub' : 'Create a Scraper'}
        </Link>
      </div>
    </div>
  );
};

export default PlacesCTA;
