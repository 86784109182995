import React, {useEffect, useState} from 'react';

import RightPanel from '../../components/utilities/RightPanel.js';
import LeftPanel from '../../components/utilities/LeftPanel.js';
import Questions from '../../components/utilities/Questions.js';
function FAQ() {
  const [left,setLeft] = useState('qa');




useEffect(() => {
  document.title = `FAQ - Zeitgeists`;
}, []);

  return (
    <div className="grid grid-cols-2 h-full pt-18 underline-offset-8">
    <div className={`col-span-2 md:col-span-1 md:overflow-auto ${left !== 'qa' ? left.color : 'bg-black'} h-full flex justify-start pt-20 flex-col text-white`}>
      {left !== 'qa' && (
        <div className="w-full text-right text-3xl cursor-pointer pr-6 hover:text-gray-500" onClick={() => setLeft('qa')}>✕</div>
      )}
      {left !== 'qa' &&  <LeftPanel left={left} />}
      {left === 'qa' && <Questions />}
    </div>

    <div className="col-span-2 md:col-span-1 md:overflow-auto h-full bg-white pt-5 md:pt-20 pb-10 text-black">
      <RightPanel left={left} setLeft={setLeft} />
    </div>
  </div>
  );
}

export default FAQ;



