import React, { useEffect,useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

function SetPO({ control, setValue, watch }) {
    const regionId = watch('regionId');
    const place = watch('place');
    const organizers = watch('organizers');
    const possibles = watch('possibles');
    const [possible, setPossible] = useState({places: [],organizers: []});
    useEffect(() => {
        if (!possibles) {
            setValue('possibles', []);
        }
    }, [possibles, setValue]);

    useEffect(() => {
     listingPlaces()
    }, []);
    const listingPlaces = async () => {
        try {
            const fetchUrl = `/api/region/${regionId}/listings/add`;
            const response = await fetch(fetchUrl, { credentials: 'include' });
            const data = await response.json(); 
            setPossible(data);
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleOrganizerChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setValue('organizers', selectedOptions);
    };
    const handlePlaceChange = (pid) => {
        setValue('placeId', pid); // Set the placeId to the selected one
    
        // Ensure the types are consistent: Convert pid to a number before comparing
        const selectedPlace = possible.places.find((pp) => pp.id === Number(pid));
    
        if (selectedPlace) {
            console.log(selectedPlace);
            setValue('place', selectedPlace); // Save the selected place using setValue
        } else {
            console.log("Place not found");
        }
    };
    return (
        <div className="text-black">
              <div className="mb-6">
      <Controller
        control={control}
        name="placeId"
        render={({ field, fieldState: { error } }) => (
          <div>
            <label htmlFor="placeSelect" className="block mb-2 text-sm font-medium text-gray-900">
              Select a Place:
            </label>
            <select
              id="placeSelect"
              {...field}
              value={field.value ?? ""}  
              onChange={(e) => handlePlaceChange(e.target.value)}
              className="block w-full bg-gray-50 border text-gray-900 border-gray-300 px-4 py-2 rounded"
            >
              <option value="">Select a place</option>
              {possible.places.map((place) => (
                <option key={place.id} value={place.id}>
                  {place.name}
                </option>
              ))}
            </select>
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        )}
      />
    </div>
            
            {control && possibles && possibles.length > 0 ? (
                <>
                    <Controller
                        control={control}
                        name="organizers"
                        render={({ field }) => (
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">Assign Organizers:</label>
                                <select
                                    id="organizerSelect"
                                    {...field}
                                    multiple
                                    onChange={handleOrganizerChange}
                                    value={organizers}
                                    className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline overflow-auto"
                                >
                                    {possibles.map(organizer => (
                                        <option key={organizer.id} value={organizer.id}>
                                            {organizer.name}
                                        </option>
                                    ))}
                                </select>
                                <p>Choose organizers from the list (scroll) and click the red dot to remove a selection.</p>
                            </div>
                        )}
                    />
                    <div className="flex flex-wrap gap-2">
                        {organizers && organizers.map(organizerId => {
                            const organizer = possibles.find(o => o.id.toString() === organizerId);
                            return (
                                <div key={organizerId} className="flex items-center gap-2 bg-blue-200 rounded px-4 py-2">
                                    {organizer?.name}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newSelectedOrganizers = organizers.filter(id => id !== organizerId);
                                            setValue('organizers', newSelectedOrganizers);
                                        }}
                                        className="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-white"
                                    >
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="mb-3">No Organizers</div>
            )}
        </div>
    );
}

export default SetPO;
