import React, {  useState } from 'react';
import { useForm } from "react-hook-form"
import { Link } from 'react-router-dom';



function AddSeries({ placeId, organizerId, onChange, region, }) {



  //fix
  function SuccessMessage({data}) {


    return (
      <div className="border text-xl border-green-400 rounded  bg-green-100 px-4 py-3 text-green-700">
        {data.series &&
        <span>{data.message}. Open it  <Link className='underline' to={`/series/${data.series.uuid}`}>here</Link></span>
      }
  </div>

    );
  }
  function FailMessage() {
    return (
      <div className="border text-xl border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
      Error adding series.
      </div>
    );
  }
  const {
    register,
    handleSubmit, getValues, reset, formState: {errors} } = useForm({
 
        mode: 'onChange', // Trigger validation on each change
      });


  const onSubmit = (data) => submitAndConnect(data);
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
const submitAndConnect = async (data) => {

    // Call the reset function to clear the form
   await  submitListing(data)
}

/*
useEffect(() => {
    setValue('placeId', place && place.id);
}, [place])

*/





  const submitListing = async (data) => {
//when saving suggestions watch for add data
    // Create a new listing object based on the form field values
    try {
      //fetch and render many regions onload
      if(organizerId) {
        data.organizerId = organizerId;
      }
      if(placeId) { 
        data.placeId = placeId;
      }
      const response = await fetch(`/api/series/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(data),
      });
      if (response.ok) {
        // You can display a success message here if needed
        const data = await response.json()

        onChange();
        
        reset();
        // Set a success message
        setUpdateMessage(<SuccessMessage data={data} />);

        // You can clear the message after some time or on user interaction
        setTimeout(() => setUpdateMessage(''), 5000); // Clear message after 5 seconds
        // You can close the form here by calling a callback function if needed
    } else {
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
    }
    } catch (error) {
      // Handle fetch error
      setUpdateMessage(<FailMessage />);
      console.error(error);
    }
  };
const handleAddSeries = async () => {
  const formData = getValues(); // Retrieves form data from React Hook Form
  await submitListing(formData)
}
  


  return (
    <div className="">
<p>
    <span className="text-2xl font-bold mb-6"> Add New Series</span>
    </p>

<div>

<div>
 


        
<div className="mb-6">
    <label  className="block mb-2 text-sm font-medium text-gray-900 ">Series Title</label>
    <input {...register("name", { required: true })} type="text"  className="block w-full p-2.5 text-gray-900 border border-gray-300 rounded-lg bg-gray-50  focus:ring-blue-500 focus:border-blue-500 text-sm " />
</div>
<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">Series Subtitle: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('alternateName')}  rows="2"  />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">Series Note:</label>        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('note')}  rows="2"  />   
     </div>
     <div className="mb-6">
    <label className="block mb-2 text-sm font-medium text-gray-900">About Series (supports Markdown):</label>
    <textarea 
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
        {...register('description')}  
        rows="4"
    />
</div>

     <div className="mb-6">
  <label className="block mb-2 text-sm font-medium text-gray-900">Main External URL:</label>
  <input
    {...register('url', {
      pattern: {
        value: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
        message: "Please enter a valid URL",
      },
    })}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
  />
  {errors.url && (
    <p className="text-red-500 text-xs mt-1">{errors.url.message}</p>
  )}
</div>

    {/* Other input fields */}
    <button type="button" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded "  onClick={handleAddSeries} >Add New Series</button>  
    {updateMessage && <p className='m-2'>{updateMessage}</p>}

</div> 

</div>
    

    </div>
  );
}

export default AddSeries;
