import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
function AddPlaceOwners({ userList, place, power, onChange }) {
  const navigate = useNavigate()

  const [selectedIds, setSelectedIds] = useState(place.owns); //preselected to set
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Convert userList to an array if it's not already, and handle null/undefined cases
  const userListArray = Array.isArray(userList) ? userList : [];

  const handleRemove = (index) => {
    if(power<3&&place.manages.includes(selectedIds[index])){
      return
    }
    const updatedIds = selectedIds.filter((_, i) => i !== index);
    setSelectedIds(updatedIds);
    setErrorMessage('Press save to update owners')

  };

  const onChangeInput = (event) => {
    setErrorMessage('');
    setInputValue(event.target.value);
  };

  const handleAdd = () => {
    const selectedUser = userListArray.find((user) => user.username === inputValue);
    setErrorMessage('Press save to update owners')

    if (selectedUser && !selectedIds.includes(selectedUser.value)) {
      setSelectedIds([...selectedIds, selectedUser.value]);
      setInputValue('');
    }
  };

  const filteredUserList = userListArray.filter((user) => !selectedIds.includes(user.value));

  const handleSubmit = async (e) => {
    if (window.confirm("Confirm Place Owner List")!==true) {
        return
    }
    try {

          const  userIds  = selectedIds;
      // Make an API request to update user profile using the fetch API
       const response = await fetch(`/api/place/${place.id}/owners`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userIds,
        }),
        credentials: 'include',

      });

      if (response.status === 201) {
        // User profile updated successfully
        // You can redirect to another page or display a success message here
       // console.log('User profile updated:', response);
       setErrorMessage('Place owners updated!')
       onChange();
      } else if (response.status===401) {
        navigate('/login')
      }
      else {
        
      }
      // Call the addRegion function with regionData
    //  await addRegion(regionData);
      // Clear the form or perform other actions as needed
    } catch (error) {
      // Handle any errors that may occur during region creation
      console.error('Error adding region:', error);
    }
  };

  return (
    <div className="m-2">
  <h3 className="text-lg font-medium text-gray-900  mb-2">Current Selected Owners:</h3>
  <div className="space-y-2 mb-4">
    {selectedIds.length > 0 ? selectedIds.map((selectedId, index) => {
      const user = userListArray.find((user) => user.value === selectedId);
      return (
        user && (

          <div key={user.value} className="">
            <span className=" font-medium text-blue-700  hover:underline pr-3"><a rel="noreferrer" target="_blank" href={`/user/${user.username}`}>@{user.username}</a></span>
            {(power > 2 || !place.owns.includes(user.value)) && <button
              onClick={() => handleRemove(index)}
              className=" hover:shadow font-xl text-white bg-red-600  rounded-full border-red-600 p-1 text-xs border-2"
            >
              REMOVE
            </button>}
          </div>
        )
      );
    }) : <p>No Owners</p>}
  </div>
  {errorMessage && <div className="text-red-500 mb-2"><b>{errorMessage}</b></div>}
{power > 1 && <div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-900 ">Add New:</label>
    <input
      type="search"
      list="list"
      autoComplete="on"
      value={inputValue}
      onChange={onChangeInput}
      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    />
    <datalist id="list">
      {filteredUserList.map((user) => (
        <option key={user.value} value={user.username}>
          {user.username}
        </option>
      ))}
    </datalist>
    <button
      onClick={handleAdd}
      className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Add
    </button>
  </div>

  <div>
    <button
      onClick={handleSubmit}
      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Save Owners
    </button>
  </div>
  </div>}
</div>

  );
}

export default AddPlaceOwners;
