import React, { useEffect, useState } from 'react';
import ManageManagers from './ManageManagers';
import ManageOwners from './ManageOwners';
import DisplayUsers from '../utilities/DisplayUsers';
function ManageOrganizerUsers({edit, space}) {
//3, add manager, 4 add owner and mananage managers, 4 < manage both
const [userList, setUserList] = useState(false)
  useEffect(() => {
    // Fetch region details when the component mounts
    users()
  }, []);

  const users = async () => {
    try {
  const response = await fetch('/api/users', {
    headers: {
      'Content-Type': 'application/json', // Use a function to get the cookie value
            },
            credentials: 'include', // Include cookies
  });
  if (response.ok) {
    const data = await response.json();
    setUserList(data);
  }
    }
    catch (error) {

    }
} 
  return (
    <div className="grid grid-cols-3 gap-3">

      <div className="md:col-span-1 col-span-3">{edit > 2 && userList ? <ManageManagers edit={edit} type={'organizer'} userList={userList} space={space} /> : <div> <p className="text-lg">Organizer Managers:</p> <DisplayUsers ids={space.manages} /> </div>}</div>
      <div className="md:col-span-1 col-span-3">{edit > 3 && userList ? <ManageOwners type={'organizer'} edit={edit} userList={userList} space={space} /> : <div> <p className="text-lg">Organizer Owners:</p><DisplayUsers ids={space.owns}/></div>}</div>


    </div>
  );
}

export default ManageOrganizerUsers;

  