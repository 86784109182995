import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function DisplayUsers({ ids }) {
  const [users, setUsers] = useState(false);
  useEffect(() => {
    if( ids.length!== 0 && Array.isArray(ids) && ids.length>0) {
      fetchUsers();
    }
  }, [ids]); 
  const fetchUsers = async () => {
        try {
            // Create a comma-separated string of user IDs from the `ids` array
            const cleanIds = ids;
            const userIdsString = cleanIds.join(',');
            if(cleanIds.length!==0) {
            // Fetch users from the server based on the user IDs
            const response = await fetch(`/api/users/${userIdsString}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setUsers(data);
          }
          } catch (error) {
            console.error('Error fetching users:', error);
          }
};
  return (
    <ul className="inline-block">
      {users && users.map((user) =>  {
      return (
        <li  key={user.id} className="m-1 inline-block">    {' '}
        <Link to={`/user/${user.username}`} className="text-blue-500">@{user.username}</Link>
        {' '}
        </li>
      )})}
    </ul>
  );
}

export default DisplayUsers;