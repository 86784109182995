import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import AddOrganizerToPlace from '../../components/place/AddOrganizerToPlace';
import AddOrganizer from '../../components/organizer/addOrganizer';
import EditPlaceBar from '../../components/utilities/EditPlaceBar';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditPlaceOrganizers() {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Fetch region details when the component mounts
        fetchPlaceDetails();
   
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

  // Fetch the region details based on regionName and render them
  // You can also use the additionalData prop as needed
  const handleAddOrganizerChange = async () => {
    await fetchPlaceDetails(); // Refresh the place details
  };
      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/organizers`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                  setPlace(data.place);
              }
              else {
                console.error(response);
              }
              setLoading(false);
              if (response.status === 401) {
                navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/organizers`);
              } else if (response.status === 403) {
                navigate(`/region/${regionName}/place/${placeName}/edit`);
              }


        } catch (error) {
          console.error('Network or other error:', error);
        }
      }
//display place people

   return (
    <LoadingScreen isLoading={loading}  message="Fetching place organizers...">

    <div className="p-4">
           
      { place && <EditPlaceBar type="place" place={place} active={'Organizers'} region={place.region} />}

{place && <AddOrganizerToPlace place={place} id="organizerDatalist1" /> }
<hr/>
    {place && <AddOrganizer placeId={place.id} onChange={handleAddOrganizerChange} region={place.region} /> }

    
  
 
    

    </div>

</LoadingScreen>
    
  );
}

export default EditPlaceOrganizers;
