import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates that the authentication status is not yet known
  const [role, setRole] = useState(localStorage.getItem('role'));
  const navigate = useNavigate();

  // Function to handle user login
  const login = async (email, password, nextPage) => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { role, onBoarded } = data;

        // Update the state to reflect the user is authenticated
        setRole(role);
        setIsAuthenticated(true);

        // Store authentication details in localStorage
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('role', role);
       // console.log('Role:', role);
        // Navigate based on onboarding status
        if (!onBoarded) {
          navigate('/onboarding', { state: { nextPage: nextPage || '/dashboard' } });
        } else {
          navigate(nextPage || '/dashboard');
        }
      } else if (response.status === 403) {
        throw new Error('Email not verified');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
      // Reset the state on error
      setIsAuthenticated(false);
      setRole(null);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('role');
      throw error;
    }
  };

  // Function to handle user logout
  const logout = () => {
    setIsAuthenticated(false);
    setRole(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('role');
    navigate('/login');
  };

  // Function to check authentication status with the server
  const checkAuth = useCallback(async () => {
    try {
      const response = await fetch('/api/check-auth', {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setRole(data.role);
        setIsAuthenticated(true);
      } else if (response.status === 401) {
        // Try to refresh the token if 401 Unauthorized (due to expired token)
        const refreshResponse = await fetch('/api/refresh-token', {
          method: 'POST',
          credentials: 'include', // Send the refresh token
        });
  
        if (refreshResponse.ok) {
          // Token refreshed, try checkAuth again
          return await checkAuth();
        } else {
          // Refresh token invalid or expired, log out user
          setIsAuthenticated(false);
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('role');
          navigate('/login');
        }
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('role');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error checking authentication', error);
      setIsAuthenticated(false);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('role');
      navigate('/login');
    }
  }, [navigate]);

  // Check authentication status when the provider mounts
  useEffect(() => {
    const initialAuthCheck = async () => {
      if (localStorage.getItem('isAuthenticated') === 'true' && isAuthenticated === null) {
      
        await checkAuth();

      } else if (isAuthenticated === null) {
        setIsAuthenticated(false); // Initialize as not authenticated if there's no stored session
      }
    };

    initialAuthCheck();
  }, [isAuthenticated, checkAuth]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
