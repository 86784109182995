function OnBoardingBlobs({ setBlobIndex, currentBlobIndex, data, }) {
    const handleClick = (item) => {
        setBlobIndex(item);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    return (
        <div className="pl-4 pr-4">
            <div className="grid grid-cols-6 text-2xl gap-4">
                {data && data.map((item, index) => (
                    <div
                        key={item.label}
                        className={`text-black lg:col-span-3 sm:col-span-3 col-span-6 rounded-lg ${item.color} flex justify-center align-middle py-24 cursor-pointer hover:underline ${currentBlobIndex===index && 'underline'}`}
                        onClick={() => handleClick(index)}
                    >
                        <div >
                            {item.label}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OnBoardingBlobs;
