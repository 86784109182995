import React from 'react';
import { Link } from 'react-router-dom';
function ScrapeBlobs({scrapes, places}) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      const placeName = (placeId) => {
            if(places) {
               const place = places.filter(place=>place._id===placeId);
               if(place) {
                return place[0].name;
               }
            }
      };
      const placeUrl = (placeId) => {
        if(places) {
            const place = places.filter(place=>place._id===placeId);
            if(place[0]) {
             return place[0].urlName;
            }
         }
      };
   return (<> {scrapes && scrapes.map((scrape) => (
        <div key={scrape._id} className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-blue-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          
          <Link  to={`/place/${placeUrl(scrape.placeId)}/edit/scrapes`}
            className={`  text-black`}
          >
            <p className="underline">{placeName(scrape.placeId)}</p>
            <div>
              {scrape.status === 'seen' ? '' : <span>🆕{scrape.error.length>0&&'⚠️'}{scrape.fail.length>0&&'⛔'}</span> }  Scrape at {new Date(scrape.scrapedAt).toLocaleString('en-US', options)}
            </div>
            <p>Inserted: {scrape.inserted}, Updated: {scrape.updated}</p>
          {scrape.success.length > 0 && (
            <div>
              <p>{scrape.success.length} New successful scrapes.</p>

            </div>
          )}
          {scrape.error.length > 0 && (
            <div>
              <p>{scrape.error.length} ⚠️ Errors</p>
            </div>
          )}
          {scrape.fail.length > 0 && (
            <div>
                <div>⛔ Fails: {scrape.fail.map((x) => (
                  <span key={x} className="inline-block mr-2">
                    <span to={x} className="text-red-500  underline">
                      {x}
                    </span>
                  </span>
                ))}</div>
                   
                </div>
          )}
          </Link>

       

      </div>
      ))}
      </>)
} 
export default ScrapeBlobs;