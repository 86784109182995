import React, { useState } from "react";
import PlaceInfo from "./PlaceInfo";
import '../../custom.css'
import DeletePlace from "./DeletePlace";
function DisplayPlaces({places, edit,organizers,onChange,gatekeeper,region}) {

      
    return (
        places ? <div className="masonry-grid ">

            {places.map((place)=>

            <div className="masonry-grid-item" key={place.id}>
             {edit&& <DeletePlace place={place} onChange={onChange} />}
                <PlaceInfo region={region} place={place} organizers={organizers} light={true} noMap={true} onChange={onChange} />
            </div>
        )}
        </div> : 
        <div>No places</div>

    )

    }
export default DisplayPlaces