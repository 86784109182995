import React, {useState,useEffect} from 'react';
import { useForm, Controller,useFieldArray } from "react-hook-form"
function AddIntegrations({ place, integrations, onChange }) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [close, setClose] = useState(false);
    const types = [
      'ScreeningEvent', 
      'LiteraryEvent', 
      'EducationEvent', 
      'ExhibitionEvent', 
      'VisualArtsEvent', 
      'SocialEvent', 
      'MusicEvent', 
      'DanceEvent', 
      'PublicationEvent', 
      'SaleEvent', 
      'TheaterEvent', 
      'ChildrensEvent', 
      'ComedyEvent',
      'Festival',
      'Event'
    ];      const listingModel = {
      "id": 147894,
      "uuid": "3fe357c0-64a1-4d9c-8b3a-987b9bccb891",
      "createdAt": "2023-12-10T05:27:54.602Z",
      "type": "Screening",
      "placeId": 144455899,
      "url": "https://www.possible.com/event",
      "name": "Some Times Organizer name or Type is in the Title",
      "description": "Other times in a uniform way the organizer name or the type in mentioned. For example it may say: Organizer Presents: A Screening of a movie.",
      "startDate": "2023-12-13T08:00:00.000Z",
      "endDate": null,
      "alternateName": "Here may also have a clue",
      "room": "",
      "inLanguage": "",
      "isAccessibleForFree": null,
      "cost": null,
      "note": "Here can also have a clue",
      "sponsor": "",
      "image": "",
      "alt": "",
      "keywords": "Organizer, Names, Or type can be here, Depending on the Integration",
      "doorTime": null,
      "status": "live",
      "regionId": 1,
      "timezone": "America/Los_Angeles",
      "address": "123 Wilshire Blvd, Los Angeles, CA 90016",
      "workingText": "Here can also have good info",
    };

    const relListingModel = {
      "url": "https://www.possible.com/event",
      "name": "Some Times Organizer name or Type is in the Title",
      "description": "Other times in a uniform way the organizer name or the type in mentioned. For example it may say: Organizer Presents: A Screening of a movie.",
      "alternateName": "Here may also have a clue",
      "room": "",
      "note": "Here can also have a clue",
      "sponsor": "",
      "alt": "",
      "keywords": "Organizer, Names, Or type can be here, Depending on the Integration",
      "workingText": "Here can also have good info",
    };

    function SuccessMessage({ data }) {
        return (
          <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">
              {data}
          </div>
        );
      }
      function FailMessage({data}) {
        return (
          <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
          {data ? data : 'Error Adding Integration'}
          </div>
        );
      }
  const {
    watch,
    control,
    register,
    handleSubmit, formState: { errors }, setError, getValues, reset, setValue} = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "catches", // Name of the field array in your form data
      });
  const type = watch('type');
  const organizer = watch('organizer');
  const litingType = watch('litingType');
  useEffect(()=>{
    setValue('catches','')
  },[type])

  const onSubmit = async (data) => {
        // Check if type is OrganizerCatch and catches are empty
        if (data.type === 'OrganizerCatch' && (!data.catches || data.catches.length === 0)) {
            setErrorMessage(<FailMessage data='At least one catch is required' />);
              return; 
        }
  if (window.confirm("Confirm Create Integration")!==true) {
      return;
  }
    try {
      console.log(data);
      // Send a POST request to your server to save the data
      const response = await fetch(`/api/region/${place.region.urlName}/place/${place.urlName}/edit/integration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Reset the form after successful submission
        const data = await response.json();
        console.log(data);
        setErrorMessage(<SuccessMessage data={data.message} />);
        onChange();
        // Handle success or navigate to a different page
      } else {
        setErrorMessage(<FailMessage  />);
        console.error('Error creating integration:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage  />);
      console.error('Error creating integration:', error);
    }
  };
  const renderCatchFields = () => {
    return fields.map((catchField, index) => (
      <div key={catchField.id} className='flex flex-wrap items-center gap-x-2 gap-y-1 mb-2'>
        {/* Index Label */}
        <div className="">
        <label className="text-sm font-medium text-gray-900">{index + 1}:</label>
      </div>
        {/* Input Fields and Selects */}
        <div className=' flex flex-wrap'>
          {index !== 0 && (
            <select {...register(`catches.${index}.type`)} className="m-1 text-md border bg-gray-50 text-gray-900 border-gray-300 hover:border-gray-500 rounded-lg px-4 py-2 focus:outline-none focus:shadow-outline">
<option key="OR" value="OR">
              OR       
              </option>
              <option key="AND" value="AND">
              AND       
              </option>
              <option keyå="NOT" value="NOT">
              NOT        
              </option>
              <option key="(" value="(">
              (       
              </option>
              <option key=")" value=")">
              )       
              </option>            </select>
          )}
  
          <select {...register(`catches.${index}.field`)} className="border m-1 bg-gray-50 text-green-500 border-gray-300 hover:border-gray-500 px-4 py-2 pr-6 rounded focus:outline-none focus:shadow-outline text-md">
          <option key="" value="">
            Select field
        </option>
        {Object.entries(relListingModel).map(([key, value])=>(
 <option key={key} value={key}>
 {key}
  </option>
        ))}
          </select>
  <div>
 <input {...register(`catches.${index}.catch`, { required: 'Catch term is required' })}
            className="border mx-1 bg-gray-50 text-blue-500 border-gray-300 hover:border-gray-500 px-4 py-2 pr-6 rounded focus:outline-none focus:shadow-outline text-md"
            placeholder={'Field catch term'}
          />
  </div>
         
        </div>
  
        {/* Remove Button */}
        <div className='flex-none ml-auto'>
          <button type="button" onClick={() => remove(index)} className="bg-red-500 text-white hover:bg-red-600 rounded p-2 h-10">
            Remove
          </button>
        </div>
  
        {/* Error Messages */}
        {errors.catches && errors.catches[index] && (
          <div className="w-full mt-2 border border-red-400 bg-red-100 px-4 py-3 text-red-700">
            <p>{errors.catches[index].catch?.message}</p>
          </div>
        )}
      </div>
    ));
  };
  

  

  return (

    <div className="m-4">
            <p className="text-lg pb-2">Add New Integration:</p>
<form onSubmit={(handleSubmit(onSubmit))} className="flex flex-col space-y-4">

<div >
<label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Select Integration Type:</label>
<select {...register("type", { required: 'Type is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
            Select type
        </option>
        {integrations&& integrations.map(integration=>(
 <option key={integration.uuid} value={integration.name}>
 {integration.name}
  </option>
        ))}
  
    </select>
</div>
{errors.type && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.type.message}</p>
                </div>
              </div>
)}
<div  className="mb-6">
<label htmlFor="permissionsSelect" className="block mb-2 text-sm font-medium text-gray-900">Choose Your Access Level:</label>
<select {...register("action", { required: 'Access level is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
Select Access Level
        </option>
       <option key="add" value="add">
      Full - Manage Live Listings (Add/Edit)
               </option>
        <option key="suggest" value="suggest">
        Suggest - Edit & New Listing Suggestions
                </option>
                <option key="suggest" value="suggest">
        Disabled - Temporarily inactive
                </option>
    </select>
</div>
{errors.permissions && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.permissions.message}</p>
                </div>
              </div>
)}
<div>
<label className="block mb-2 text-sm font-medium text-gray-900 "> Integration Label: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("name",  {required: 'Label is required'})} />   
     </div>
     {errors.name && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.name.message}</p>
                </div>
              </div>
)}  
{type === 'Scraper' && <div>
<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> Repository URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url")} />   
     </div>

    </div>}

    {type === 'ICS' && <div>
<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> ICS URL:  </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url",  {required: 'ICS feed is required'})} />   
     </div>
     {errors.url && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.url.message}</p>
                </div>
              </div>
)}
    </div>}
    {type === 'Eventbrite' && <div>
<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> Eventbrite API Key:  </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("key",  {required: 'API Key is required'})} />   
     </div>
     {errors.key && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.key.message}</p>
                </div>
              </div>
)}
    </div>}
    {type === 'TMDB' && <div>



</div>}

{type === 'OrganizerCatch' && <div>
<div className="mb-6">
<label htmlFor="organizerSelect" className="block mb-2 text-sm font-medium text-gray-900">Select an Organizer:</label>
<select {...register("organizer", { required: 'Organizer is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">       
 <option key="" value="">
        Select Organizer
        </option>
        {place.placeOrganizers.map(po=>(
            <option key={po.organizer.id} value={po.organizer.id}>
                    {po.organizer.name}
               </option>))}
         </select>
         {errors.organizer && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.organizer.message}</p>
                </div>
              </div>
)}
</div>
<div className="mb-6">
          {!close ? <div className='w-full'>
            Sample Listing Data - <button onClick={()=>setClose(!close)} className='text-blue-600 underline'>Close</button>
          {Object.entries(listingModel).map(([key, value])=>(
            <p key={key} value={key}>
            <span className='text-green-500'>{key}:</span> <span className='text-blue-400'>{value}</span>
            </p>))
 }
          </div>: <button onClick={()=>setClose(!close)} className='text-blue-600 underline'>Open Sample Listing Data</button>}
          <label className="block mb-2 text-sm font-medium text-gray-900">Catches:</label>
          {renderCatchFields()}
          <button
            type="button"
            onClick={() => append({ catch: "", organizer })}
            className="bg-green-500 text-white hover:bg-green-600 rounded px-2 py-1 m-2"
          >
            Add Catch
          </button>
        </div>
    </div>}
    {type === 'TypeCatch' && <div>
<div className="mb-6">
<label htmlFor="litingType" className="block mb-2 text-sm font-medium text-gray-900">Select a Listing Type:</label>
<select {...register("listingType", { required: 'Listing Type is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">       
 <option key="" value="">
        Select Listing Type
        </option>
        {types.map(lType=>(
            <option key={lType} value={lType}>
                    {lType}
               </option>))}
         </select>
         {errors.litingType && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.litingType.message}</p>
                </div>
              </div>
)}
</div>
<div className="mb-6">
          {!close ? <div className='w-full'>
            Sample Listing Data - <button onClick={()=>setClose(!close)} className='text-blue-600 underline'>Close</button>
          {Object.entries(listingModel).map(([key, value])=>(
            <p key={key} value={key}>
            <span className='text-green-500'>{key}:</span> <span className='text-blue-400'>{value}</span>
            </p>))
 }
          </div>: <button onClick={()=>setClose(!close)} className='text-blue-600 underline'>Open Sample Listing Data</button>}
          <label className="block mb-2 text-sm font-medium text-gray-900">Catches:</label>
          {renderCatchFields()}
          <button
            type="button"
            onClick={() => append({ catch: "", litingType })}
            className="bg-green-500 text-white hover:bg-green-600 rounded px-2 py-1 m-2"
          >
            Add Catch
          </button>
        </div>
    </div>}
    {errorMessage}

    <button type="submit" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">Submit</button>

    </form>


    </div>

  );
}

export default AddIntegrations;
