import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const { token } = useParams();

  useEffect(() => {
    
    const confirm = async () => {
      try {
        const response = await fetch(`/api/confirm/${token}`);
        if (response.ok) {
          const data = await response.json();
          setConfirmationMessage(
            <p className='text-green-600 text-center text-lg font-semibold'>
              {data.message}
            </p>
          );
        } else {
          setConfirmationMessage(
            <p className='text-red-600 text-center text-lg font-semibold'>
              Invalid or expired token
            </p>
          );
        }
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error) {
        setConfirmationMessage(
          <p className='text-red-600 text-center text-lg font-semibold'>
            Invalid or expired token
          </p>
        );
        console.error(error);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    };

    confirm();
  }, [token, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = `Email Confirmation - Zeitgeists`;
  }, []);

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">
          Email Confirmation
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          {confirmationMessage}
          <div className="text-center text-gray-500">
            Redirecting to login page...
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
