import React, {useEffect, useState} from 'react';
import RegisterForm from '../../components/user/RegisterForm';
import { useParams } from 'react-router-dom';

function RegisterPage() {
  const { '*': wildcardParam } = useParams();
  const [nextPage, setNextPage] = useState(false);
  useEffect(() => {
    document.title = 'Signup - Zeitgeists';
  }, []);
  useEffect(() => {
    if (wildcardParam) {
      setNextPage(wildcardParam);
    }
  }, [wildcardParam]);

  return (
    

          <div>    
          {nextPage? <RegisterForm nextPage={nextPage} /> :<RegisterForm  /> }
          </div>


  );
}

export default RegisterPage;