function RightPanel({ setLeft, left }) {
    const handleClick = (blob) => {
        setLeft(blob);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const data = [
        {
            label: 'Accessing',
            color: 'bg-sky-400',
            text: "Zeitgeists is a nonprofit cultural events aggregator open to all. Most people using our tools won’t need an account! Open access to structured cultural event data is now available."
        },
        {
            label: 'Demonstrating',
            color: 'bg-emerald-400',
            text: "There’s much to explore. This is an evolving experiment, that includes your interaction."
        },
        {
            label: 'Curating',
            color: 'bg-indigo-400',
            text: "Not every event is here. Our focus is on capturing the cultural spirit of our places and time. We’re developing new ideas, both mental and technical, to find clarity. These efforts take time and a thoughtful community, and we thank you for contributing to both."
        },
        { 
            label: 'Interfaces', 
            color: 'bg-lime-400', 
            text: "The event data is primarily accessed through third-party interfaces such as websites and apps, facilitating a wide range of projects." 
        },
        { 
            label: 'Feeds', 
            color: 'bg-teal-400', 
            text: "Similar to RSS or iCal feeds, we offer JSON-LD feeds using schema.org vocabulary for our cultural events. Simply append '/feed' to the relevant endpoint, or use our tool to create a custom feed of events. This approach reduces computational resources while providing rich, structured data." 
        },
        { 
            label: 'API', 
            color: 'bg-orange-400', 
            text: "Our API provides JSON-LD data and allows for detailed queries. An account and API authentication are required to use the API." 
        },
        { 
            label: 'Listings', 
            color: 'bg-yellow-400', 
            text: "Listings are the individual events hosted by Places, like exhibitions or concerts. They provide details about what's happening, where, and when. If an event is on a Place's calendar, it automatically appears in our Listings." 
        },
        { 
            label: 'Places', 
            color: 'bg-purple-400', 
            text: "Places are specific venues like museums, theaters, or book stores. They host the events that make up our Listings." 
        },
        { 
            label: 'Organizers', 
            color: 'bg-blue-400', 
            text: "Organizers play a key role in the cultural landscape. They setup events, whether it's independently or in collaboration at Places." 
        },
        { 
            label: 'Regions', 
            color: 'bg-green-400', 
            text: "Regions are geographical areas that manage the Places and Organizers within them." 
        },
        { 
            label: 'Manual Entry', 
            color: 'bg-pink-400', 
            text: "Authorized users have access to a powerful interface for easily adding and editing Listings, Series, Organizers, Places, and Regions. All users can suggest edits, while authorized users have direct control over data management."
        },
        { 
            label: 'Community Council', 
            color: 'bg-red-400', 
            text: "Regional Community Councils select and maintain managers/owners for Places and Organizers, ensuring the data remains accurate and relevant." 
        },
        { 
            label: 'Integrations', 
            color: 'bg-rose-400', 
            text: "Zeitgeists connects with popular event systems like Eventbrite and iCal for event data synchronization. Much of our data is collected through community-developed scrapers, and bots check for issues and add more details."
        }
    ];
    

    return (
        <div className="pl-4 pr-4">
            <div className="grid grid-cols-6 text-2xl gap-4">
                {data.map((blob) => (
                    <div
                        key={blob.label}
                        className={`lg:col-span-3 sm:col-span-3 col-span-6 rounded-lg ${blob.color} flex justify-center align-middle py-24 cursor-pointer hover:underline ${left.label === blob.label && 'underline'}`}
                        onClick={() => handleClick(blob)}
                    >
                        <div>
                            {blob.label}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RightPanel;
