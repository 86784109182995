// PasswordResetRequest.js

import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
const [status, setStatus] = useState(false);
useEffect(() => {
    document.title = `Forgot Password - Zeitgeists`;

}, []);
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/forgot-password/${email}`);
      if(response.ok) {
        setStatus('If there is an account with that email, a reset link has been sent. Check spam folder if not received.');
        setEmail('');

      } else {
        setStatus('There was an error. Try again.')
      }
      //const data = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img class="mx-auto h-10 w-auto" src="" alt="" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">Forgot Password</h2>
      </div>
    
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" onSubmit={handleEmailSubmit}>
          <p>We'll email you instructions to reset your password.</p>
          <div>
            <label for="email"  className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div class="mt-2">
              <input id="email" name="email" type="email" autocomplete="email" value={email} onChange={(e) => setEmail(e.target.value)} required                 className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " />
            </div>
          </div>
          <div>
          <button
  type="submit"
  className="w-full px-4 py-2 text-sm font-semibold text-black bg-white border border-black rounded-md shadow-sm hover:bg-black hover:text-white focus:outline-none focus:ring focus:ring-gray-600"
  >
  Submit
</button>      </div>
          
        </form>
        { status && <div className="mt-5">
    
    <div className="border border-green-400  bg-green-100 px-4 py-3 text-green-700">
      <p> {status}</p>
    </div>
    </div>}
    <p class="mt-5 text-center text-lg text-gray-500">
          <Link to={'/login'} class="font-semibold leading-6 hover:text-black text-grey-800">Return to login</Link>
        </p>
        <p class="mt-5 text-center text-lg text-gray-500">
          Don't have an account? {' '}
          <Link to={'/signup'} class="font-semibold leading-6 hover:text-black text-grey-800">Sign up now</Link>
        </p>
       
       
    
    
      </div>
    </div>
  );
};

export default PasswordResetRequest;
