import React, { useState } from 'react';
import { useForm } from "react-hook-form";
function AddPlace({regionName,onChange}) {
  const [errorMessage,setErrorMessage] = useState(false);
  const { reset, register, handleSubmit } = useForm();
  function SuccessMessage({ data }) {
    return (
      <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">
      New place: <a className='underline' href={`place/${data.place.urlName}`}>{data.place.name}</a> added successfully
      </div>
    );
  }
  function FailMessage() {
    return (
      <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
      Error adding place</div>
    );
  }
  const onSubmit = async (data) => await goSubmit(data);

  const goSubmit = async (data) => {

    if (window.confirm("Confirm add place")!==true) {
      return;
  }
    try {
  
      // Send a POST request to your server to save the data
      
      const response = await fetch(`/api/region/${regionName}/place`, {
        method: 'POST',
        credentials: 'include', // Include cookies
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Reset the form after successful submission
        const data = await response.json()
        setErrorMessage(<SuccessMessage data={data} />);
        onChange();
        reset();

        // Handle success or navigate to a different page
      } else {
        setErrorMessage(<FailMessage />)
        console.error('Error adding place:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage/>)

      console.error('Error adding place:', error);
    }
  };
 
  
  return (
    <div className="m-4">
      <h2 className='text-bold text-lg pb-2'>Create New Region Place:</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
        {/* Render input fields for each property in the schema */}
        <div className='mb-6'>
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Place Name:</label>
          <input
          type="text"
          name="name"
          placeholder="Name"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
          {...register("name", { required: true })}
        />
        </div>
        <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url", {required: true})} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Alternative Name: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("alternateName")} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Description: </label>
        <textarea rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("description")} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Street Address: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("streetAddress", {required: true})} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">City: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("addressLocality", {required: true})} />   
     </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">State Acronym: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("addressRegion", { pattern: /^[A-Za-z]{2}$/ } , {required: true})} />   
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Postal Code: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("postalCode", { pattern: /^\d{5}$/ }, {required: true})} />   
        </div>
    <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Neighborhood: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("area")} />   
     </div>
     <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Latitude: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("latitude", { pattern: /^-?\d{1,3}\.\d{1,10}$/ })} />        
          </div>
     <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Longitude: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("longitude", { pattern: /^-?\d{1,3}\.\d{1,10}$/ })} />       
           </div>

    
        
        <button className="bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" type="submit">Create Place</button>
      </form>
      {errorMessage}
    </div>
  );
}

export default AddPlace;
