import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AddIntegrations from '../../components/place/AddIntegrations';
import EditBar from '../../components/utilities/EditPlaceBar';
import DisplayIntegrationsTable from '../../components/place/DisplayIntegrationsTable';
import DisplayLogs from '../../components/place/DisplayLogs';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditPlaceIntegrations() {
  const navigate = useNavigate();
  const { regionName,placeName } = useParams(); // Access the "urlName" route parameter
  const [loading, setLoading] = useState(true);
  const [place, setPlace] = useState(false);
  const [integrations, setIntegrations] = useState(false);
  const [placeData, setPlaceData] = useState({
    integrations: [],
    integrationLogs: [],
    place: {},
  });

  const [showLogs, setShowLogs] = useState(false); // State to toggle between logs and integrations
    useEffect(() => {
      fetchPlaceDetails();
 
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(placeData.place) {
        document.title = `Edit ${placeData.place.name} Integrations - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [placeData.place]);
    


      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/integrations`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                setPlaceData({
                  place: data.place,
                  integrations: data.integrationTypes
                });
                
                  if(response.status ===201) {
                    setPlace(data.place);
                    setIntegrations(data.integrationTypes)
                  }
                  setLoading(false);
              }
              else {
                console.error(response);
              }
        
              if (response.status === 401) {
                navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/integrations`);
              } else if (response.status === 403) {
                navigate(`/region/${regionName}/place/${placeName}/edit`);
              }
        
   
        
        } catch (error) {
          console.error(error);
        }
      }


  const toggleView = () => {
    setShowLogs(!showLogs);
  };
      const handlePlaceChange = () => {
        fetchPlaceDetails();  // Refetch data after any change
      };
    
      
// integration logs

   return (
    <LoadingScreen isLoading={loading}  message="Fetching place integrations...">

    
    <div className="p-4 text-black">
      { place && <EditBar type="place" place={place} active={'Integrations'} region={place.region} />} 
    <div>
    <div className="flex justify-end m-2 center">
        <button
          onClick={toggleView}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          {showLogs ? 'Show Integrations' : 'Show All Logs Combined'}
        </button>
      </div>

      <div>
       {placeData.place && !showLogs ?  <DisplayIntegrationsTable place={placeData.place} onChange={handlePlaceChange}   /> : <DisplayLogs place={placeData.place} onChange={handlePlaceChange} />}
      </div>
      <div >


<AddIntegrations place={place} integrations={integrations} onChange={handlePlaceChange} />

      </div>
    </div>


    
  
 
    

       </div>


    </LoadingScreen>
  );
}

export default EditPlaceIntegrations;
