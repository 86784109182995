import React, { useState } from 'react';
import ConfirmModal from '../utilities/ConfirmModal';
function DeleteOrganizer ({ organizer, onChange }) {
  const [showModal, setShowModal] = useState(false); // State for controlling modal visibility

  const handleDelete = async (organizerId) => {
    try {
        const response = await fetch(`/api/organizer/${organizerId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json', // Use a function to get the cookie value
                    },
            credentials: 'include', // Include cookies
               })
            
      if (response.status === 201) {
        //setErrorMessage('Place Deleted');
        onChange();
      } else if (response.status === 401) {
        // Handle unauthorized access
      } else {
        // Handle other errors
      }
    } catch (error) {
      //setErrorMessage('Error deleting place');
      console.error('Error deleting organizer:', error);
    }
  };


  const handleDeleteClick = (placeId) => {
    setShowModal(true); // Open the modal
  };

  const handleConfirmDelete = () => {
    if (organizer) {
      handleDelete(organizer.id); // Call the delete function when confirmed
    }
    setShowModal(false); // Close the modal after deletion
  };

  return (
    <div className="text-center">
     
        <span>
          <button
            onClick={() => handleDeleteClick(organizer.id)} // Show the modal instead of deleting immediately
            className="inline-block m-2 bg-red-500 hover:bg-red-900 hover:text-white border rounded-full border-black p-1 py-1 px-3 text-sm"
          >
            Delete {organizer.name}
          </button>
        </span>
    

      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)} // Close the modal without deleting
        onConfirm={handleConfirmDelete} // Handle actual deletion
        title={`Confirm Delete ${organizer.name}`}
        message={`Are you sure you want to delete ${organizer.name} and all attached information?`}
        verifyText={organizer.name} // Verify the deletion by typing the place name
      />
    </div>
  );
};

export default DeleteOrganizer;
