import React from 'react';

function ProfileImage({ url, alt }) {
  return (
    <div className="w-32 h-32  justify-center overflow-hidden">
      <img
        src={'/' + url}
        alt={alt}
        className="w-full h-full object-cover"
      />
    </div>
  );
}

export default ProfileImage;
