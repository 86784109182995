import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import LoadingScreen from '../../components/utilities/LoadingScreen';
import EditBar from '../../components/utilities/EditPlaceBar';
import DisplayLastEdit from '../../components/place/DisplayLastEdit';

function EditPlace() {
  const navigate = useNavigate();
  const { regionName, placeName } = useParams();
  const [loading, setLoading] = useState(true);
  const [placeData, setPlaceData] = useState({
    place: null,
    count: 0,
    scrapes: null,
    suggestions: [],
    lastEdit: null,
    seriesCount: 0,
    integrationsCount: 0,
    suggestionsCount: 0,
  });


  useEffect(() => {
    fetchPlaceDetails();
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    if (placeData.place) {
      document.title = `Edit ${placeData.place.name} - Zeitgeists`;
    } else {
      document.title = `Place - Zeitgeists`;
    }
  }, [placeData.place]);

  const fetchPlaceDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setPlaceData({
          place: data.place,
          count: data.count || 0,
          scrapes: data.scrapes,
          suggestions: data.suggestions || [],
          lastEdit: data.lastEdit,
          seriesCount: data.seriesCount || 0,
          integrationsCount: data.integrationsCount || 0,
          suggestionsCount: data.suggestionsCount || 0,
        });
        setLoading(false);
      }  else {
        console.error(response);
      }

      if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/`);
      } else if (response.status === 403) {
        navigate(`/region/${regionName}/place/${placeName}/edit`);
      }
  
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };

  return (
    <LoadingScreen isLoading={loading}  message="Fetching place...">
    <div className='p-4 text-black'>
      {placeData.place && (
        <EditBar type="place" place={placeData.place} active={'Dashboard'} region={placeData.place.region} />
      )}

      <div className='grid grid-cols-4 p-4 gap-4'>
        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-green-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <p className="text-5xl">{placeData.count}</p>
          <p className="text-xl">Upcoming Listings</p>
          <Link to={`/region/${regionName}/place/${placeName}`} className="text-blue-600 hover:underline">
            See listings
          </Link>
        </div>

        {placeData.lastEdit && <DisplayLastEdit lastEdit={placeData.lastEdit} />}

        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-purple-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
         
        <Link to={`/region/${regionName}/place/${placeName}/edit/suggestions`} className="text-blue-600 hover:underline">
       <p className="text-5xl">{placeData.suggestionsCount}</p>
          <p className="text-xl">Unapproved Listing Suggestions</p>
          </Link>
        </div>

        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-blue-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <p className="text-5xl">{placeData.seriesCount}</p>
          <p className="text-xl">Active Series</p>
          <Link to={`/region/${regionName}/place/${placeName}/edit/series`} className="text-blue-600 hover:underline">
            See series
          </Link>
        </div>

        <div className="col-span-4 md:col-span-1 sm:col-span-2 bg-red-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <Link to={`/region/${regionName}/place/${placeName}/edit/organizers`} className="text-blue-600 hover:underline">
            <p className="text-5xl">Manage</p>
            <p className="text-xl">Organizers</p>
          </Link>
        </div>

        <div className="col-span-4 md:col-span-1 sm:col-span-2 bg-pink-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <Link to={`/region/${regionName}/place/${placeName}/edit/listings`} className="text-blue-600 hover:underline">
            <p className="text-5xl">Add</p>
            <p className="text-xl">Listing</p>
          </Link>
        </div>

        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-orange-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <Link to={`/region/${regionName}/place/${placeName}/edit/integrations`} className="text-blue-600 hover:underline">
            <p className="text-5xl">{placeData.integrationsCount}</p>
            <p className="text-xl">Active Integrations</p>
          </Link>
        </div>
      </div>
    </div>
    </LoadingScreen>
  );
}

export default EditPlace;
