import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../components/utilities/LoadingScreen';
function UserDashboard() {
//we want all scrapes from all managaged listings, ability to see the listings and dismiss scrapes
//we want all suggestions and adds

// i think one place at a time
//in a row with numbers x upcoming listings x new scrapes  x listing add requests x listings edit requests -> click to open all or any in particular
// list out managed places, orgs 
//add listing tab - click on 

  const navigate = useNavigate();

  const [regions, setRegions] = useState(null);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
      fetchDashboard();
  }, []); 
  
  const fetchDashboard = async () => {
    try {
      const response = await fetch('/api/dashboard', {
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
      credentials: 'include', // Include cookies
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
        setRegions(data.regions)
      }
      else {
        console.error(response);
        if (response.status === 401) {
          navigate('/login'); // Redirect to login
        }
      }
      setLoading(false);
    } catch (error) {
      //console.log('Error fetching listings', error);

    }
  };
  useEffect(() => {
      document.title = `Dashboard - Zeitgeists`;
  }, []);


  return (
    <LoadingScreen isLoading={loading} message="Loading dashboard...">
    <div className='mt-20'>
      <div className=' text-center'>
      <p className='text-3xl text-center font-bold'>Dashboard</p>
        <p className='text-lg mt-4'>Manage your regions, places, and organizers. For any assistance, please reach out to <a href='mailto:contact@zeitgeists.org' className='text-blue-500 underline'>contact@zeitgeists.org</a>.</p>
     </div>
      <div className='grid grid-cols-4 p-4'>
        {user && user.userRegions && user.userRegions.length > 0 && (
          <div className='col-span-4 text-center border-black border border-b-0 p-2'>
            <span className='font-bold text-lg'>Region{regions.length > 0 && 's'}: </span>
            {user.userRegions.map(ur => (
              <span className='inline-block mt-2 mr-2' key={ur.region.id}>
                <Link className='p-2 border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform' to={`/region/${ur.region.urlName}/`}>{ur.region.name}</Link>
                <Link className='p-2 border-black border-l-0 border-2 bg-green-500 hover:bg-green-700 text-white hover:scale-105 transition-transform' to={`/region/${ur.region.urlName}/edit`}>Dashboard</Link>
              </span>
            ))}
          </div>
        )}

        {/* Repeat for places */}
          {user && user.userPlaces && user.userPlaces.length > 0 && (
            <div className='col-span-4 text-center border-black border border-b-0 pt-2'>
              <span className='font-bold text-lg'>Places: </span>
              {user.userPlaces.map(up => (
                <span className='inline-block m-3' key={up.place.id}>
                  <Link className='p-2 rounded-l-lg border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform' to={`/region/${up.place.region.urlName}/place/${up.place.urlName}/`}>{up.place.name}</Link>
                  <Link className='p-2 rounded-r-lg border-black border-l-0 border-2 bg-green-500 hover:bg-green-700 text-white hover:scale-105 transition-transform' to={`/region/${up.place.region.urlName}/place/${up.place.urlName}/edit`}>Dashboard</Link>
                </span>
              ))}
            </div>
          )}

        {/* Repeat for organizers */}
        
          {user && user.userOrganizers && user.userOrganizers.length > 0 && (
            <div className='col-span-4 text-center border-black border border-b-0  pt-1 pb-2'>
              <span className='font-bold text-lg'>Organizers: </span>
              {user.userOrganizers.map(uo => (
                <span className='inline-block m-3' key={uo.organizer.id}>
                  <Link className='p-2 rounded-l-full border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform' to={`/region/${uo.organizer.region.urlName}/organizer/${uo.organizer.urlName}/`}>{uo.organizer.name}</Link>
                  <Link className='p-2 rounded-r-full border-black border-l-0 border-2 bg-green-500 hover:bg-green-700 text-white hover:scale-105 transition-transform' to={`/region/${uo.organizer.region.urlName}/organizer/${uo.organizer.urlName}/edit`}>Dashboard</Link>
                </span>
              ))}
            </div>
          )}

        <div className='col-span-4 text-center border-black border pt-1 pb-2'>
          <p className='font-bold text-lg'>Quick Links</p>
          <p>
            <Link className='p-2 font-medium text-xl text-blue-500 underline' to={`/api`}>API</Link>
            <Link className='p-2 font-medium text-xl text-blue-500 underline' to={`/faq`}>FAQ</Link>
            <Link className='p-2 font-medium text-xl text-blue-500 underline' to={`/listings`}>Listings</Link>
          </p>
        </div>
      </div>
    </div>
    </LoadingScreen>
  );
}

export default UserDashboard;



