import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//import DisplayEditListing from '.../components/DisplayEditListing';
import EditListingNew from '../../components/listing/EditListingNew';
import ListingBox from '../../components/listing/ListingBox';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditListing() {
    const navigate = useNavigate();
    const [listing, setListing] = useState(false)
    const [editor, setEditor] = useState(false)
    const [suggestions, setSuggestions] = useState(false);
    const [loading, setLoading] = useState(true);
    //get place by string

    useEffect(() => {
      // Fetch region details when the component mounts
        fetchListing();
      
    }, []);
  
    useEffect(() => {
      document.title = `Edit Listing - Zeitgeists`;

  }, []);
    
      const { listingId } = useParams(); // Access the "urlName" route parameter

      const fetchListing = async () => {
        try {
            const response = await fetch(`/api/listing/${listingId}/edit`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                setListing(data.listing);
                setEditor(data.editor);
                setSuggestions(data.suggestions);
                //not sure if boolean
              } 
            setLoading(false);
              if(response.status===401) {
                navigate(`/login?redirect=/listing/${listingId}/edit`)
          
              }
        } catch (error) {
          console.error('Network or other error:', error);
        }
      }
//create edit listing blurb add requests blurb
   return (
    <LoadingScreen isLoading={loading}  message="Loading edit listing...">

    <div className="m-4 mt-20 md:mt-14"> 
        {(listing ) && <ListingBox  listing={listing} suggestions={suggestions}  editor={editor} editing={true}  />}

    {(listing ) && <div> 
    <EditListingNew onChange={fetchListing} listing={listing} editor={editor}/>
    </div> 
      
}

    </div>
</LoadingScreen>

    
  );
}

export default EditListing;
