
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Datehandling({parsedDates,setValue,region}) {
  const [note,setNote]=useState('');
  const [datesInput, setDatesInput] = useState('');
  const [singleStartDate, setSingleStartDate] = useState(parsedDates.length == 1 ? parsedDates[0].startDate : new Date());
  const [singleEndDate, setSingleEndDate] = useState(parsedDates.length == 1 ? parsedDates[0].endDate : new Date());
  const [multiDateInput, setMultiDateInput] = useState(parsedDates.length > 1 ? true :false);

  const validateDate = (startDate, endDate) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);  // Normalize to start of day for fair comparison.
    if (startDate < now || endDate < now) {
      setNote('Date must be in the future.');
      return false;
    }
    if (endDate < startDate) {
      setNote('End date must be after start date.');
      return false;
    }
    setNote('');
    return true;
  };

  const handleStartDateChange = (date) => {
    // Check if the new start date is after the current end date
    if (date > singleEndDate) {
      const newEndDate = new Date(date.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours to the start date
  
      // Set the new start date and new end date
      setSingleStartDate(date);
      setSingleEndDate(newEndDate);
      setValue('parsedDates', [{ startDate: date, endDate: newEndDate }]);
    } else {
      // If the new start date is valid and not after the end date, update only the start date
      setSingleStartDate(date);
      setValue('parsedDates', [{ startDate: date, endDate: singleEndDate }]);
    }
  };
  
  const handleEndDateChange = (date) => {
    if (!validateDate(singleStartDate, date)) {
      setSingleEndDate(singleEndDate); // Revert to last valid date if new date is invalid
    } else {
      setSingleEndDate(date);
      if(!multiDateInput)setValue('parsedDates', [{ startDate: singleStartDate, endDate: date }])
    }
  };


  const parseDates = (input) => {
    const dateRegex = /(\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}) - (\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2})/g;
    const matches = [...input.matchAll(dateRegex)];
    const dates = matches.map(match => ({
      startDate: new Date(match[1]),
      endDate: new Date(match[2]),
    }));
    const validDates = dates.filter(date => validateDate(date.startDate, date.endDate));
    setValue('parsedDates', validDates);
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    setDatesInput(input);
    parseDates(input);
  };

  const addDate = () => {
    if (validateDate(singleStartDate, singleEndDate)) {
      setValue('parsedDates', [...parsedDates, { startDate: singleStartDate, endDate: singleEndDate }]);
    }
  };

  const removeDate = (indexToRemove) => {
    setValue('parsedDates', parsedDates.filter((_, index) => index !== indexToRemove));
  };

  const toggleMultiDateInput = () => {
    setMultiDateInput(!multiDateInput);
    setValue('parsedDates', [{ startDate: singleStartDate, endDate: singleEndDate }]);
  };

  const updateDate = (index, newStartDate, newEndDate) => {
    if (validateDate(newStartDate, newEndDate)) {
      const updatedDates = [...parsedDates];
      updatedDates[index] = { startDate: newStartDate, endDate: newEndDate };
      setValue('parsedDates', updatedDates);
    }
  };
   // Helper function to format the date and time in the required format
   const formatEventTime = (startDate, endDate) => {
    const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: region.timezone };

    const startTime = startDate.toLocaleTimeString('en-US', optionsTime);
    const startDateStr = startDate.toLocaleDateString('en-US', optionsDate);
    const endTime = endDate.toLocaleTimeString('en-US', optionsTime);
if(parsedDates.length>0) {
  return `The event starts at ${startTime} ${region.name} time on ${startDateStr} until ${endTime} time on ${startDateStr}`;

}
  };
  return (
    <div>
      {multiDateInput ? (
        <>
         
          
          <h3 className="mb-2 font-heavy text-lg">Multiple Event Dates Input:</h3>

          <div className="mt-4 flex flex-items-center space-x-2 mb-2  mx-1'">
            <div>


          <label className="block mb-2 text-sm font-medium text-gray-900">
Start Date          </label>
          <DatePicker
            selected={singleStartDate}
            onChange={setSingleStartDate}
            showTimeSelect
            dateFormat="Pp"
            className="w-full p-2 border rounded mb-2  mx-1"
          />
                      </div>

          <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
End Date          </label>
          <DatePicker
            selected={singleEndDate}
            onChange={setSingleEndDate}
            showTimeSelect
            dateFormat="Pp"
            className="w-full p-2 border rounded mb-2"
          />
                    </div>


          <button onClick={addDate} className="bg-blue-500 text-white px-2 py-1 rounded mt-2  mx-1" type="button">
            Add Date
          </button>
          
            </div>
            <button onClick={toggleMultiDateInput} className="bg-green-500 text-white px-2 py-1 rounded  mb-6" type="button">
            Switch to Single Date
          </button>
            <label className="block mb-2 text-sm font-medium text-gray-900">
            Enter multiple dates in the format: MM/DD/YYYY HH:MM - MM/DD/YYYY HH:MM; Separate each date range with a semicolon.
          </label>
          <textarea
            value={datesInput}
            onChange={handleInputChange}
            placeholder="Enter dates in the format: 11/20/2024 13:00 - 11/20/2024 15:00; 11/2/2024 15:00 - 11/2/2024 16:00"
            rows="4"
            className="w-full p-2 border rounded mb-2"
          />
        </>
      ) : (
        <div >
         
    
         <h3 className="mb-2 font-heavy text-lg">Single Event Date Input:</h3>

 
                    <div className='flex space-x-2 '>
                            {/* Displaying the formatted event range in plain text */}
    
                      <div>

                    <label className="block mb-2 text-sm font-medium text-gray-900">
Start Date          </label>
          <DatePicker
            selected={singleStartDate}
            onChange={handleStartDateChange}
            showTimeSelect
            dateFormat="Pp"
            minDate={new Date()}
            className="w-full p-2 border-2 rounded mb-2 mx-1"
          />

          </div>

              <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
End Date          </label>
          <DatePicker
            selected={singleEndDate}
            onChange={handleEndDateChange}
            showTimeSelect
            dateFormat="Pp"
            minDate={singleStartDate}
            className="w-full p-2 border-2 rounded mb-2  mx-1  "
          />
          </div>

      
        
        </div>
        <p className="mt-4 mb-4 text-lg">
          {formatEventTime(singleStartDate, singleEndDate)}
        </p>

        <button onClick={toggleMultiDateInput} className="bg-gray-500 text-white px-2 py-1 rounded mb-6" type="button">
         Switch to Multiple Dates Input
         </button>
        </div>
      )}

      {multiDateInput && <div className="mt-4">
        <h3 className="mb-2">Parsed Dates:</h3>
        <p>Click on a date to edit it. You can also remove a date by clicking the 'Remove' button.</p>
        {parsedDates.map((date, index) => { return (
          <div key={index} className="flex flex-col md:flex-row md:space-x-4 mb-4">
  <div className="flex flex-col md:items-end mb-2 md:mb-0">
    <label className="block mb-2 text-sm font-medium text-gray-900">Start Date</label>
    <DatePicker
      selected={date.startDate}
      onChange={(newDate) => updateDate(index, newDate, date.endDate)}
      showTimeSelect
      minDate={new Date()}
      dateFormat="Pp"
      className="border-2 rounded-sm p-1"
    />
  </div>

  <div className="flex flex-col md:items-end mb-2 md:mb-0">
    <label className="block mb-2 text-sm font-medium text-gray-900">End Date</label>
    <DatePicker
      selected={date.endDate}
      onChange={(newDate) => updateDate(index, date.startDate, newDate)}
      minDate={date.startDate}
      showTimeSelect
      dateFormat="Pp"
      className="border-2 rounded-sm p-1"
    />
  </div>

  {/* Aligning the remove button with the bottom of the input fields */}
  <div className="flex md:items-end">
    <button
      onClick={() => removeDate(index)}
      className="bg-red-500 text-white px-2 py-1 rounded w-full md:w-auto"
      type="button"
    >
      Remove
    </button>
  </div>
</div>

      
        )})}
      </div>}

  
      {note&&<p className="text-red-500">{note}</p>}
    </div>
  );
}

export default Datehandling;
