import React, { useState, useRef, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './form.css';
import { useForm, Controller } from 'react-hook-form';

function RegisterForm({ nextPage }) {
  function FailMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }
  function SuccessMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-green-400 bg-green-100 px-4 py-3 text-green-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }
  const [status, setStatus] = useState('');
  const [success, setSuccess] = useState(false);
  const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const { handleSubmit, watch, control, register, setError, reset, formState: { errors }} = useForm();

  const verifyToken = async (token) => {
    try {
      const response = await fetch('/api/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reCAPTCHA_TOKEN: token,
        }),
      });

      if (!response.ok) {
        //throw an Error('Network response was not ok');
      }

      const data = await response.json();
      return [data];
    } catch (error) {
      console.error('Error verifying token:', error);
      throw error;
    }
  };

  


  const onSubmit = async (formData) => {
    setStatus('');
    let token = captchaRef.current.getValue();
    if (token) {
      let valid;
      try {
        valid = await verifyToken(token);
      } catch (error) {
        console.error('Error verifying token:', error);
        setStatus(<FailMessage message={'Verify that you are not a bot'} />);
        return;
      }
      
      if (valid.length>0&&valid[0].success) {
        if (formData.password !== formData.confirmPassword) {
          setStatus(<FailMessage message={'Passwords do not match'} />);
        } else 
        {
          // Send registration data to the server (you'll need to implement this)
          const { username, email, password } = formData;
          try{
            const response = await fetch('/api/register', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ username, email, password }),
            });
            if(response.ok) {
              setStatus(<SuccessMessage message={'Account Created! Check for confirmation email. Redirecting to login now.'} />);
              setSuccess(true);
              reset();
              
              setTimeout(() => {
                if (nextPage) {
                  navigate(`/`);
                } else {
                  navigate('/');
                }
              }, 6000); // Redirect after 2 seconds
            }
            if (!response.ok) {
              if(response.status==400) {
                const data = await response.json();
                if(data.errors) {
                  data.errors.map(error=>setError(error.name,{message: error.message}));
                }
        
              }
            }
          } catch (error) {
            <FailMessage message={'Registration Failed'} />
console.error(error);
          }
     
          
        }
      } 
    }
      else {
        console.log('Not verified');
        setStatus(<FailMessage message={'Verify that you are not a bot'} />);
      }
      captchaRef.current.reset();


  };

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
  {success ? (
    <div className="mt-16 text-center">
      <p className="inline-block text-2xl font-bold text-green-600">Account Successfully Created!</p>
      <p className="mt-4 text-lg text-gray-800">Please check your inbox for an email from <span className="font-semibold">noreply@zeitgeists.org</span> to activate your account.</p>
      <p className="mt-2 text-lg text-gray-800">If you don't see the email, please check your spam or junk folder.</p>
      <p className="mt-6 text-xl text-gray-700">Redirecting to home now...</p>
    </div>
  ) : (
    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Create an account</h2>
  )}
</div>


     {!success && <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
              Username
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="username"
                name="username"
                {...register('username', {
                  required: 'Username is required',
                  pattern: {
                    value: /^[A-Za-z\d]{3,}$/,
                    message: 'Username should contain at least 3 characters with only lowercase letters and numbers.',
                  },
                })}
                autoComplete="username"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              />
            </div>
            {errors.username && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.username.message}</p>
                </div>
              </div>
            )}
          </div>
                    <div className="mt-2">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid email format',
                  },
                })}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              />
            </div>
            {errors.email && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.email.message}</p>
                </div>
              </div>
            )}
          </div>

  
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
            </div>
            <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              {...register('password', {
                required: 'Password is required',
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
                                    message: 'Password should be at least 8 characters long, with 1 number, 1 lowercase, 1 uppercase, and 1 special character.',
                },
                
              })}
              autoComplete="new-password"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            />
            </div>
            {errors.password && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.password.message}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-2">
        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
          Confirm Password
        </label>
        <div className="mt-2">
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: 'Confirm Password is required',
              validate: (value) => value === watch('password') || 'Passwords do not match',
            }}
            render={({ field }) => (
              <input
                {...field}
                type="password"
                autoComplete="new-password"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              />
            )}
          />
        </div>
        {errors.confirmPassword && (
          <div className="mt-5">
            <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
              <p>{errors.confirmPassword.message}</p>
            </div>
          </div>
        )}
      </div>
                <div className="m-3">
                  <div className="flex justify-center">
                    <ReCAPTCHA className="recaptcha" sitekey={SITE_KEY} ref={captchaRef} />
                  </div>
                </div>
                {status}

                <div>
                  <button
                    type="submit"
                    className="w-full px-4 py-2 text-sm font-semibold text-black bg-white border border-black rounded-md shadow-sm hover:bg-black hover:text-white focus:outline-none focus:ring focus:ring-gray-600"
                 >
                    Sign up
                  </button>
                </div>
        </form>
        <p className="mt-5 text-center text-sm text-gray-500">
          Already have an account?{' '}
          {nextPage ? (
            <Link to={`/login?redirect=/${nextPage}`} className="font-semibold leading-6 text-black hover:text-black">
              Login now
            </Link>
          ) : (
            <Link to={'/login'} className="font-semibold leading-6 text-black hover:text-black">
              Login now
            </Link>
          )}
        </p>
      </div> }
    </div>
  );
}

export default RegisterForm;
