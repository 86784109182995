import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import EditPlace from '../../components/place/EditPlace';
import DeletePlaceListings from '../../components/place/DeletePlaceListings';
import EditPlaceBar from '../../components/utilities/EditPlaceBar';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditPlaceSettings() {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);
    const [region,setRegion] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPlaceDetails();
      
    }, []);
    
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        alert('Copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    };
  
    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Settings - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

    const fetchPlaceDetails = async () => {
      try {
        const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/settings`, {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
    
        if (response.ok) {
          const data = await response.json();
          setPlace(data.place);
          setRegion(data.region);
        }     else {
          console.error(response);
        }
        setLoading(false);
        if (response.status === 401) {
          navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/settings`);
        } else if (response.status === 403) {
          navigate(`/region/${regionName}/place/${placeName}/edit`);
        }
      } catch (error) {
        console.error('Network or other error:', error);
      }
    };
//display place people

   return (
    <LoadingScreen isLoading={loading}  message="Fetching place settings...">

    <div className="p-4">

 {place &&  <EditPlaceBar active="Settings" type="place" place={place} region={region} />}
    <div className="content flex-grow">
     {place&&region &&  <EditPlace onChange={fetchPlaceDetails} place={place} region={region} />}
</div>
{place && <div className="mt-60">
  <p className='font-bold underline'>Details</p>
  <div>
      <p><strong>UUID:</strong> - <span onClick={() => copyToClipboard(place.uuid)} style={{ cursor: 'pointer', color: 'blue' }}>{place.uuid}</span></p>
      <p><strong>ID:</strong> - <span onClick={() => copyToClipboard(place.id)} style={{ cursor: 'pointer', color: 'blue' }}>{place.id}</span></p>
      <p><strong>REGION ID:</strong> - <span onClick={() => copyToClipboard(region.id)} style={{ cursor: 'pointer', color: 'blue' }}>{region.id}</span></p>
      <p><strong>REGION UUID:</strong> - <span onClick={() => copyToClipboard(region.uuid)} style={{ cursor: 'pointer', color: 'blue' }}>{region.uuid}</span></p>
    </div>
</div> }
{place &&<div className="mt-60"> 
  <p className='text-red-500 underline'>Danger</p>
  <DeletePlaceListings place={place} />
</div> }
    </div>
</LoadingScreen>

    
  );
}

export default EditPlaceSettings;
