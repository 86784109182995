import React, { useState } from 'react';

function DeletePlaceListings({ place }) {
  const [message, setMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const openModal = () => {
    setInputValue('');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMessage('');
  };

  const confirmDelete = async (placeId) => {
    if (inputValue !== place.name) {
      setMessage(`Please type "${place.name}" correctly to confirm deletion.`);
      return;
    }

    if (window.confirm('Are you sure you want to delete all listings? This action cannot be undone.') !== true) {
      return;
    }

    try {
      const response = await fetch(`/api/place/${placeId}/listings`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        setMessage('All listings have been deleted successfully.');
      } else {
        setMessage(data.message || 'Failed to delete listings.');
      }
      closeModal();
    } catch (error) {
      console.error('Error deleting listings:', error);
      setMessage('Error deleting listings.');
      closeModal();
    }
  };

  return (
    <div className="m-2">
      {place && (
        <>
          <button
            onClick={openModal}
            className='rounded bg-red-500 p-2 hover:bg-red-800 text-white'
          >
            Delete All Place Listings
          </button>

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded shadow-lg max-w-md w-full">
                <h2 className="text-lg font-bold mb-2">Confirm Deletion</h2>
                <p>Type "{place.name}" to confirm deletion:</p>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="border p-2 m-2 w-full"
                  placeholder={place.name}
                />
                <button
                  onClick={() => confirmDelete(place.id)}
                  className='bg-red-500 hover:bg-red-800 text-white rounded p-2 mt-2'
                  disabled={inputValue !== place.name}
                >
                  Confirm Delete
                </button>
                <button
                  onClick={closeModal}
                  className='bg-gray-300 hover:bg-gray-500 text-black rounded p-2 mt-2 ml-2'
                >
                  Cancel
                </button>
                <p className="text-red-500 mt-2">{message}</p>
              </div>
            </div>
          )}
        </>
      )}
      <br />
      {message && <div className="mt-2 text-red-500">{message}</div>}
    </div>
  );
}

export default DeletePlaceListings;
