import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import EditPlaceBar from '../../components/utilities/EditPlaceBar';
import AddListingIn from '../../components/listing/AddListingIn';
import LoadingScreen from '../../components/utilities/LoadingScreen'; 
function EditPlaceListings() {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);
    const [region,setRegion] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      // Fetch region details when the component mounts
        fetchPlaceDetails();

      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Listings - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

    const fetchPlaceDetails = async () => {
      try {
      const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/settings`, {
        headers: {
        'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setPlace(data.place);
        setRegion(data.region);
      } else {
        console.error(response);
      }
      setLoading(false);
      if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/listings`);
      } else if (response.status === 403) {
        navigate(`/region/${regionName}/place/${placeName}/edit`);
      }
      } catch (error) {
      console.error('Network or other error:', error);
      }
    };

   return (
    <LoadingScreen isLoading={loading}  message="Fetching place listings...">

    <div className="p-4 ">

 {place &&  <EditPlaceBar active="Add Listing" type="place" place={place} region={region} />}
    <div className="content flex-grow text-black">
     {place&&region &&  <AddListingIn place={place} regionIn={region}  />}

</div>

    </div>

</LoadingScreen>
    
  );
}

export default EditPlaceListings;
