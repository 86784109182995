import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
function AdminManageIntegration({ selectedIntegration, setSelectedIntegration, onChange }) {
  const [errorMessage, setErrorMessage] = useState(false);
  const [gitUrl, setGitUrl] = useState(selectedIntegration.repo || '');

  function SuccessMessage({ data }) {
    return (
      <div className="border text-xl border-green-400 bg-green-100 px-4 py-3 text-green-700">
        {data}
      </div>
    );
  }

  function FailMessage({ data }) {
    return (
      <div className="border text-xl border-red-400 bg-red-100 px-4 py-3 text-red-700">
        {data ? data : 'Error Editing Integration'}
      </div>
    );
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      directory: selectedIntegration.directory || null,
      status: selectedIntegration.status,
      frequency: selectedIntegration.frequency || null,
      action: selectedIntegration.action || null,
    },
  });

  useEffect(() => {
    console.log(selectedIntegration);
    setValue('directory', selectedIntegration.directory || null);
    setValue('status', selectedIntegration.status);
    setValue('frequency', selectedIntegration.frequency);
    setValue('action', selectedIntegration.action);
    setGitUrl(selectedIntegration.repo || '');
  }, [selectedIntegration]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`/api/admin/integration/${selectedIntegration.uuid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        setErrorMessage(<SuccessMessage data={result.message} />);
        onChange();
      } else {
        setErrorMessage(<FailMessage />);
      }
    } catch (error) {
      setErrorMessage(<FailMessage />);
    }
  };

  const handleQueueRun = async () => {
    try {
      const response = await fetch(`/api/admin/integration/${selectedIntegration.uuid}/queue-run`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        const result = await response.json();
        setErrorMessage(<SuccessMessage data="Integration run queued successfully!" />);
        onChange();
      } else {
        setErrorMessage(<FailMessage data="Failed to queue run." />);
      }
    } catch (error) {
      setErrorMessage(<FailMessage data="Error queuing integration run." />);
    }
  };

  const handleUpdateGitUrl = async () => {
    try {
      const response = await fetch(`/api/admin/integration/${selectedIntegration.uuid}/update-repo`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ repo: gitUrl }),
      });

      if (response.ok) {
        const result = await response.json();
        setErrorMessage(<SuccessMessage data="Git URL updated successfully!" />);
        onChange();
      } else {
        setErrorMessage(<FailMessage data="Failed to update Git URL." />);
      }
    } catch (error) {
      setErrorMessage(<FailMessage data="Error updating Git URL." />);
    }
  };

  const handleImportCode = async () => {
    try {
      const response = await fetch(`/api/admin/integration/${selectedIntegration.uuid}/import-code`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        const result = await response.json();
        setErrorMessage(<SuccessMessage data="Code imported from Git URL successfully!" />);
        onChange();
      } else {
        setErrorMessage(<FailMessage data="Failed to import code from Git URL." />);
      }
    } catch (error) {
      setErrorMessage(<FailMessage data="Error importing code from Git URL." />);
    }
  };

  return (
    <div className="m-4 shadow rounded-md px-6 py-4 border-4 border-yellow-200">
      <div className='text-right'>
        <button className='underline text-red-500 hover:font-semibold' onClick={() => setSelectedIntegration(false)}>Close</button>
      </div>
      <p className='text-xl text-blue-500 underline '><Link target='_blank' to={`/region/${selectedIntegration.place.region.urlName}/place/${selectedIntegration.place.urlName}/edit/integrations`}>View {selectedIntegration.place.name} Integration Page</Link></p>
      <div>
        <span className="font-bold mr-2">Label:</span>
        <span className="mr-4">{selectedIntegration.name}</span>

        <span className="font-bold mr-2">Type:</span>
        <span className="mr-4">{selectedIntegration.integrationType.name}</span>

        <span className="font-bold mr-2">Status:</span>
        <span className="mr-4">{selectedIntegration.status}</span>

        <span className="font-bold mr-2">Frequency:</span>
        <span className="mr-4">{selectedIntegration.frequency}</span>

        <span className="font-bold mr-2">Directory:</span>
        <span className="mr-4">{selectedIntegration.directory}</span>

        <span className="font-bold mr-2">Git repo:</span>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
          value={gitUrl}
          onChange={(e) => setGitUrl(e.target.value)}
        />
        <button onClick={handleUpdateGitUrl} className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded">
          Update Git URL
        </button>
        <button onClick={handleImportCode} className="ml-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded">
          Import Code
        </button>

        <span className="font-bold mr-2">Place:</span>
        <span className="mr-4">{selectedIntegration.place.name}</span>

        <span className="font-bold mr-2">Region:</span>
        <span className="mr-4">{selectedIntegration.place.region.name}</span>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
        {/* Status Select */}
        <div>

        {/* Frequency Select */}
        <div>
          <label htmlFor="frequencySelect" className="block mb-2 text-sm font-medium text-gray-900">Change Frequency:</label>
          <select {...register("frequency")} className="block appearance-none w-full bg-gray-50 border text-gray-900 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none">
            <option value="">Select Frequency</option>
            <option value="daily">Daily - Runs every night</option>
            <option value="weekly">Weekly - Runs once every week</option>
          </select>
          {errors.frequency && <div className="mt-5 text-red-500">{errors.frequency.message}</div>}
        </div>

          <label htmlFor="statusSelect" className="block mb-2 text-sm font-medium text-gray-900">Change Status:</label>
          <select {...register("status", { required: 'Status is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none">
            <option value="">Select Status</option>
            <option value="new">New - Inactive awaiting approval</option>
            <option value="active">Active - Approved, running integration</option>
            <option value="review">Review - Integration under review</option>
            <option value="paused">Paused - Integration temporarily paused</option>
            <option value="rejected">Rejected - Integration denied or frequent errors</option>
          </select>
          {errors.status && <div className="mt-5 text-red-500">{errors.status.message}</div>}
        </div>

        {/* Permissions Select */}
        <div  className="mb-6">
<label htmlFor="permissionsSelect" className="block mb-2 text-sm font-medium text-gray-900">Choose Your Access Level:</label>
<select {...register("action", { required: 'Access level is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
Select Access Level
        </option>
       <option key="add" value="add">
      Full - Manage Live Listings (Add/Edit)
               </option>
        <option key="suggest" value="suggest">
        Suggest - Edit & New Listing Suggestions
                </option>
                <option key="suggest" value="suggest">
        Disabled - Temporarily inactive
                </option>
    </select>
</div>
{errors.permissions && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.permissions.message}</p>
                </div>
              </div>
)}

        {/* Directory Input */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900 "> Integration Directory: what comes after ~/cronjobs/{selectedIntegration.place.region.urlName}/ </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg p-2.5"
            {...register("directory")}
          />
          {errors.directory && <div className="mt-5 text-red-500">{errors.directory.message}</div>}
        </div>

        {/* Submit Button */}
        <button type="submit" className="bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">Update</button>

      </form>

      {/* Queue Run Button */}
      <button onClick={handleQueueRun} className="mt-4 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
        Queue Run
      </button>

     {/* Display Integration Runs */}
<div className="mt-6">
  <h3 className="text-lg font-semibold mb-4">Integration Run Logs</h3>
  <table className="min-w-full bg-white border border-gray-300">
    <thead>
      <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
        <th className="py-3 px-6 text-left">Run At</th>
        <th className="py-3 px-6 text-left">Status</th>
        <th className="py-3 px-6 text-left">Updated</th>
        <th className="py-3 px-6 text-left">Added</th>
        <th className="py-3 px-6 text-left">Errors</th>
      </tr>
    </thead>
    <tbody className="text-gray-600 text-sm font-light">
      {selectedIntegration.runs.length > 0 ? (
        selectedIntegration.runs.map((run) => (
          <tr key={run.uuid} className="border-b border-gray-200 hover:bg-gray-100">
            <td className="py-3 px-6">{new Date(run.createdAt).toLocaleString('en-US')}</td>
            <td className={`py-3 px-6 ${run.status === 'finished' ? 'text-green-500' : 'text-yellow-500'}`}>
              {run.status}
            </td>
            <td className="py-3 px-6">{run.updated || '-'}</td>
            <td className="py-3 px-6">{run.added || '-'}</td>
            <td className="py-3 px-6">
              {run.errorLogs && run.errorLogs.length > 0 ? (
                <div className="text-red-500">
                  {run.errorLogs.map((error, index) => (
                    <p key={index}>{error.message}</p>
                  ))}
                </div>
              ) : (
                <span className="text-green-500">No errors</span>
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5" className="text-center py-4 text-gray-500">
            No integration run logs available.
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>


      {errorMessage}
    </div>
  );
}

export default AdminManageIntegration;
