import React, {useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
function Logout() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
      document.title = `Logout - Zeitgeists`;
  }, []);
  const handleLogout = async () => {

    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include', 
      });
      
      if (response.ok) {
        await logout()
        navigate('/login');
      } else {
        //need to handle
      }
    } catch (error) {
    }
  };

  return (


<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 mt-36">
  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
    <img className="mx-auto h-10 w-auto" src="" alt="" />
    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">Are you sure you want to logout?</h2>
  </div>

  <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    
      <div>
      <button
  type="submit"
  className="w-full px-4 py-2 text-sm font-semibold text-black bg-white border border-black rounded-md shadow-sm hover:bg-black hover:text-white focus:outline-none focus:ring focus:ring-gray-600" onClick={handleLogout}
>
Log Out</button>

      </div>

</div>
   
    </div>
  );
}

export default Logout;
