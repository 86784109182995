import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import EditOrganizerBar from '../../components/utilities/EditOrganizerBar';
import AddListingIn from '../../components/listing/AddListingIn';
import LoadingScreen from '../../components/utilities/LoadingScreen';


function EditOrganizerListings() {
  const navigate = useNavigate();
  const { regionName, organizerName } = useParams();
const [loading, setLoading] = useState(true);
  const [organizerData, setOrganizerData] = useState({
    organizer: null,
    places: null,
    region: null,
    count: 0,
    scrapes: null,
    suggestions: [],
    lastEdit: null,
    seriesCount: 0,
    integrationsCount: 0,
    suggestionsCount: 0,
    power: false,
  });


  useEffect(() => {
      fetchOrganizerDetails();
 
  }, []);

  useEffect(() => {
    if (organizerData.organizer) {
      document.title = `Edit ${organizerData.organizer.name} - Zeitgeists`;
    } else {
      document.title = `Organizer - Zeitgeists`;
    }
  }, [organizerData.organizer]);

  const fetchOrganizerDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({
          organizer: data.organizer,
          places: data.places || [],
          region: data.region,
          count: data.count || 0,
          scrapes: data.scrapes,
          suggestions: data.suggestions || [],
          lastEdit: data.lastEdit,
          seriesCount: data.seriesCount || 0,
          integrationsCount: data.integrationsCount || 0,
          suggestionsCount: data.suggestionsCount || 0,
          power: data.power,
        });
        setLoading(false);
      } else if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/organizer/${organizerName}/listings`);
      }  if (response.status === 403) {
        navigate(`/region/${regionName}/organizer/${organizerName}`);
      } 
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };
// add, settings, people, dashboard, places,

  return (
    <LoadingScreen isLoading={loading} message="Fetching organizer details...">

    <div className='p-4'>
{organizerData && organizerData.region && organizerData.organizer && <EditOrganizerBar region={organizerData.region} organizer={organizerData.organizer} active={"/listings"} />}

      <div  className="content flex-grow">
      {organizerData && organizerData.region && organizerData.organizer && <AddListingIn regionIn={organizerData.region} organizer={organizerData.organizer} />}

      </div>

    </div>
    </LoadingScreen>
  );
}

export default EditOrganizerListings;
