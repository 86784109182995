import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import DisplayLastEdit from '../../components/place/DisplayLastEdit';

import EditOrganizerBar from '../../components/utilities/EditOrganizerBar';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditOrganizerDetail() {
  const navigate = useNavigate();
  const { regionName, organizerName } = useParams();
  const [loading, setLoading] = useState(true);
  const [organizerData, setOrganizerData] = useState({
    organizer: null,
    places: null,
    region: null,
    count: 0,
    scrapes: null,
    suggestions: [],
    lastEdit: null,
    seriesCount: 0,
    integrationsCount: 0,
    suggestionsCount: 0,
    locationsCount: 0,
    power: false,
  });


  useEffect(() => {
      fetchOrganizerDetails();
      window.scrollTo(0, 0);

  }, []);


  useEffect(() => {
    if (organizerData.organizer) {
      document.title = `Edit ${organizerData.organizer.name} - Zeitgeists`;
    } else {
      document.title = `Organizer - Zeitgeists`;
    }
  }, [organizerData.organizer]);

  const fetchOrganizerDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({
          organizer: data.organizer,
          places: data.places || [],
          region: data.region,
          count: data.count || 0,
          scrapes: data.scrapes,
          suggestions: data.suggestions || [],
          lastEdit: data.lastEdit,
          seriesCount: data.seriesCount || 0,
          integrationsCount: data.integrationsCount || 0,
          suggestionsCount: data.suggestionsCount || 0,
          locationsCount: data.locationsCount || 0,
          power: data.power,
        });
        setLoading(false); // Set loading to false after data is fetched
      }
       else if (response.status === 401) {
        navigate(`/login/region/${regionName}/organizer/${organizerName}/edit`);
      }  if (response.status === 403) {
        navigate(`/region/${regionName}/organizer/${organizerName}`);
      } 
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };
// add, settings, people, dashboard, places,

  return (
    <LoadingScreen isLoading={loading} message="Fetching organizer details...">
    <div className='p-4'>
{organizerData && organizerData.region && organizerData.organizer && <EditOrganizerBar region={organizerData.region} organizer={organizerData.organizer} active={"/dashboard"} />}

      <div className='grid grid-cols-4 p-4 gap-4'>
        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-green-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <p className="text-5xl">{organizerData.count}</p>
          <p className="text-xl">Upcoming Listings</p>
          <Link to={`/region/${regionName}/organizer/${organizerName}`} className="text-blue-600 hover:underline">
            See listings
          </Link>
        </div>

        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-blue-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <p className="text-5xl">{organizerData.seriesCount}</p>
          <p className="text-xl">Active Series</p>
          <Link to={`/region/${regionName}/organizer/${organizerName}/edit/series`} className="text-blue-600 hover:underline">
            See Series
          </Link>
        </div>

        {organizerData.lastEdit && <DisplayLastEdit lastEdit={organizerData.lastEdit} />}
        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-orange-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{organizerData.locationsCount}</p>
            <p className="text-xl">Locations</p>
            <Link to={`/region/${regionName}/organizer/${organizerName}/edit/locations`} className="text-blue-600 hover:underline">
            See Locations
          </Link>
        </div>


        <div className="col-span-4 md:col-span-1 sm:col-span-2 bg-red-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <Link to={`/region/${regionName}/organizer/${organizerName}/edit/places`} className="text-blue-600 hover:underline">
            <p className="text-5xl">Manage</p>
            <p className="text-xl">Places</p>
          </Link>
        </div>

        <div className="col-span-4 md:col-span-1 sm:col-span-2 bg-pink-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
          <Link to={`/region/${regionName}/organizer/${organizerName}/edit/listings`} className="text-blue-600 hover:underline">
            <p className="text-5xl">Add</p>
            <p className="text-xl">Listing</p>
          </Link>
        </div>

        

        <div className="col-span-4 sm:col-span-2 md:col-span-1 bg-purple-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{organizerData.suggestionsCount}</p>
            <p className="text-xl">Unapproved Listing Suggestions</p>
        </div>

     
      </div>

    </div>
    </LoadingScreen>
  );
}

export default EditOrganizerDetail;
