import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
function SetLocation({region,nameLocation, register,organizer,setValue,control,locationId}) {
    const [locations, setLocations] = useState([]);
    const [newLocation, setNewLocation] = useState(false);
    const fetchExistingTempPlaces = async () => {
      try {
        const response = await fetch(`/api/region/${region.urlName}/organizer/${organizer.urlName}/edit/locations`, {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setLocations(data.locations);
        } else {
          console.error('Failed to fetch locations:', response.statusText);
        }
      } catch (error) {
        console.error('Network or other error:', error);
      }
    };
  
    useEffect(() => {
      if (region && organizer) {
        fetchExistingTempPlaces();
      }
    }, [region, organizer]);
    const handleLocationChange = (locationId) => {

      const selectedLocation = locations.find((location) => location.id === parseInt(locationId));
      if (selectedLocation) {
        setValue('locationId', selectedLocation.id);
        setValue('nameLocation', selectedLocation.name);
      }
    };
    useEffect(() => {
      if (locationId) {
        setNewLocation(false)
      }
    }, [locationId]);
    const handleNewLocation = () => {
        setNewLocation(true);
        setValue('locationId', '');
        setValue('nameLocation', '');
    };
  return (
    <div className="mt-10">
        <p className='text-sm font-medium mt-4'>Add Location</p>
 
        {locations.length > 0 && (
        <div className="mt-4">
          <p className="text-md font-medium">Existing Organizer Event Locations</p>
          <div className="mb-6">
            <Controller
              control={control}
              name="locationId"
              rules={{ required: 'Selecting a location is required' }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <label htmlFor="locationSelect" className="block mb-2 text-sm font-medium text-gray-900">
                    Select a Location:
                  </label>
                  <select
                    id="locationSelect"
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e); // react-hook-form's onChange
                      handleLocationChange(e.target.value);
                    }}
                    className="block w-full bg-gray-50 border text-gray-900 border-gray-300 px-4 py-2 rounded"
                  >
                    <option value="">Select an existing Organizer Location</option>
                    {locations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
        </div>
      )}


        <p className='mb-6'>If your event is taking place at a location that isn’t listed as a Place on the platform or in a temporary, unconventional Location (like a pop-up screening or a venue with increased capacity), please use this option to add the details.</p>     
        
        {/* Render input fields for each property in the schema */}
       { !newLocation && <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleNewLocation}
        >
          Add Custom Location 
        </button> }
        {newLocation && <div>
                  <div className='mb-6'>
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Location Name:</label>
          <input
          type="text"
          name="name"
          placeholder="Name"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
          {...register("nameLocation")}
        />
        </div>
        <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Description: </label>
        <textarea rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("descriptionLocation")} />   
     </div>
        
<div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("urlLocation")} />   
     </div>
    
   
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Street Address: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("streetAddressLocation")} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">City: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("addressLocalityLocation")} />   
     </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">State Acronym: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("addressRegionLocation", { pattern: /^[A-Za-z]{2}$/ } )} />   
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Postal Code: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("postalCodeLocation", { pattern: /^\d{5}$/ })} />   
        </div>

    <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Neighborhood: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("areaLocation")} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Alternative Name: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("alternateNameLocation")} />   
     </div>
     <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Latitude: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("latitudeLocation", { pattern: /^-?\d{1,3}\.\d{1,10}$/ })} />        
          </div>
     <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 ">Longitude: </label>
          <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("longitudeLocation", { pattern: /^-?\d{1,3}\.\d{1,10}$/ })} />       
           </div>

        </div>}
          </div>
  );
}

export default SetLocation;
