import { set } from "date-fns";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

function SetSeries({ placeId, organizers, control, organizerID, seriesFields, removeSeries, appendSeries,watch,getValues,setValue,locationId, }) {
    if(getValues("allSeries")===undefined)setValue("allSeries",[])
    const allSeries = watch("allSeries");
    const fetchSeries = async function fetchSeries() {
        try {
            if(!placeId&&!locationId) return;
        
            const url = `/api/series?placeId=${placeId}&organizers=${organizerID ? [organizerID] : organizers}`;
            const response = await fetch(url, { credentials: "include" });  
            const resData = await response.json();
            setValue('allSeries', resData.series);
        } catch (error) {
            console.error("Failed to fetch series:", error);
        }
    }

    useEffect(() => {
        if (placeId) {
            fetchSeries();
        }
        }, [placeId]);
        useEffect(() => {
                fetchSeries();
            }, []);
        
  
    const handleAddSeries = uuid => {
        if (!seriesFields.some(field => field.uuid === uuid)) {
            const seriesToAdd = allSeries.find(series => series.uuid === uuid);
            if (seriesToAdd) {
                appendSeries({ uuid: seriesToAdd.uuid });
            }
        }
    };


    return (
        <div className="text-black">
            {allSeries&&seriesFields.length>0&&
            <div className="mt-5 mb-10">
              <div className="text-lg font-medium">Listing is a part of:</div>
    {seriesFields.map((series, index) => {const seriesData = allSeries.find(i=>i.uuid===series.uuid) || {}; return (
                <div key={series.id} className="flex justify-between items-center mb-4">
                    <div className="text-lg">
                      Series:     <Link target="_blank" to={`/region/${seriesData.region?.urlName}/series/${series.uuid}`} className="hover:underline text-blue-600">
                           {seriesData?.name}
                        </Link></div>

                    <button type="button" onClick={() => removeSeries(index)} className="ml-4 py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded shadow">
                        Remove
                    </button>
                </div>
            )})}
            </div>
          }
            {allSeries.length > 0 ? 
              <div className="mb-4">
                <div className="text-lg font-medium">Possible Series:</div>
                <div className="text-md">If an existing series is not displayed, make sure to first add the associated organizers. Hidden series are not displayed here.</div>
{allSeries.filter(series => !seriesFields.some(field => field.uuid === series.uuid)).map(series => (
                    <div key={series.uuid} className="flex justify-between items-center mb-4">
                        <Link target="_blank" to={`/series/${series.uuid}`} className="hover:underline text-blue-600">
                            <h3 className="text-lg">{series.name} ({series.listings?.length} listings)</h3>
                        </Link>
                        <button type="button" onClick={() => handleAddSeries(series.uuid)} className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded shadow">
                            Add To Series
                        </button>
                    </div>
                ))}
              </div>
                
             : <div>There are no series for the place and organizers.</div>}
        </div>
    );
}

export default SetSeries;
