import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ProfileImage from '../../components/user/ProfileImage';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function UserProfile() {
  const navigate = useNavigate()
  const [profile, setProfile] = useState(null);
  const [places, setPlaces] = useState(null);
  const [organizers, setOrganizers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { username } = useParams();

  useEffect(() => {
    if(profile) {
      document.title = `@${profile.username} - Zeitgeists`;
    } else {
      document.title = `Profile - Zeitgeists`;
    }
  }, [profile]);


// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(() => {
    fetchUser()
}, [username,navigate]);

const fetchUser = async () => {
  try {
    const response = await fetch(`/api/user/${username}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      if (data.redirect) {
        navigate('/user');
      }
      setProfile(data.user);
      setOrganizers(data.user.userOrganizers);
      setPlaces(data.user.userPlaces);
    } 
    setLoading(false);
  } catch (error) {
    console.error('Error fetching user', error);
    // Handle error
  }
};

  return (

<LoadingScreen isLoading={loading} message="Fetching user details...">
    <div className='p-4 text-center mt-36'>
       {profile && 
    <div className='p-3 text-xl'>
    <div  className='flex items-center text-center justify-center'>
        {profile.image && <ProfileImage url={profile.image} alt={profile.alt} />}
      </div>              
      <p>{profile.name} </p> 
    <p>@{profile.username}</p>
    {profile.userRegions&&  profile.userRegions.map(ur=>( 
        <span className='inline-block mt-2 mr-2' key={ur.region.id}>
Member of the  {' '}
        <Link  to={`/region/${ur.region.urlName}`} className="underline ">
            {ur.region.name} Council
        </Link> 
        </span>
      ))}
    {profile.listingEdits.length > 0 && <p>{profile.listingEdits.length} Edits</p> }
    <p> 
      { places && places.map(up=>( 
        <span className='inline-block mt-2' key={up.place.id}>
        <Link  to={`/region/${up.place.region.urlName}/place/${up.place.urlName}`} className=" rounded-lg mr-1 border border-1 border-black p-1 hover:text-white hover:bg-black ">
            {up.place.name}
        </Link>
        </span>
      ))}
      {organizers  && organizers.map((uo) => (
                <span className='inline-block mt-2' key={uo.organizer.id}>

              <Link key={uo.organizer.id} to={`/region/${uo.organizer.region.urlName}/organizer/${uo.organizer.urlName}`} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
                {uo.organizer.name}
              </Link>
              </span>
      ))}
  </p>
    {profile.region && <p>Council of <Link to={`/region/${profile.region.urlName}`}>{profile.region.name}</Link></p>}
    <p>{profile.description}</p>
    


    </div>
}
    </div>
    </LoadingScreen>
  );
}

export default UserProfile;
