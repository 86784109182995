import React, { useState } from 'react';
import DisplayLogs from './DisplayLogs'; // Assuming you have a DisplayLogs component
import ManageIntegration from './ManageIntegration';

function DisplayIntegrationsTable({ place, onChange }) {
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const toggleExpandRow = (integrationUuid, type) => {
    if (expandedRow === integrationUuid) {
      setExpandedRow(null);
      setSelectedIntegration(null);
    } else {
      setExpandedRow(integrationUuid);
      if (type === 'manage') {
        setSelectedIntegration(place.placeIntegrations.find((pi) => pi.uuid === integrationUuid));
      }
    }
  };

  return (
    place && (
      <div className="p-4">
        {place.placeIntegrations && place.placeIntegrations.length > 0 && (
          <div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow overflow-hidden rounded-md">
                <thead>
                  <tr className="bg-gray-100 border-b">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Control</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Frequency</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Permissions</th>
                  </tr>
                </thead>
                <tbody>
                  {place.placeIntegrations.sort((a, b) => {
            // Sort hidden series last
            if (a.status !== 'active' && b.status === 'active') return 1;
            if (a.status === 'active' && b.status !== 'active') return -1;
    
            // Sort hidden series last
            if (a.status !== 'new' && b.status === 'new') return 1;
            if (a.status === 'new' && b.status !== 'new') return -1;
    
          }).map((pi) => (
                    <React.Fragment key={pi.uuid}>
                      <tr className="border-b">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col space-y-2">
                            <button
                              className='px-2 py-1 text-white bg-blue-500 hover:bg-blue-700 rounded-md text-md w-20'
                              onClick={() => toggleExpandRow(pi.uuid, 'manage')}
                            >
                              Manage
                            </button>
                            <button
                              className='px-2 py-1 text-white bg-orange-500 hover:bg-orange-700 rounded-md text-md w-20'
                              onClick={() => toggleExpandRow(pi.uuid, 'logs')}
                            >
                              Logs
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex px-2 py-1 rounded-full text-xs font-semibold bg-gray-200">
                            {pi.integrationType.name}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">{pi.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`inline-flex px-2 py-1 rounded-full text-xs font-semibold ${
                              pi.status === 'active' ? 'bg-green-200 text-green-800' :
                              pi.status === 'paused' ? 'bg-yellow-200 text-yellow-800' :
                              pi.status === 'new' ? 'bg-blue-200 text-blue-800' :
                              pi.status === 'disabled' ? 'bg-gray-200 text-gray-800' :
                              'bg-red-200 text-red-800'
                            }`}
                          >
                            {pi.status.toUpperCase()}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`inline-flex px-2 py-1 rounded-full text-xs font-semibold ${
                              pi.frequency === 'daily' ? 'bg-green-200 text-green-800' :
                              pi.frequency === 'weekly' ? 'bg-blue-200 text-blue-800' :
                              'bg-gray-200 text-gray-800'
                            }`}
                          >
                            {pi.frequency ? pi.frequency.toUpperCase() : 'N/A'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`inline-flex px-2 py-1 rounded-full text-xs font-semibold ${
                              pi.action === 'add' ? 'bg-blue-200 text-blue-800' :
                              pi.action === 'disabled' ? 'bg-gray-200 text-gray-800' :
                              pi.action === 'suggest' ? 'bg-purple-200 text-purple-800' :
                              'bg-red-200 text-red-800'
                            }`}
                          >
                            {pi.action.toUpperCase()}
                          </span>
                        </td>
                      </tr>
                      {expandedRow === pi.uuid && (
                        <tr>
                          <td colSpan="6" className="px-6 py-4 bg-gray-50">
                            {selectedIntegration?.uuid === pi.uuid ? (
                              <ManageIntegration
                                place={place}
                                integrations={place.placeIntegrations}
                                selectedIntegration={selectedIntegration}
                                setSelectedIntegration={setSelectedIntegration}
                                onChange={onChange}
                              />
                            ) : (
                              <DisplayLogs place={place} placeIntegration={pi.id} limit={10} />
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default DisplayIntegrationsTable;
