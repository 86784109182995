import { useParams } from "react-router-dom";
import { useEffect } from "react";
import OrganizerDetail from "./OrganizerDetail";

function OrganizerReload() {
    const { regionName,organizerName } = useParams();

    useEffect(() => {
    },[organizerName])
    return (
        <OrganizerDetail key={organizerName} organizerName={organizerName}  regionName={regionName} />
        )
    
}
export default OrganizerReload;