import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Questions() {
    const [qa,setQA] = useState(0);

  const data = [
  {
    question: "What is this website?",
    answer: "Zeitgeists is a nonprofit cultural events aggregator open to all. Most people using our tools won’t need an account!",
  },
  {
    question: "What cultural events are included?",
    answer: "The platform hosts a range of cultural events including film screenings, art exhibitions, literary events, and more, all selected by our community councils. Zeitgeists.org serves as a collective effort to enrich the cultural scene by providing reliable, structured information.",
  },
  {
    question: "Why does this exist?",
    answer: "Zeitgeists.org was developed to provide a structured, accessible resource for cultural event data. It aims to facilitate a more intuitive and diverse cultural engagement by offering free data for developers and efficient tools for organizers.",
  },
  {
    question: "How does it support developers?",
    answer: "Developers have access to free, structured event data, enabling them to build innovative tools and applications. Organizers and venues use the platform to manage and share event details, reaching a wider audience efficiently.",
  },
  {
    question: "How can I view events?",
    answer: "There is currently no official Zeitgeists.org app. However, several third-party clients are available, and you can explore them to view events.",
  },
  {
    question: "Can anyone contribute?",
    answer: "Yes, contributions from the community are essential. Whether it's through editing an error in a listing, managing a place’s information, participating in local councils, or using our free API for your apps, there are many ways to be involved.",
  },

  {
    question: "More questions?",
    answer: <>Email us at <Link to="mailto:contact@zeitgeists.org" className="underline">contact@zeitgeists.org</Link>.</>,
  },
];
    return (
  <div className="divide-y">
          {data.map((q, i) => (
            <div key={i} className="m-10">
              <button onClick={() => setQA(i)} className={`text-3xl hover:text-gray-500 ${qa === i && 'font-bold shadow-md'}`}>
                {q.question}
              </button>
              {qa === i && <div className="text-2xl mt-4">{q.answer}</div>}
            </div>
          ))}
        </div>  
    );
}

export default Questions;