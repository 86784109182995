import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AddPlaceOwners from '../../components/place/AddPlaceOwners';
import AddPlaceManagers from '../../components/place/AddPlaceManagers';

import EditPlaceBar from '../../components/utilities/EditPlaceBar';
import LoadingScreen from '../../components/utilities/LoadingScreen';
function EditPlacePeople() {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);
    const [region,setRegion] = useState(false);

  const [loading, setLoading] = useState(true);

    //get place by string
    const [userList,setUserList] = useState(false);
    const [editPlace,setEditPlace] = useState(false);

    useEffect(() => {
      // Fetch region details when the component mounts
        fetchPlaceDetails();
  
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} People - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

  // Fetch the region details based on regionName and render them
  const fetchPlaceDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/people`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setPlace(data.place);
        setRegion(data.region);
        setUserList(data.users);
        setEditPlace(data.edit);
      }
      else {
        console.error(response);
      }
      setLoading(false);
      if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/people`);
      } else if (response.status === 403) {
        navigate(`/region/${regionName}/place/${placeName}/edit`);
      }


    } catch (error) {
      console.error('Network or other error:', error);
    }
  };

//display place people

   return (
    <LoadingScreen isLoading={loading}  message="Fetching place people...">
    <div className="p-4">

 { place && <EditPlaceBar active="People" type="place" place={place} region={region} />}
    <div className="content flex-grow">


{place && userList && <AddPlaceManagers place={place} userList={userList} power={editPlace} onChange={fetchPlaceDetails} />}
 {place && userList && <AddPlaceOwners place={place}  userList={userList} power={editPlace} onChange={fetchPlaceDetails} />}

    
  
 
    

    </div>
       </div>

</LoadingScreen>
    
  );
}

export default EditPlacePeople;
