function LastRunInfo({ placeIntegration }) {
  // Find the latest run by sorting the runs based on the createdAt date
  const lastRun = placeIntegration.runs
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

  const lastRunError = lastRun?.errorLogs.length > 0 ? lastRun.errorLogs[0].message : null;

  return (
    <div className="mt-2">
      {lastRun ? (
        <>
          <p>Last Run Status: {lastRun.status}</p>
          <p>Run Date: {new Date(lastRun.createdAt).toLocaleString()}</p>
          {lastRunError ? (
            <p className="text-red-500">Error: {lastRunError}</p>
          ) : (
            <p className="text-green-500">No errors reported.</p>
          )}
        </>
      ) : (
        <p>No runs available.</p>
      )}
    </div>
  );
}

export default LastRunInfo;
