import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Assuming React Router is being used

function AddListings() {
    const [pageData, setPageData] = useState({ places: [], organizers: [] });

    useEffect(() => {
        listingPlaces();
    }, []);

    const listingPlaces = async () => {
        try {
            const fetchUrl = `/api/listings/add`;
            const response = await fetch(fetchUrl, { credentials: 'include' });
            const data = await response.json();
            setPageData(data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 text-black border border-black rounded-lg">
            {
                pageData && pageData.display      && <div className="mb-4">
                {pageData.places.length === 0 && pageData.organizers.length === 0 ? (
                    <div className="bg-yellow-100 border-4 border-yellow-500 text-yellow-700 p-4 ml-1 mr-1">
                        <p>
                            It looks like you don't have any places or organizers linked to your account yet. No worries!
                        </p>
                        <p className="mt-4">
                            The ability to suggest edits to existing places and suggest new listings for non-listed places is coming soon.
                        </p>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {pageData.places.length > 0 && (
                            <div>
                                <h3 className="text-xl font-semibold mb-2">Your Places</h3>
                                <ul className="space-y-3">
                                    {pageData.places.map((place, index) => (
                                            <Link key={index} to={`${place.url}/edit/listings`} className="font-medium block text-center">
                                            <button className="h-16 rounded-lg border border-black p-4 hover:bg-black hover:text-white transition-colors">
                                            {place.name}
                                            </button>
                                            </Link>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {pageData.organizers.length > 0 && (
                            <div>
                                <h3 className="text-xl font-semibold mb-2">Your Organizers</h3>
                                <ul className="flex flex-wrap gap-4">
                                    {pageData.organizers.map((organizer, index) => (
                                        <li 
                                            key={index} 
                                            className="w-24 h-16 flex items-center justify-center rounded-full border border-black hover:bg-black hover:text-white transition-colors"
                                        >
                                            <Link to={`${organizer.url}/edit/listings`} className="font-medium block text-center">
                                                {organizer.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
            }
     
            <h2 className="text-2xl font-bold mb-4 ml-2">Listings</h2>
            <p className="bg-green-200 p-5 m-5">
                If you're looking to discover events, this isn't the best place. Use a third-party interface or access the data via our <Link to="/api" className="text-blue-600 hover:text-blue-800">API</Link>. For more information, visit our <Link to="/faq" className="text-blue-600 hover:text-blue-800">FAQ</Link>.
            </p>
            <p className="bg-yellow-200 p-5 m-5">
                This page serves as a demonstration and is not intended for finding events or managing listings. For best results, please manage listings on the individual pages associated with your places or organizers. If you're looking for events or need to spot errors, you can explore the appropriate <Link to="/regions" className="text-blue-600 hover:text-blue-800">regions, places, or organizers</Link> through the designated interface.
            </p>
            <p className="bg-red-200 p-5 m-5">
                Below are links to add listings for each of the places and organizers you directly manage.
            </p>
            <p className="bg-purple-200 p-5 m-5 font-medium">
                A unified listing management interface is coming soon. For now, please use the individual pages associated with your places and organizers to manage listings.
            </p>
        </div>
    );
}

export default AddListings;
