import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

function DisplaySuggestedAdd({ suggestion, open, listing }) {
  const [openSuggestion, setOpenSuggestion] = useState(open || false);

  useEffect(() => {
    setOpenSuggestion(open || false);
  }, [open]);

  function formatISOInLocal(isoString) {
    return moment(isoString).format('MMM DD, h:mm A');
  }

  return (
    <div key={suggestion.id} className="p-2 mt-2 border border-black rounded shadow-sm mb-2">
      <p className="text-sm">
        Status:{' '}
        <span
          className={`${
            suggestion.status === 'unapproved'
              ? 'bg-yellow-400 text-yellow-900'
              : suggestion.status === 'approved'
              ? 'bg-green-400 text-green-900'
              : suggestion.status === 'rejected'
              ? 'bg-red-400 text-red-900'
              : ''
          } px-2 py-1 rounded capitalize`}
        >
          {suggestion.status}
        </span>
      </p>

      {suggestion.createdAt && (
        <div className="text-xs text-gray-600 mt-1">
          Submitted At: {formatISOInLocal(suggestion.createdAt)} by{' '}
          {suggestion.user ? (
            <Link to={`/user/${suggestion.user.username}`} className="text-blue-400">
              @{suggestion.user.username}
            </Link>
          ) : (
            'Integration'
          )}
        </div>
      )}

      {openSuggestion && <div className="text-xs mt-1">Opened</div>}

      {suggestion.comment && (
        <p className="text-xs mt-2">
          Comment: <span className="bg-gray-100 p-1 rounded">{truncateString(suggestion.comment, 100)}</span>
        </p>
      )}
    </div>
  );
}

export default DisplaySuggestedAdd;
