import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const EditPlaceBar = ({ active, onMenuItemClick, type, place, region }) => {
  const [info, setInfo] = useState(false);

  const fetchSidebarInfo = async () => {
    const response = await fetch(`/api/sidebar/type/${type}/id/${place.id}`, {credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      setInfo(data.toReturn);
    }
  };

  useEffect(() => {
    fetchSidebarInfo();
  }, []);

  const style = (url) => {
    return active !== url
      ? "p-2 rounded-lg border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform"
      : "p-2 rounded-lg border-black border-2 bg-black text-white hover:scale-105 transition-transform";
  };

  return (
    <div className="text-center mt-16">
      {region && (
        <p className="text-lg mb-4">
          <Link
            to={`/region/${region.urlName}/`}
            className="text-blue-600 hover:underline"
          >
            {region.name}
          </Link>
        </p>
      )}

      {place && region && (
        <p className="font-bold text-2xl underline mb-6">
          <Link
            to={`/region/${region.urlName}/place/${place.urlName}/`}
            className="text-blue-600 hover:underline"
          >
            {place.name}
          </Link>
        </p>
      )}

      <div className="border-black border p-6 rounded-lg shadow-lg">
        {info &&
          info.map((x, index) => (
            <span key={index} className="inline-block m-3">
              <Link
                className={style(x.name)}
                to={x.url}
                onClick={() => onMenuItemClick && onMenuItemClick(x.url)}
              >
                {x.name}
              </Link>
            </span>
          ))}
      </div>
    </div>
  );
};

export default EditPlaceBar;
