import React from 'react';
import { Link } from 'react-router-dom';
import DisplaySuggestedAdds from './DisplaySuggestedAdds';
import HideRemoveListing from './HideRemoveListing';
import ReactMarkdown from 'react-markdown';
const moment = require('moment-timezone');



function ListingBox({ listing, editing, editor,closeSuggestions,preview,place }) {
  function formatISOInTimezone(isoString, timezone) {
    // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
    return moment(isoString).tz(timezone).format('MMM DD, h:mm A');
}
function formatISOInTimezoneTime(isoString, timezone) {
  // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
  return moment(isoString).tz(timezone).format('h:mm A');
}

function formatISOInLocal(isoString) {
  // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
  return moment(isoString).format('MMM DD, h:mm A');
}

const formatDateRegionString = (listing) => {
return <div className="text-xl font-medium font-unde">
  {listing.parsedDates ?
    listing.parsedDates.map( (date,index) => {
      return <div key={index}>
         <span className="text-lg font-bold text-gray-800">
    {formatISOInTimezone(date.startDate, listing.region.timezone)}
  </span>
  {date.endDate && <>
    <span className="text-lg font-semibold text-gray-600">
    {' → '}
  </span>
  <span className="text-lg font-bold text-gray-800">
    {formatISOInTimezone(date.endDate, listing.region.timezone)}
  </span>
  </>}

</div>
}) : <div>
  <span className="text-lg font-bold text-gray-800">
    {formatISOInTimezone(listing.startDate, listing.region.timezone)}
  </span>
  {listing.endDate && <>
    <span className="text-lg font-semibold text-gray-600">
    {' → '}
  </span>
  <span className="text-lg font-bold text-gray-800">
    {formatISOInTimezone(listing.endDate, listing.region.timezone)}
  </span>
  </>}
</div>
}
</div>}
  
  return (
    <div key={listing.uuid} className="p-4 border border-black rounded shadow mb-4 text-center">
      <div className='flex justify-end'>
     {!preview &&(editing ? <Link to={`/listing/${listing.uuid}`} className=' bg-blue-500 hover:bg-blue-900 hover:text-white border rounded-full border-black p-1 z-20'>Close Editor</Link> : <Link to={`/listing/${listing.uuid}/edit`} className='justify-right bg-yellow-500 hover:bg-yellow-900 hover:text-white border rounded-full border-black p-1 z-20'>Edit Listing</Link>)}

      </div>

<div className='mt-4'>
  {preview ? <div  className="text-3xl font-bold hover:underline">

{listing.name ? listing.name : 'No Listing Title'}
</div>  : <Link to={`/listing/${listing.uuid}`} className="text-3xl font-bold hover:underline">

{listing.name ? listing.name : 'No Listing Title'}
</Link> }

</div>

      <p> 
 {!preview &&  listing.place &&    <span className='inline-block mt-2' >
        <Link to={`/region/${listing.region.urlName}/place/${listing.place.urlName}`} className="mr-1 rounded-lg border border-1 border-black p-1 hover:text-white hover:bg-black ">
          {listing.place.name}
        </Link>
        </span>}
        {!preview && !listing.place && listing.location && (
          <span className='inline-block mt-2' >
        <span  className="mr-1 rounded-lg border border-dashed border-1 border-black p-1 hover:text-white hover:bg-black ">

{listing.location.name}
        </span>
        </span>
        )}
        {preview && place && (
          <span className='inline-block mt-2' >
        <Link target="_blank" to={`/region/${listing.region.urlName}/place/${place.urlName}`} className="mr-1 rounded-lg border border-1 border-black p-1 hover:text-white hover:bg-black ">
          {place.name}
        </Link>
        </span>
        )}
            {preview && !place && listing.nameLocation && (
          <span className='inline-block mt-2' >
        <Link  className="mr-1 rounded-lg border border-dashed border-1 border-black p-1 hover:text-white hover:bg-black ">
          {listing.nameLocation}
        </Link>
        </span>
        )}
      {!preview && listing.organizers && listing.organizers.length > 0 && (
        <>
          {listing.organizers.map((op) => (

<span className='inline-block mt-2' key={op.organizer.id}>
              <Link to={`/region/${listing.region.urlName}/organizer/${op.organizer.urlName}`} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
                {op.organizer.name}
              </Link>
              </span>
          ))}
        </>
      )}

{
        preview && listing.organizersData && listing.organizers &&
        (
          <>
          {listing.organizersData.map((op,index) => (

<span className='inline-block mt-2' key={index}>
              <Link target="_blank" to={`/region/${listing.region.urlName}/organizer/${op.urlName}`} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
                {op.name}
              </Link>
              </span>
          ))}
          </>
        )
          
        
      }


            {listing.type &&       <span className='inline-block mt-2' >
<span className="mr-1 border border-1 border-black p-1 hover:text-white hover:bg-black ">{listing.type}</span></span>}

            </p>
            <div className="text-xl">
            {formatDateRegionString(listing)}
</div>
      {listing.url && (
        <p>
          <strong>URL: </strong>
          <a
            href={listing.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 break-all"
          >
            {listing.url}
          </a>
        </p>
      )}
<p>
  { !preview &&
 <p>
  
 <span className='mx-1'>
   <strong>Zeitgeists Identifier:</strong> {listing.uuid}
 </span>
</p>
  }
 
      {listing.room && (
        <span className='mx-1'>
          <strong>Room:</strong> {listing.room}
        </span>
      )}
      
                  {listing.alternateName && (
        <span className='mx-1'>
          <strong>Subtitle:</strong> {listing.alternateName}
        </span>
      )}
            {listing.note && (
        <span className='mx-1'>
          <strong>Note:</strong> {listing.note}
        </span>
      )}
     <div>
    {listing.eventStatus  && listing.eventStatus !== "https://schema.org/EventScheduled" && (
      <p>
        <strong>Event Status:</strong> {listing.eventStatus.split('/').pop()}
      </p>
    )}
    {listing.eventAttendanceMode && listing.eventAttendanceMode !== "https://schema.org/OfflineEventAttendanceMode" && (
      <p>
        <strong>Attendance Mode:</strong> {listing.eventAttendanceMode.split('/').pop()}
      </p>
    )}
    </div>
     {listing.isAccessibleForFree  && listing.isAccessibleForFree =='yes' &&(
        <span className='mx-1 underline'>{listing.isAccessibleForFree && 'Is Accessible For Free'}</span>
      )}
      {listing.doorTime && (
        <span className='mx-1'>
          <strong>Door Time:</strong> {formatISOInTimezoneTime(listing.doorTime,listing.region.timezone)}
        </span>
      )}
</p>

     {false && listing.parsedDates && listing.parsedDates.length == 1 && 
     <p>
       <strong>Date:</strong> {listing.parsedDates[0].date} {listing.parsedDates[0].time && 'at'} {listing.parsedDates[0].time} {listing.parsedDates[0].timezone && `(${listing.region.timezone})`}
       </p>}
      {listing.status && listing.status!== 'live' &&(
        <p>
          <strong>Status:</strong> {listing.status}
        </p>
      )}
      {listing.image && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <p>
            <strong>Image:</strong><br />
            <img src={listing.image} alt="Listing" style={{ width: 200, height: 'auto' }} />
          </p>
        </div>
      )}
      
      {listing.alt && listing.image && (
                <p>
                <strong>Alt Text:</strong> {listing.alt}
              </p>
      )}



{listing.description && 
  <div>
      <strong>Description:</strong><ReactMarkdown>{listing.description}</ReactMarkdown>
      </div>
  }
              {listing.language && (
        <p>
          <strong>Language:</strong> {listing.language}
        </p>
      )}
                    {listing.keywords && (
        <p>
          <strong>Keywords:</strong> {listing.keywords}
        </p>
      )}
                          {listing.sponsor && (
        <p>
          <strong>Sponsor:</strong> {listing.sponsor}
        </p>
      )}
{listing.sameAs && listing.sameAs.length > 0 && <p> {listing.sameAs.map( (sameAs,index) => {
  if(sameAs!==''&&sameAs.url&&sameAs.url!=='') {
    return <span key={index} className='ml-1 mr-1'>
      {index===0 && <strong>Same As: </strong>}
    <a href={sameAs.url} target='_blank' className='text-blue-500 underline'>{sameAs.url}</a> 
  </span>
  }
  
})}</p>}

{preview &&  listing.seriesData  &&  listing.seriesData.length > 0 && <p> {listing.seriesData.map( (series,index) => {
  return <>
  {index===0 && <strong>Series: </strong>}
  <Link key={index} className='ml-1 mr-1 text-blue-500 underline' to={`/series/${series.uuid}`} target='_blank' >
    {series.name}
</Link>
  </>
})} </p>}

{ !preview && listing.series && listing.series.length > 0 && <p> {listing.series.map((series, index) => (
  <>
    {index === 0 && <strong>Series: </strong>}
    <Link key={index} className='ml-1 mr-1 text-blue-500 underline' to={`/series/${series.series.uuid}`}  >
    {series.series.type==='multiday' ? <span className='text-black underline font-bold'>Recurring Event</span>:series.series.name}
    </Link>{index < listing.series.length - 1 && ','}
  </>
))} </p>
}




      {listing.worksPresented && listing.worksPresented.length > 0 && (
  <div>
    
    {listing&&listing.type==='ScreeningEvent' && listing.worksPresented.length < 6 && 
    <div  > 
        <div>

    </div>
    <div>
      { listing.worksPresented.length > 0  && (
        <strong>Work Presented ({listing.worksPresented.length}): </strong>
      )}
    </div>
    <div className='flex flex-wrap justify-center  gap-4 mx-auto'>
  {listing.worksPresented.length < 6 && listing.worksPresented.map((work) => (
    <div key={work.uuid} className="bg-white overflow-hidden mt-2 pt-2 border border-black w-full sm:w-auto">
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">Title: {work.name}</h3>
        {work.year && (
          <p className="text-sm mb-1"><strong>Release Year:</strong> {work.year}</p>
        )}
        {work.duration && (
          <p className="text-sm mb-1"><strong>Duration:</strong> {work.duration.length > 400 ? 'Problem with duration' : work.duration} Mins</p>
        )}
        {work.director && (
          <p className="text-sm mb-1"><strong>Director:</strong> {work.director.length > 500 ? 'Problem with director' : work.director}</p>
        )}
        {work.videoFormat && (
          <p className="text-sm mb-1"><strong>Video Format:</strong> {work.videoFormat}</p>
        )}
        {work.TMDB && (
          <p className="text-sm mb-1"><a href={`https://letterboxd.com/tmdb/${work.TMDB}`} target='_blank' className='text-blue-500 underline'>Letterboxd Link</a></p>
        )}
      </div>
    </div>
  ))}  

  

</div>
    
    
    </div>}
   
      


  </div>
)}
                      {listing && listing.edits && (
        <div className=" mt-2 pt-2">
          <DisplaySuggestedAdds listing={listing} data={listing.edits} closeSuggestions={editing || closeSuggestions} />
        </div>
      )}
              {listing && listing.lastEdit.lastDate && (
        <p className=" mt-2 pt-2">
          Last Updated: {formatISOInLocal(listing.lastEdit.lastDate)} by{' '}
          {  listing.lastEdit.lastUsername ?        <Link to={`/user/${listing.lastEdit.lastUsername}`} className="text-blue-600">
            @{listing.lastEdit.lastUsername}
          </Link> : listing.lastEdit.lastIntegrationName &&listing.lastEdit.lastIntegrationName}

        </p>
      )}
 {editor && <HideRemoveListing listing={listing} />}
    
      {/* Add more fields as needed */}
    </div>
  );
}

export default ListingBox;
