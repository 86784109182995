import React from "react";


function LeftPanel({left }) {
    return (
        <div className={`ml-10 mr-10 mb-4 text-black `}>
            <div className="text-4xl underline ">{left.label}</div>
            <div className="text-2xl mt-4 mr-4 mb-4">{left.text}</div>
        </div>
    );
}

export default LeftPanel;