import React, { useState, useEffect } from 'react';

function DisplayLogs({ place, placeIntegration, limit}) {
  const [PI, setPI] = useState(false);
  const [displayError, setDisplayError] = useState(null);
  const [logs, setLogs] = useState([]);
  const [seens, setSeens] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [visibleLogs, setVisibleLogs] = useState(limit); // State to control the number of logs displayed

  useEffect(() => {
    if (logs) {
      setSeens(logs.filter(log => !log.isNew).map(log => log.uuid));
      setFiltered(logs);
    }
  }, [logs]);

  const handleFilter = (pi) => {
    setPI(pi);
    if (!pi) {
      setFiltered(logs);
    } else {
      setFiltered(logs.filter(log => log.placeIntegrationId === pi));
    }
  };

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const markSeen = async (log) => {
    if ( !log.isNew) {
      return;
    }
    try {
      setSeens([...seens, log.uuid]);

      const response = await fetch(`/api/region/${place.region.urlName}/place/${place.urlName}/edit/seen/${log.uuid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        setSeens(seens.filter(uuid => uuid !== log.uuid));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchLogs = async () => {
    try {
      const response = await fetch(`/api/region/${place.region.urlName}/place/${place.urlName}/edit/logs${placeIntegration ? `?placeIntegrationId=${placeIntegration}` : ''}`
      ,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setLogs(data.runs);
      }
      else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (place) {
      console.log('ping')
      fetchLogs();
    }
  }, [place, placeIntegration]);

  const loadMoreLogs = () => {
    setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + limit); // Increase the number of visible logs by the limit
  };
  return (
    <div className="p-4">
      {!placeIntegration && (
        <div className="flex flex-wrap justify-center gap-4 m-2 mb-4">
          Filter Place Integrations: {place && place.placeIntegrations.map(pi => (
            <button key={pi.id} onClick={() => handleFilter(pi.id)} className="underline">
              {pi.name}
            </button>
          ))}
          {PI && <button onClick={() => handleFilter(null)} className="underline">Remove Filters</button>}
        </div>
      )}

      <table className="min-w-full bg-white shadow rounded-lg overflow-hidden">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Integration Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Updated</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Errors</th>
          </tr>
        </thead>
        <tbody>
          {filtered.slice(0, visibleLogs).map(log => (
            <React.Fragment key={log.uuid}>
              <tr
                className={`cursor-pointer ${log.errorLogs.length > 0 ? 'bg-red-' : 'bg-green-'}${!seens.includes(log.uuid) ? '600' : '100'} hover:bg-gray-50 transition-colors duration-150`}
                onClick={() => markSeen(log)}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  {log.errorLogs.length > 0 && !seens.includes(log.uuid) && '⚠️'} {new Date(log.createdAt).toLocaleString('en-US', options)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="underline text-lg">{log.placeIntegration.name}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <strong>{log.added}</strong>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <strong>{log.updated}</strong>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {log.errorLogs && log.errorLogs.length > 0 && (
                    <button
                      className="underline hover:text-black"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDisplayError(displayError === log.uuid ? null : log.uuid);
                      }}
                    >
                      {displayError === log.uuid ? 'Hide' : 'Show'} Error Log
                    </button>
                  )}
                </td>
              </tr>
              {displayError === log.uuid && (
                <tr>
                  <td colSpan="5" className="bg-gray-100 p-4">
                    <div className="bg-gray-800 text-white p-4 rounded-lg">
                      {log.errorLogs.map((error, i) => (
                        <div key={error.uuid} className="mb-2">
                          <strong>Error {i + 1}:</strong> {error.message}
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
              
            </React.Fragment>
            
          ))}
          {visibleLogs < filtered.length && (
        <div className="">
          <button 
            onClick={loadMoreLogs}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 m-2"
          >
            Load More
          </button>
        </div>
      )}
        </tbody>
      </table>
    </div>
  );
}

export default DisplayLogs;
