import React from "react";
import { Link } from "react-router-dom";
function SuggestionBlobs({suggestions, urlName, places, organizers}) {
    const formatDateTime = (dateTime) => {
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
        return new Date(dateTime).toLocaleString('en-US', options);
      };
    return (<>


{suggestions && 
        suggestions.map(suggestion => { if (suggestion.listingId) {return (
            <div key={suggestion._id} className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-yellow-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            

       <p>
<Link className="text-blue-500 hover:underline" to={`/listing/${suggestion.listingId}?suggestion=${suggestion._id}`} > View Listing Edit Suggestion</Link> 
</p> 

Status: <span className={`${suggestion.status === 'unapproved' ? 'bg-red-300' : suggestion.status === 'approved' ? 'bg-green-300' : ''} capitalize`}>
  {suggestion.status}
</span>
           {
            places && places.filter(place=>place._id ===suggestion.placeId).map(place=> {return <p key={place._id} ><Link className="text-blue-500 hover:underline" to={`/place/${place.urlName}`}>
            {place.name}
            </Link>
</p>}) 
           } 
      
      {suggestion.createdAt && (
        <div>
          Submited At: {formatDateTime(suggestion.createdAt)} by{' '}
          <Link to={`/user/${suggestion.username}`} className="text-blue-600">
            @{suggestion.username}
          </Link>
        </div>
      )}
  
      {suggestion.comments && <p>Comment: <span className="bg-green-200">{suggestion.comments}</span></p>}

        </div>
         )} else { return ( 
            <div key={suggestion._id} className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-purple-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            
           {!places && <p>
<Link className="text-blue-500 hover:underline" to={`/place/${urlName}/edit/suggestions?id=${suggestion._id}`} > View Listing Add Suggestion
</Link> 
</p>}
{
            places && places.filter(place=>place._id ===suggestion.placeId).map(place=> {return <p key={place._id} >
              <Link className="text-blue-500 hover:underline" to={`/place/${place.urlName}/edit/suggestions?id=${suggestion._id}`} > View Listing Add Suggestion
</Link>
<br/>
              <Link className="text-blue-500 hover:underline"  to={`/place/${place.urlName}`}>
            {place.name}
            </Link>

</p>}) 
           } 
     
     Status: <span className={`${suggestion.status === 'unapproved' ? 'bg-red-300' : suggestion.status === 'approved' ? 'bg-green-300' : ''} capitalize`}>
       {suggestion.status}
     </span>
     
           
           {suggestion.createdAt && (
             <div>
               Submited At: {formatDateTime(suggestion.createdAt)} by{' '}
               <Link to={`/user/${suggestion.username}`} className="text-blue-600">
                 @{suggestion.username}
               </Link>
             </div>
           )}
       
           {suggestion.comments && <p>Comment: <span className="bg-green-200">{suggestion.comments}</span></p>}
     
             </div>
            
         )} } )
    }

    </>)
}
export default SuggestionBlobs;