import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import ListingBox from '../../components/listing/ListingBox';
import PlaceInfo from '../../components/place/PlaceInfo';
import OrganizerInfo from '../../components/organizer/OrganizerInfo';

import SuggestionList from '../../components/listing/SuggestionList';
import LoadingScreen from '../../components/utilities/LoadingScreen'; // Import the LoadingScreen component
import LocationInfo from '../../components/location/LocationInfo';

function Listing() {
  const location = useLocation();
  const navigate = useNavigate();
  const { listingId } = useParams();

  const [openSuggestion, setOpenSuggestion] = useState(null);
  const [listing, setListing] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const [editor, setEditor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const suggestionParam = searchParams.get('suggestion');
    setOpenSuggestion(suggestionParam);
  }, [location]);

  useEffect(() => {
    fetchListing();
  }, [listingId]);

  useEffect(() => {
    document.title = `Listing - Zeitgeists`;
  }, []);

  const fetchListing = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/listing/${listingId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setListing(data.listing);
        setSuggestions(data.listing.edits.filter((edit) => edit.type === 'suggest'));
        setEditor(data.editor);
      }  else {
        setError('Failed to load listing');
      }
    } catch (error) {
      setError('An error occurred while fetching the listing');
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div className="m-4 mt-20 md:mt-14 text-red-500">{error}</div>;
  }
  const handleCloseSuggestion = () => {
    setOpenSuggestion(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('suggestion');
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <LoadingScreen isLoading={loading} message="Loading listing details...">
      <div className="m-4 mt-20 md:mt-14">
        {listing && (
          <ListingBox listing={listing} editor={editor} closeSuggestions={openSuggestion} />
        )}

        {openSuggestion && suggestions && (
          <>
          <button 
  onClick={handleCloseSuggestion} 
  type='button'
  className="text-blue-600 hover:underline text"
>
  Close Suggestions
</button>
<SuggestionList
            listing={listing}
            suggestions={suggestions}
            editor={editor}
            fetchListing={fetchListing}
          />
          </>

        )}

        {listing && (
          <div>
            <div className="flex justify-center flex-wrap">
              {listing.organizers &&
                listing.organizers.map((lo) => (
                  <div className="w-full sm:w-1/2 md:w-1/3" key={lo.organizer.id}>
                    <OrganizerInfo
                      organizer={lo.organizer}
                      places={[listing.place]}
                      region={listing.region}
                      listing={true}
                    />
                  </div>
                ))}
            </div>
            <PlaceInfo
              place={listing.place}
              organizers={listing.organizers}
              region={listing.region}
              listing={true}
            />
                 <LocationInfo
              listing={true}
              location={listing.location}
              region={listing.region}
            />
 
            {listing.region && (
              <div className="text-right">
                In{' '}
                <Link
                  className="text-blue-500 hover:underline"
                  to={`/region/${listing.region.urlName}`}
                >
                  {listing.region.name}
                </Link>
                .
              </div>
            )}
          </div>
        )}
      </div>
    </LoadingScreen>
  );
}

export default Listing;
