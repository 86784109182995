function ConfirmModal({ isOpen, onClose, onConfirm, title, message, verifyText }) {
  if (!isOpen) return null;
  const handleConfirm = () => {
    if (verifyText) {
      const input = document.querySelector('input');
      if (input.value.toLowerCase() === verifyText) {
        onConfirm();
      } 
    } else {
      onConfirm();
    }
  }
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-lg">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <p className="mb-4">{message}</p>
        
        {verifyText && (
          <>
          <p>Type <span className="font-bold">{verifyText}</span> to verify delete.</p>
          <input
            type="text"
            className="border border-gray-300 rounded-md p-2 mb-4"
            placeholder="Type here to verify"
          />
          </>
        )}
        <div className="flex space-x-4">
          <button
            className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded-md"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
export default ConfirmModal;