import { useParams } from "react-router-dom";
import SeriesDetail from "./SeriesDetail";
import { useEffect } from "react";

function SeriesReload() {
    const { seriesUUID } = useParams();

    useEffect(() => {
        console.log(seriesUUID)
    },[seriesUUID])
    return (
        <SeriesDetail key={seriesUUID} seriesUUID={seriesUUID} />
        )
    
}
export default SeriesReload;