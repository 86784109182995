import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import EditOrganizerBar from '../../components/utilities/EditOrganizerBar';
import ManageLocations from '../../components/location/ManageLocations';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditOrganizerLocations() {
  const navigate = useNavigate();
  const { regionName, organizerName } = useParams();

  const [organizerData, setOrganizerData] = useState({
    organizer: null,
    places: null,
    region: null,
    locations: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      fetchOrganizerDetails();
    
  }, []);
  useEffect(() => {
    if (organizerData.organizer) {
      document.title = `Edit ${organizerData.organizer.name} - Zeitgeists`;
    } else {
      document.title = `Organizer - Zeitgeists`;
    }
  }, [organizerData.organizer]);

  const fetchOrganizerDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit/locations`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({
          organizer: data.organizer,
          region: data.region,
          locations: data.locations,
        });
        setLoading(false);
      } else if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/organizer/${organizerName}/locations`);
      }  if (response.status === 403) {
        navigate(`/region/${regionName}/organizer/${organizerName}`);
      } 
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };
  const updateLocaitons = (locationNew,row,action) => {
    setOrganizerData({...organizerData,series:locationNew})
    const noListings = row;
    delete noListings.listings;
    sendDifferencesToServer({selectLocation:noListings,action},action)
  };
  
  // Function to send differences to the server implement
  const sendDifferencesToServer = async (selectedLocation,action) => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit/locations/`, {
        method: (action == "delete" ? "DELETE" :'PATCH'),
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedLocation),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({...organizerData,locations:data.locations})
      } else {
        console.error("Failed to update location");
      }
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };


  return (
    <LoadingScreen isLoading={loading}  message="Fetching organizer locations...">
    <div className='p-4'>
{organizerData && organizerData.region && organizerData.organizer && <EditOrganizerBar region={organizerData.region} organizer={organizerData.organizer} active={"/locations"} />}


<ManageLocations locations={organizerData.locations} onChange={updateLocaitons} />
<div className="p-4">


</div>
    </div>
    </LoadingScreen>
  );
}

export default EditOrganizerLocations;
