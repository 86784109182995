import React, {useEffect} from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy - Zeitgeists';
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="max-w-3xl mx-auto p-6 mt-20">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
        <p>
          Zeitgeists.org ("we," "our," "us") values your privacy. This Privacy Policy explains how we collect, use, and share your information in compliance with California privacy laws, including the California Consumer Privacy Act (CCPA).
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
        <p>
          <strong>Personal Data:</strong> Includes identifiers like your name, email, IP address, and other details you provide.
        </p>
        <p>
          <strong>Usage Data:</strong> Includes your browsing activity on our site, such as pages visited, time spent, and interactions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
        <p>
          We may use your information to:
        </p>
        <ul className="list-disc list-inside">
          <li>Provide, operate, and improve our website.</li>
          <li>Communicate with you and respond to your inquiries.</li>
          <li>Analyze and monitor usage trends.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">4. Your Rights Under CCPA</h2>
        <p>As a California resident, you have the following rights:</p>
        <ul className="list-disc list-inside">
          <li><strong>Right to Know:</strong> Request details about the personal information we collect, use, and share.</li>
          <li><strong>Right to Delete:</strong> Request the deletion of your personal data, subject to certain exceptions.</li>
          <li><strong>Right to Opt-Out:</strong> Opt-out of the sale of your personal data (though we do not sell personal information).</li>
          <li><strong>Right to Non-Discrimination:</strong> You will not be discriminated against for exercising your privacy rights.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">5. How to Exercise Your Rights</h2>
        <p>
          To exercise your rights, contact us at <a href="mailto:contact@zeitgeists.org" className="text-blue-600 underline">contact@zeitgeists.org</a>. We may need to verify your identity before processing your request.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">6. Sharing Your Information</h2>
        <p>
          We do not sell your personal information. However, we may share your data with third parties for business purposes, such as improving our services or complying with legal obligations.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">7. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies to enhance your experience on our site. You can manage your cookie preferences through your browser settings.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">8. Data Security</h2>
        <p>
          We implement security measures to protect your data but cannot guarantee absolute security.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">9. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy to reflect changes in our practices. We will notify you of any significant updates via email or a prominent notice on our site.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">10. Contact Information</h2>
        <p>
          For questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@zeitgeists.org" className="text-blue-600 underline">contact@zeitgeists.org</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
