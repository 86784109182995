import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import EditPlaceBar from '../../components/utilities/EditPlaceBar';
import ManageSeries from '../../components/series/ManageSeries';
import AddSeries from '../../components/series/AddSeries';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditPlaceSeries() {
  const navigate = useNavigate();
    const [place,setPlace] = useState(null);
    const [series,setSeries] = useState(null);
    const [region,setRegion] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPlaceDetails();
     
    }, []);
    

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Series - Zeitgeists`;
      } else {
        document.title = `Edit Series - Zeitgeists`;
      }
    }, [place]);
    
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

  // Fetch the region details based on regionName and render them
  // You can also use the additionalData prop as needed
  const updateSeries = (seriesNew,row,action) => {
    setSeries(seriesNew); //temp before verifying
    sendDifferencesToServer({selectSeries:row,action},action)
  };
  
  // Function to send differences to the server (hypothetical)
  const sendDifferencesToServer = async (selectSeries,action) => {
    try {
      const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/series/`, {
        method: (action == "delete" ? "DELETE" :'PATCH'),
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectSeries),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setSeries(data.series)
      } else {
        console.error("Failed to update series");
      }
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };
  
      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/series`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                setRegion(data.region);
                setPlace(data.place);
                setSeries(data.series);
              }
              else {
                console.error(response);
              }
              setLoading(false);
              if (response.status === 401) {
                navigate(`/login?redirect=/region/${regionName}/place/${placeName}/edit/series`);
              } else if (response.status === 403) {
                navigate(`/region/${regionName}/place/${placeName}/edit`);
              }


        } catch (error) {
            console.error('Network or other error:', error);


        }
      }
//display place people

   return (
    <LoadingScreen isLoading={loading}  message="Fetching place series...">

    <div className="p-4">
      { place && <EditPlaceBar type="place" place={place} active={'Series'} region={region} />}

      <ManageSeries series={series} onChange={updateSeries} />

      <div className="p-4">

 <AddSeries placeId={place&&place.id} region={region} onChange={fetchPlaceDetails} />
    
</div>
    </div>
</LoadingScreen>

    
  );
}

export default EditPlaceSeries;
