import React, { useEffect, useState } from 'react';
import ProfileImage from '../../components/user/ProfileImage';
import EditUserProfile from '../../components/user/EditProfile';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/utilities/LoadingScreen';
import { Link } from 'react-router-dom';

function Profile() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [places, setPlaces] = useState(null);
  const [organizers, setOrganizers] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [apiMessage, setApiMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
   
    fetchProfile();

  
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await fetch('/api/user', {
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });
      if (response.ok) {
        const data = await response.json();
        setProfile(data.user);
        setPlaces(data.places);
        setOrganizers(data.organizers);

      }      else if (response.status===401) {
      navigate('/login')    
  } else {
          //redirect no login
      //    setIsAuthenticated(false)
      }
      setLoading(false);

    } catch (error) {
      //console.log('Error fetching listings', error);

    }
  };

  useEffect(() => {
    if(profile) {
      document.title = `@${profile.username} - Zeitgeists`;
    } else {
      document.title = `Profile - Zeitgeists`;
    }
  }, [profile]);
  
  const handleNewApiKey = async () => {
    try {
      const response = await fetch('/api/generate-api-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });
      if (response.ok) {
        const data = await response.json();    
        setApiMessage(data.apiKey);

      }      else if (response.status===401) {
        navigate('/login')
      }else {
          //redirect no login
      //    setIsAuthenticated(false)
      
      }
    } catch (error) {
      console.error( error);
    }
  }
  const handleRevokeApiKey = async () => {
    if(!window.confirm("Confirm Revoke API Key")) {
      return
    }
    try {
      const response = await fetch('/api/revoke-api-my-key', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });
      if (response.ok) {
        setApiMessage("API key removed");
      }      else if (response.status===401) {
navigate('/login')
      }else {
          //redirect no login
      //    setIsAuthenticated(false)
      
      }
    } catch (error) {
      console.error( error);
    }
  }
  const handleChange = () => {
    fetchProfile();
    setEditProfile(false);
  }
  const handleEditProfile = () => {
    if(editProfile) {
      setEditProfile(false);
    }
    else {
      setEditProfile(true);
    }
    
  }
  return (
    <LoadingScreen isLoading={loading} message="Loading profile...">
    <div className='p-4 text-center mt-36'>
        
              {(profile && !editProfile) ? 
              <div className='p-3 text-xl'>
              <div  className='flex items-center text-center justify-center'>
                  {profile.image && <ProfileImage url={profile.image} alt={profile.alt} />}
                </div>              
                <p>{profile.name} </p> 
              <p>@{profile.username}</p>
              {profile.numberOfEdits > 0 && <p>{profile.numberOfEdits} Edits</p> }
              <p> 
                { places && places.map(place=>(  
                          <span className='inline-block mt-2' key={place.id}>
                  <Link key={place.id} to={`/place/${place.urlName}`} className="mr-1 rounded-lg  border border-1 border-black p-1 hover:text-white hover:bg-black ">
                      {place.name}
                  </Link>
                  </span>
                ))}
                {organizers  && organizers.map((organizer) => (
                          <span className='inline-block mt-2' key={organizer.id}>
                        <Link key={organizer.id} to={`/organizer/${organizer.urlName}`} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
                          {organizer.name}
                        </Link>
                        </span>
                ))}
            </p>
              {profile.region && <p>Gatekeeper of <Link to={`/region/${profile.region.urlName}`}>{profile.region.name}</Link></p>}
              <p>{profile.description}</p>
              
         

              <button className='bg-black text-white rounded-full p-3 mt-5 hover:bg-white hover:text-black border-2 border-black' onClick={() => handleEditProfile()}>Edit</button>

    </div> : null}
                {editProfile ? <EditUserProfile profile={profile} onChange={handleChange} /> : null}
                { profile && <div>

                  <p className='pt-10 font-bold underline'>API Key</p>

               {profile.apiKey ?<button className='bg-black text-white rounded-full p-2 mt-5 hover:bg-red-500 ' onClick={handleRevokeApiKey}>Revoke Issued API Key</button> : <button className='bg-black text-white rounded-full p-2 mt-5 hover:bg-green-500 ' onClick={handleNewApiKey}>New API Key</button>} <p></p>
                <p className='p-4 text-lg font-mono '>{apiMessage}</p>
                {apiMessage &&  apiMessage !=="API key removed" && <p>Please make sure to save your generated API key in a secure place. If you refresh the page without saving it somewhere, you will need to generate a new API key. </p>}
                </div> }
              </div>
</LoadingScreen>
  );
}

export default Profile;



