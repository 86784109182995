import React, { useState,useEffect } from 'react';

import {Link} from 'react-router-dom';
import DisplaySuggestedAdd from '../../components/listing/DisplaySuggestedAdd';

function DisplaySuggestedAdds({listing, data, closeSuggestions}) {
  useEffect(() => {
  }, [data]);
  const [displayBig, setDisplayBig] = useState(!closeSuggestions);
 useEffect(() => {
    setDisplayBig(!closeSuggestions);
  }, [closeSuggestions]);
  const handleDisplay = () => {
    setDisplayBig(!displayBig);
  }
  return (
    data.filter(suggestion=>suggestion.status!=='rejected').length >0 &&
    <div className="mb-4">
              {data && data.filter(suggestion=>!suggestion.listingId).length > 0 && <span>{data.filter(suggestion=>suggestion.type === 'suggest').length} Suggested Listing{data.filter(suggestion=>suggestion.type === 'suggest').length> 1 && 's'} <Link className="text-blue-500 hover:underline" to={'/listings?suggestion=all'}>View All</Link> </span>} 
              
              {data && data.filter(suggestion=>suggestion.type === 'suggest').length > 0  && <div className="border border-black m-1 p-2">
              <p>Suggested Edits <span className="bg-yellow-400 pl-1 pr-1">{data.filter(suggestion=>suggestion.type === 'suggest'&&suggestion.status ==='unapproved').length}</span>  <span className="bg-green-400 pl-1 pr-1">{data.filter(suggestion=>suggestion.type === 'suggest'&&suggestion.status === 'approved').length}</span>  <span className="bg-red-400 pl-1 pr-1">{data.filter(suggestion=>suggestion.type === 'suggest'&&suggestion.status === 'rejected').length}</span>{data.filter(suggestion=>suggestion.type === 'suggest'&&suggestion.status ==='unapproved').length > 0  && <Link className="text-blue-500 hover:underline" to={`/listing/${listing.uuid}?suggestion=all`} > View All</Link>}</p>  
             
              <p><button onClick={handleDisplay} className="text-blue-500 hover:underline">{displayBig ? 'Close': 'Preview' }</button></p>
              {displayBig && <div className="flex flex-wrap justify-center gap-2">
                {data
  .filter(suggestion => suggestion.type === 'suggest')
  .sort((a, b) => {
    const statusOrder = {
      'unapproved': 1,
      'approved': 2,
      'rejected': 3
    };

    // First, sort by status
    const statusComparison = (statusOrder[a.status] || 4) - (statusOrder[b.status] || 4);

    if (statusComparison !== 0) {
      return statusComparison;
    }

    // If statuses are the same, sort by date (most recent first)
    return new Date(b.createdAt) - new Date(a.createdAt);
  })
  .map(suggestion => (
    <div key={suggestion.id}>
      <DisplaySuggestedAdd suggestion={suggestion} open={false} listing={listing} />
    </div>
  ))}

              </div> }
              </div>
            }
    </div>
  );
}

export default DisplaySuggestedAdds;
