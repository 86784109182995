import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import ManageSeries from '../../components/series/ManageSeries';
import EditOrganizerBar from '../../components/utilities/EditOrganizerBar';
import AddSeries from '../../components/series/AddSeries';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditOrganizerSeries() {
  const navigate = useNavigate();
  const { regionName, organizerName } = useParams();
  const [loading, setLoading] = useState(true);
  const [organizerData, setOrganizerData] = useState({
    organizer: null,
    places: null,
    region: null,
    count: 0,
    scrapes: null,
    suggestions: [],
    lastEdit: null,
    seriesCount: 0,
    integrationsCount: 0,
    suggestionsCount: 0,
    power: false,
  });


  useEffect(() => {
      fetchOrganizerDetails();
 
  }, []);
  useEffect(() => {
    if (organizerData.organizer) {
      document.title = `Edit ${organizerData.organizer.name} - Zeitgeists`;
    } else {
      document.title = `Organizer - Zeitgeists`;
    }
  }, [organizerData.organizer]);

  const fetchOrganizerDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit/series`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({
          organizer: data.organizer,
          
          region: data.region,
          seriesCount: data.seriesCount || 0,
          series: data.series,
          power: data.power,
        });
        setLoading(false);
      } else if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/organizer/${organizerName}/series`);
      }  if (response.status === 403) {
        navigate(`/region/${regionName}/organizer/${organizerName}`);
      } 
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };
  const updateSeries = (seriesNew,row,action) => {
    setOrganizerData({...organizerData,series:seriesNew})
    sendDifferencesToServer({selectSeries:row,action},action)
  };
  
  // Function to send differences to the server (hypothetical)
  const sendDifferencesToServer = async (selectSeries,action) => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit/series/`, {
        method: (action == "delete" ? "DELETE" :'PATCH'),
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectSeries),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({...organizerData,series:data.series})
      } else {
        console.error("Failed to update series");
      }
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };
  return (
    <LoadingScreen isLoading={loading}  message="Fetching organizer series...">

    <div className='p-4'>
{organizerData && organizerData.region && organizerData.organizer && <EditOrganizerBar region={organizerData.region} organizer={organizerData.organizer} active={"/series"} />}


<ManageSeries series={organizerData.series} onChange={updateSeries} />
<div className="p-4">

<AddSeries  organizerId={organizerData&&organizerData.organizer&&organizerData.organizer.id} region={organizerData&&organizerData.region||null} onChange={fetchOrganizerDetails} />

</div>
    </div>
    </LoadingScreen>
  );
}

export default EditOrganizerSeries;
