import React, { useEffect } from 'react';

const TermsOfService = () => {
    useEffect(() => {
        document.title = 'Terms of Service - Zeitgeists';
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="max-w-3xl mx-auto p-6 mt-20">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
        <p>Welcome to Zeitgeists.org. By accessing or using our website, you agree to comply with and be bound by the following Terms of Service. Please read them carefully.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">2. User Responsibilities</h2>
        <p><strong>Account Registration:</strong> You may need to create an account to access certain features. You agree to provide accurate and current information during registration.</p>
        <p><strong>User Conduct:</strong> You agree not to engage in spamming, harassment, or any illegal activities on the platform. Respectful interaction is expected at all times.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">3. Content and Intellectual Property</h2>
        <p><strong>User-Generated Content:</strong> By submitting content to Zeitgeists.org, you grant us a non-exclusive, royalty-free license to use, distribute, and display that content in connection with the site’s operations.</p>
        <p><strong>Intellectual Property Rights:</strong> All content, including text, images, and software, on Zeitgeists.org is the property of Zeitgeists.org or its content suppliers and is protected by intellectual property laws. You may not reproduce or distribute any content from the site without explicit permission.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">4. Data Privacy</h2>
        <p><strong>Data Collection:</strong> We collect data to improve your experience on Zeitgeists.org. Please refer to our Privacy Policy for detailed information on how your data is used.</p>
        <p><strong>User Rights:</strong> You have the right to request access to, correction of, or deletion of your personal data. Contact us to exercise these rights.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">5. Use of the Service</h2>
        <p><strong>Availability:</strong> Zeitgeists.org is provided on an "as is" basis. We do not guarantee uninterrupted access to the service and reserve the right to modify or discontinue any aspect of the service at any time.</p>
        <p><strong>Prohibited Uses:</strong> You may not use the service to engage in activities that are harmful, unlawful, or violate the rights of others.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">6. Termination</h2>
        <p><strong>Account Suspension:</strong> We reserve the right to suspend or terminate your account if you violate these Terms of Service.</p>
        <p><strong>Effect of Termination:</strong> Upon termination, your access to the platform will be revoked, and your content may be deleted.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">7. Liability and Disclaimers</h2>
        <p><strong>Limitation of Liability:</strong> Zeitgeists.org is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform.</p>
        <p><strong>Disclaimers:</strong> We do not warrant the accuracy, reliability, or completeness of any information provided on Zeitgeists.org.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">8. Governing Law</h2>
        <p>These Terms of Service are governed by the laws of the State of California. Any disputes arising from these terms will be resolved in the courts of California.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">9. Changes to the Terms</h2>
        <p>We may update these Terms of Service from time to time. Significant changes will be communicated via email or a prominent notice on the site. Continued use of the site after any such changes constitutes your consent to the new terms.</p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">10. Contact Information</h2>
        <p>If you have any questions or suggestions, please contact us at <a href="mailto:contact@zeitgeists.org" className="text-blue-600 underline">contact@zeitgeists.org</a>.</p>
      </section>
    </div>
  );
};

export default TermsOfService;
