import React from 'react';
import { Link } from 'react-router-dom';

const ListingsIntro = ({count}) => {
  return (

<div className="p-6 bg-white shadow-md rounded border-black border mt-2">
        <h2 className="text-2xl font-semibold mb-4">Cultural Listings on Zeitgeists.org</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Find Events: Access a diverse collection of cultural happenings.</li>
          <li>Event Details: View event dates, locations, and descriptions.</li>
          <li>API Integration: Seamlessly integrate events into your own platform.</li>
          <li>Community Driven: Enhance the listings with your contributions.</li>
        </ul>
        <div className="flex flex-col sm:flex-row justify-center gap-4">
          <Link to="/signup/listings" className="px-4 py-2 bg-blue-200 text-black text-sm font-medium rounded hover:bg-blue-500">Sign Up for full access</Link>
          <Link to="/login/listings" className="px-4 py-2 bg-green-200 text-black text-sm font-medium rounded hover:bg-green-500">Log In to contribute</Link>
          <Link to="/api" className="px-4 py-2 bg-purple-200 text-black text-sm font-medium rounded hover:bg-purple-500">API access</Link>
        </div>
      </div>
       );
    };
    
    export default ListingsIntro;