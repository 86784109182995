import React, { useEffect,useState } from 'react';
import GetListings from '../../components/listing/GetListings';
import { useParams } from 'react-router-dom';
import AddListings from '../../components/listing/AddListings';
import LoadingScreen from '../../components/utilities/LoadingScreen';
function Listings({ setIsAuthenticated, isAuthenticated }) {
  const [regions,setRegions] = useState(false);

  const { regionName } = useParams();
  const [expand,setExpand] = useState(false);
  const [loading, setLoading] = useState(true);
  const onChange = () => {
    console.log('change');
  }


useEffect(() => {
    document.title = `Listings - Zeitgeists`;
    fetchListings();
}, []);
const fetchListings = async () => {
  try {
    const response = await fetch(regionName ? `/api/region/${regionName}/listings/` : `/api/listings/regions`);
    if (response.ok) {
      const data = await response.json();
      setRegions(data.regions);
      setLoading(false);
    }
  } catch (error) {
    console.error(error)
  }
 }
  return (
    <LoadingScreen isLoading={loading} message="Loading listings...">
    <div className="m-5 grid gap-3 grid-cols-5 pt-16">
      <div className="md:col-span-2 col-span-5">
      
          <div className="content flex-grow">
            <AddListings />
          </div>
      </div>
    <div className="md:col-span-3 col-span-5">
        <GetListings isAuthenticated={isAuthenticated} regions={regions && regions.map(region => region.id)} filters={true} onChange={onChange} />
      </div>
    </div>
    </LoadingScreen>
  );
}

export default Listings;
