import React, { useEffect, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import SeriesInfo from '../../components/series/SeriesInfo';

function EditSeries() {
  const { seriesUUID } = useParams(); // Access the "urlName" route parameter

  const navigate = useNavigate();
  const [series, setSeries] = useState(null);
  const [organizers, setOrganizers] = useState(false);
  useEffect(() => {
      fetchSeriesDetails();

      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(series) {
        document.title = `${series.name} - Zeitgeists`;
      } else {
        document.title = `Series - Zeitgeists`;
      }
    }, [series]);
  


      const fetchSeriesDetails = async () => {
        try {
            const response = await fetch(`/api/series/${seriesUUID}`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                window.scrollTo(0, 0);
                const data = await response.json();
                setSeries(data.series); //?
                setOrganizers(data.organizers);
                navigate(`/series/${seriesUUID}/edit`, { replace: true });
              }
             else if(response.status===401) {
                navigate(`/login/series/${seriesUUID}/edit`)
              }
              
        } catch (error) {
          console.error('Network or other error:', error);
        }
      }
//display place people

   return (

    <div className="grid grid-cols-3 gap-4 mt-16">
      <div className="col-span-3 md:col-span-1">
      {series && <SeriesInfo series={series}  />} 
      </div>
  <div className="col-span-3 md:col-span-2">
      {series && (
        <div className="m-4">
        </div>
      )}
</div>

    </div>


    
  );
}

export default EditSeries;
