import { Link } from 'react-router-dom';
const getBackgroundColor = (action) => {
    switch (action) {
      case 'add':
        return 'bg-green-200'; // green for 'add'
      case 'edit':
        return 'bg-yellow-200'; // yellow for 'edit'
      case 'delete':
        return 'bg-red-200'; // red for 'delete'
      default:
        return 'bg-gray-200'; // default color if action is unknown
    }
  };
  
  const DisplayLastEdit = ({ lastEdit }) => {
    const displayUserOrIntegration = lastEdit?.userUsername
    ? `@${lastEdit.userUsername}`
    : lastEdit?.integrationName
    ? `Integrated with: ${lastEdit.integrationName}`
    : 'N/A';
    return (
        <div
        className={`col-span-4 md:col-span-1 sm:col-span-2 ${getBackgroundColor(lastEdit?.action)} text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm`}
                >
          <p className="text-2xl font-semibold text-gray-800 mb-1">
            {lastEdit?.createdAt ? new Date(lastEdit.createdAt).toLocaleString() : 'N/A'}
          </p>
          <p className="text-lg font-medium text-gray-700 mb-2">
            {lastEdit?.userUsername ? (
              <Link to={`/user/${lastEdit.userUsername}`} className="text-blue-600 hover:underline">
                {displayUserOrIntegration}
              </Link>
            ) : (
              displayUserOrIntegration
            )}
          </p>
          <p className="text-base text-gray-600">
            Last Edit{' '}
            {lastEdit?.listingUUID ? (
              <Link to={`/listing/${lastEdit.listingUUID}`} className="text-blue-600 hover:underline">
                View Listing
              </Link>
            ) : (
              'N/A'
            )}
          </p>
          <p className="text-base text-gray-600 mt-2">
            Action: {lastEdit?.action ? lastEdit.action : 'N/A'}
          </p>
        </div>
      );
    };
    
  export default DisplayLastEdit;
  