import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminManageIntegration from './AdminManageIntegration';
import LastRunInfo from './LastRunInfo'; // Import the component for displaying the last run

function AdminPlaceIntegrations({ placeIntegrations, onChange }) {
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [sortedPlaceIntegrations, setSortedPlaceIntegrations] = useState([]);
  const handleQueueRun = async (uuid) => {
    const confirmRun = window.confirm('Are you sure you want to run this integration?');
    if (confirmRun) {

    try {
      const response = await fetch(`/api/admin/integration/${uuid}/queue-run`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        const result = await response.json();
        onChange();
      } else {
        console.error('Error queuing integration run:', response);
      }
    } catch (error) {
      console.error('Error queuing integration run:', error);
    }
  }
  };
  useEffect(() => {
    // Sort placeIntegrations based on the latest run's createdAt date
    const sorted = [...placeIntegrations].sort((a, b) => {
      const lastRunA = a.runs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
      const lastRunB = b.runs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

      if (!lastRunA && !lastRunB) return 0; // No runs for both
      if (!lastRunA) return 1; // No run for A, so B is first
      if (!lastRunB) return -1; // No run for B, so A is first

      return new Date(lastRunB.createdAt) - new Date(lastRunA.createdAt);
    });

    setSortedPlaceIntegrations(sorted);
  }, [placeIntegrations]);

  const handleDelete = (integrationId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this integration?');
    if (confirmDelete) {
      // Handle deletion logic here
      // console.log(`Deleted integration with ID: ${integrationId}`);
    }
  };

  return (
    <div>
      <button
        onClick={() => setShowIntegrations(!showIntegrations)}
        className="underline text-blue-600"
      >
        {showIntegrations ? 'Close Place Integrations' : 'Show Place Integrations'}
      </button>

      {showIntegrations && sortedPlaceIntegrations && (
        <div className="space-y-4 mt-4">
          <div className="font-bold">Place Integrations:</div>
          {selectedIntegration && (
            <AdminManageIntegration
              onChange={onChange}
              setSelectedIntegration={setSelectedIntegration}
              selectedIntegration={selectedIntegration}
            />
          )}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">Region</th>
                <th className="border border-gray-300 px-4 py-2">Place</th>
                <th className="border border-gray-300 px-4 py-2">Date Added</th>
                <th className="border border-gray-300 px-4 py-2">Type</th>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Status</th>
                <th className="border border-gray-300 px-4 py-2">Permissions</th>
                <th className="border border-gray-300 px-4 py-2">Last Run</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedPlaceIntegrations.map((pi) => (
                <tr
                  key={pi.uuid}
                  className={`${pi.status === 'new' ? 'bg-orange-200' : 'bg-white'}`}
                >
                  <td className="border border-gray-300 px-4 py-2">
                    <Link to={`/region/${pi.place.region.urlName}`} className="underline text-blue-600 hover:text-blue-800">
                      {pi.place.region.name}
                    </Link>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <Link to={`/region/${pi.place.region.urlName}/place/${pi.place.urlName}`} className="underline text-blue-600 hover:text-blue-800">
                      {pi.place.name}
                    </Link>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {new Date(pi.createdAt).toLocaleString('en-US')}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">{pi.integrationType.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{pi.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{pi.status.toUpperCase()}</td>
                  <td className="border border-gray-300 px-4 py-2">{pi.action.toUpperCase()}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    <LastRunInfo placeIntegration={pi} />
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="space-y-2">
                      <button
                        className="mt-4 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded m-1"
                        onClick={() => setSelectedIntegration(selectedIntegration?.id === pi.id ? null : pi)}
                      >
                        {selectedIntegration?.id === pi.id ? 'Close Manager' : 'Manage Integration'}
                      </button>
          
                      <button onClick={()=>handleQueueRun(pi.uuid)} className="mt-4 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded m-1">
        Queue Run
      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AdminPlaceIntegrations;
