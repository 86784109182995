// MapComponent.js
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Import the marker icon and shadow
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = ({ position }) => {
    // Create a new icon instance with the imported images
    const selectedDotIcon = new L.divIcon({
      className: 'custom-dot-marker bg-black w-14 h-14 rounded-full ',
      html: '<div></div>',
      iconSize: [32, 32],
      iconAnchor: [16, 16]
    });
  // Set the default icon for all Leaflet markers
  L.Marker.prototype.options.icon = selectedDotIcon;
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement.invalidateSize();
    }
  }, []);

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: '400px', width: '100%' }} // Set a specific height
      scrollWheelZoom={false}
      dragging={false}
      touchZoom={false}
      doubleClickZoom={false}
      zoomControl={false}
      attributionControl={false}
      className='z-0'
      whenCreated={map => (mapRef.current = map)}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        />
      <Marker position={position} />
    </MapContainer>
  );
};

export default MapComponent;
