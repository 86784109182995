import React from "react";
import OrganizerInfo from "./OrganizerInfo";
import '../../custom.css'
import DeleteOrganizer from "./DeleteOrganizer";

function DisplayOrganizers({organizers, places, edit, gatekeeper, onChange,region}) {

    return (
        organizers ? <div className=" masonry-grid">

            {organizers.map((organizer)=>
            <div className="masonry-grid-item p-1" key={organizer.id}>
             {edit &&  <DeleteOrganizer organizer={organizer} onChange={onChange} />}
                <OrganizerInfo region={region} organizer={organizer} places={places} light={true}  />
            </div>
        )}
        </div> : 
        <div>No Organizers</div>

    )

    }
export default DisplayOrganizers