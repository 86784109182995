import React, { useEffect, useState } from 'react';
import LoginForm from '../../components/user/LoginForm';
import { useParams, useLocation } from 'react-router-dom';

function LoginPage() {
  const { '*': wildcardParam } = useParams();
  const [nextPage, setNextPage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectParam = params.get('redirect');
    
    if (redirectParam) {
      setNextPage(redirectParam);
    } else if (wildcardParam) {
      setNextPage(wildcardParam);
    }
  }, [wildcardParam, location.search]);

  useEffect(() => {
    document.title = `Login - Zeitgeists`;
  }, []);

  return (
    <div className='flex min-h-full flex-col justify-center'>
      <LoginForm  nextPage={nextPage} />
    </div>
  );
}

export default LoginPage;
