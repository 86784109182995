import React from 'react';
import { Controller } from 'react-hook-form';

function PickRegion({ control, setValue,regions }) {




  const handleRegionChange = (pid) => {
    setValue('regionId', pid); // Set the placeId to the selected one

    // Ensure the types are consistent: Convert pid to a number before comparing
    const selectedRegion = regions.find((pp) => pp.id === Number(pid));

    if (selectedRegion) {
        console.log(selectedRegion);
        setValue('region', selectedRegion); // Save the selected place using setValue
    } else {
        console.log("Region not found");
    }
};


  return (
    <div className="mb-6">
      <Controller
        control={control}
        name="regionId"
        rules={{ required: 'Selecting a region is required' }}
        render={({ field, fieldState: { error } }) => (
          <div>
            <label htmlFor="regionSelect" className="block mb-2 text-sm font-medium text-gray-900">
              Select a Region:
            </label>
            <select
              id="regionSelect"
              {...field}
              value={field.value ?? ""}  
              onChange={(e) => handleRegionChange(e.target.value)}
              className="block w-full bg-gray-50 border text-gray-900 border-gray-300 px-4 py-2 rounded"
            >
              <option value="">Select a region</option>
              {regions.map((region) => (
                <option key={region.id} value={region.id}>
                  {region.name}
                </option>
              ))}
            </select>
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        )}
      />
    </div>
  );
}

export default PickRegion;
