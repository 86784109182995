import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConfirmModal from '../utilities/ConfirmModal';
function ManageLocations({ locations, onChange }) {
    const [sortedLocations, setSortedLocation] = useState([]);
    useEffect(() => {
        setSortedLocation(Array.isArray(locations)
        ? locations.slice().sort((a, b) => {
            // Sort hide location last
            if (a.hide && !b.hide) return 1;
            if (!a.hide && b.hide) return -1;
    
            // Sort by number of listings first (descending)
            if (b.listings.length !== a.listings.length) {
              return b.listings.length - a.listings.length;
            }
    
            // If listings count is equal, sort by creation date (most recent first)
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
        : [])
    },[locations])


  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [confirmModal, setConfirmModal] = useState({ isOpen: false, action: null, locationId: null });

  const handleEdit = (id, key, newValue) => {
    setSelectedLocation({ ...selectedLocation, [key]: newValue });
  };

  const handleSave = (id) => {
    const updatedLocations = sortedLocations.map((s) => (s.id === id ? { ...selectedLocation } : s));
    onChange(updatedLocations, updatedLocations.filter((s) => s.id === id)[0],'update');
  };

  const toggleExpandRow = (locationId) => {
    if (expandedRow === locationId) {
      setExpandedRow(null);
      setSelectedLocation(null);
    } else {
      setExpandedRow(locationId);
      setSelectedLocation(sortedLocations.find((s) => s.id === locationId));
    }
  };
  const handleDelete = (id) => {
    setConfirmModal({ isOpen: true, action: 'delete', locationId: id });
  };

  const handleHide = (id) => {
    setConfirmModal({ isOpen: true, action: 'hide', locationId: id });
  };

  const confirmAction = () => {
    const { action, locationId } = confirmModal;

    if (action === 'delete') {
      const updatedLocations = sortedLocations.filter((s) => s.id !== locationId);
      onChange(updatedLocations, sortedLocations.filter((s) => s.id === locationId)[0],'delete'); //deleted from updated

    } else if (action === 'hide') {
      const updatedLocations = sortedLocations.map((s) => (s.id === locationId ? { ...s, hide: !s.hide } : s));
      onChange(updatedLocations, updatedLocations.filter((s) => s.id === locationId)[0],'hide');
    }

    setConfirmModal({ isOpen: false, action: null, locationId: null });
  };

  const cancelAction = () => {
    setConfirmModal({ isOpen: false, action: null, locationId: null });
  };

  return (
    <div className="p-4">
      {sortedLocations && sortedLocations.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow overflow-hidden rounded-md hidden md:table">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Listings</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedLocations.map((s) => (
                <React.Fragment key={s.id}>
                  <tr className="border-b">
                    <td className="px-6 py-4 whitespace-nowrap">
                        <span className="font-bold">{s.name}</span>
                        <br />
                        {s.alternateName}
                    </td>
                    <td className="px-6 py-4" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
            
                      {s.description && s.description.length > 100 ? `${s.description.substring(0, 100)}...` : s.description}
                      <br />
                      <a className="text-blue-500 underline" target="_blank" href={s.url}>
                        {s.url}
                      </a>
                    </td>
                    <td className="px-6 py-4 ">
                        {s.streetAddress}, {s.addressLocality}, {s.addressRegion} {s.postalCode}, {s.addressCountry}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{s.listings && s.listings.length}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{new Date(s.createdAt).toLocaleDateString()}</td>
                    <td className="px-6 py-4">
                      <button className="px-2 py-1 text-white bg-blue-500 hover:bg-blue-700 rounded-md text-sm" onClick={() => toggleExpandRow(s.id)}>
                        {expandedRow === s.id ? 'Collapse' : 'Manage'}
                      </button>
                     <button className=" m-1 px-2 py-1 text-white bg-orange-500 hover:bg-orange-700 rounded-md text-sm" onClick={() => handleHide(s.id)}>
                        {s.hide ? 'Unhide' : 'Hide'}
                      </button>
                      {s.listings.length===0&&<button className="m-1 px-2 py-1 text-white bg-red-500 hover:bg-red-700 rounded-md text-sm" onClick={() => handleDelete(s.id)}>
                        Delete
                      </button>}
                    </td>
                  </tr>
                  {expandedRow === s.id && (
                    <tr>
                      <td colSpan="6" className="px-6 py-4 bg-gray-50">
                        <div className="flex flex-col space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                              type="text"
                              value={selectedLocation.name}
                              onChange={(e) => handleEdit(s.id, 'name', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Alternative Name</label>
                            <input
                              type="text"
                              value={selectedLocation.alternateName}
                              onChange={(e) => handleEdit(s.id, 'alternateName', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">URL</label>
                            <input
                              type="text"
                              value={selectedLocation.url}
                              onChange={(e) => handleEdit(s.id, 'url', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                              rows={10}
                              value={selectedLocation.description}
                              onChange={(e) => handleEdit(s.id, 'description', e.target.value)}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            />
                          </div>

                            <div className="mb-6">
                            <label className="block text-sm font-medium text-gray-700">Street Address: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.streetAddress}
                                 onChange={(e) => handleEdit(s.id, 'streetAddress', e.target.value)}
                                />   
                            </div>
                            <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">City: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.addressLocality}
                                   onChange={(e) => handleEdit(s.id, 'addressLocality', e.target.value)}
                                />   
                            </div>
                                <div className="mb-6">
                                <label
                                 className="block mb-2 text-sm font-medium text-gray-900 ">State Acronym: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.addressRegion}
                              onChange={(e) => handleEdit(s.id, 'addressRegion', e.target.value)}
                              />   
                                </div>
                                <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Postal Code: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.postalCode}
                              onChange={(e) => handleEdit(s.id, 'postalCode', e.target.value)}
                               />   
                                </div>

                            <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">Neighborhood: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.area}
                              onChange={(e) => handleEdit(s.id, 'area', e.target.value)}
                              />   
                            </div>
            
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Latitude: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.latitude}
                              onChange={(e) => handleEdit(s.id, 'latitude', e.target.value)}
                                />        
                                </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">Longitude: </label>
                                <input 
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                              value={selectedLocation.longitude}
                              onChange={(e) => handleEdit(s.id, 'longitude', e.target.value)}
                               />       
                                </div>




                          <div className="mt-4">
                            <button onClick={() => handleSave(s.id)} className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded-md">
                              Save
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {/* Mobile Version */}
          <div className="md:hidden">
            {sortedLocations.map((s) => (
              <div key={s.id} className="border border-gray-200 rounded-md mb-4 p-4 shadow-sm">
                {/* Header with Location Name and Manage button */}
                <div className="flex justify-between items-center">
                    {s.name}
                    <br></br>
                  <button className="text-xs text-blue-500 underline" onClick={() => toggleExpandRow(s.id)}>
                    {expandedRow === s.id ? 'Collapse' : 'Manage'}
                  </button>
                </div>

                {/* Location details */}
                <div className="text-sm text-gray-500 mt-2">
                  {s.alternateName && <p>Alternate Name: {s.alternateName}</p>}
                  {s.url && (
                    <p>
                      URL:
                      <a className="text-blue-500 underline" target="_blank" href={s.url}>
                        {s.url}
                      </a>
                    </p>
                  )}
                  {s.description && (
                    <p>
                      Description: {s.description.length > 100 ? `${s.description.substring(0, 100)}...` : s.description}
                    </p>
                  )}
                </div>
                <div className="text-sm text-gray-500 mt-2">
                {s.streetAddress}, {s.addressLocality}, {s.addressRegion} {s.postalCode}, {s.addressCountry}
                </div>
                <div className="text-sm text-gray-500 mt-2">Listings: {s.listings && s.listings.length}</div>
                <div className="text-sm text-gray-500">Created: {new Date(s.createdAt).toLocaleDateString()}</div>

                {/* Expanded Row for editing and managing */}
                {expandedRow === s.id && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-md">
                    {/* name */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">Name</label>
                      <input
                        type="text"
                        value={selectedLocation.name}
                        onChange={(e) => handleEdit(s.id, 'name', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    {/* alternateName */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">Alternate Name</label>
                      <input
                        type="text"
                        value={selectedLocation.alternateName}
                        onChange={(e) => handleEdit(s.id, 'alternateName', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>

                  
                    {/* Url */}
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700">External URL</label>
                      <input
                        type="text"
                        value={selectedLocation.url}
                        onChange={(e) => handleEdit(s.id, 'url', e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                    {/* description */}
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">Description</label>
                                          <textarea
                                            rows={10}
                                            value={selectedLocation.description}
                                            onChange={(e) => handleEdit(s.id, 'description', e.target.value)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                          />
                                        </div>

                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">Street Address:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.streetAddress}
                                            onChange={(e) => handleEdit(s.id, 'streetAddress', e.target.value)}
                                          />
                                        </div>
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">City:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.addressLocality}
                                            onChange={(e) => handleEdit(s.id, 'addressLocality', e.target.value)}
                                          />
                                        </div>
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">State Acronym:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.addressRegion}
                                            onChange={(e) => handleEdit(s.id, 'addressRegion', e.target.value)}
                                          />
                                        </div>
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">Postal Code:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.postalCode}
                                            onChange={(e) => handleEdit(s.id, 'postalCode', e.target.value)}
                                          />
                                        </div>
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">Neighborhood:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.area}
                                            onChange={(e) => handleEdit(s.id, 'area', e.target.value)}
                                          />
                                        </div>
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">Latitude:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.latitude}
                                            onChange={(e) => handleEdit(s.id, 'latitude', e.target.value)}
                                          />
                                        </div>
                                        <div className="mt-2">
                                          <label className="block text-sm font-medium text-gray-700">Longitude:</label>
                                          <input
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            value={selectedLocation.longitude}
                                            onChange={(e) => handleEdit(s.id, 'longitude', e.target.value)}
                                          />
                                        </div>
        

                    {/* Action Buttons */}
                    <div className="mt-4 flex space-x-2">
                      <button
                        onClick={() => handleSave(s.id)}
                        className="px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded-md"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => handleHide(s.id)}
                        className="px-4 py-2 bg-orange-500 hover:bg-orange-700 text-white rounded-md"
                      >
                        {s.hide ? 'Unhide' : 'Hide'}
                      </button>
                      {s.listings.length===0&&<button
                        onClick={() => handleDelete(s.id)}
                        className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded-md"
                      >
                        Delete
                      </button>}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* Confirm modal for delete/hide actions */}
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        onClose={cancelAction}
        onConfirm={confirmAction}
        title={confirmModal.action === 'delete' ? 'Confirm Delete' : 'Confirm Hide'}
        message={
          confirmModal.action === 'delete'
            ? 'Are you sure you want to delete this location? This action cannot be undone.'
            : 'Are you sure you want to hide this location?'
        }
      />
    </div>
  );
}

export default ManageLocations;
