import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import EditOrganizer from '../../components/organizer/EditOrganizer';
import LoadingScreen from '../../components/utilities/LoadingScreen';
import EditOrganizerBar from '../../components/utilities/EditOrganizerBar';

function EditOrganizerSettings() {
  const navigate = useNavigate();
  const { regionName, organizerName } = useParams();
const [loading, setLoading] = useState(true);
  const [organizerData, setOrganizerData] = useState({
    organizer: null,
    places: null,
    region: null,
    count: 0,
    scrapes: null,
    suggestions: [],
    lastEdit: null,
    seriesCount: 0,
    integrationsCount: 0,
    suggestionsCount: 0,
    power: false,
  });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  useEffect(() => {
      fetchOrganizerDetails();
  
  }, []);
  useEffect(() => {
    if (organizerData.organizer) {
      document.title = `Edit ${organizerData.organizer.name} - Zeitgeists`;
    } else {
      document.title = `Organizer - Zeitgeists`;
    }
  }, [organizerData.organizer]);

  const fetchOrganizerDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}/edit`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizerData({
          organizer: data.organizer,
          places: data.places || [],
          region: data.region,
          count: data.count || 0,
          scrapes: data.scrapes,
          suggestions: data.suggestions || [],
          lastEdit: data.lastEdit,
          seriesCount: data.seriesCount || 0,
          integrationsCount: data.integrationsCount || 0,
          suggestionsCount: data.suggestionsCount || 0,
          power: data.power,
        });
        setLoading(false);
      } else if (response.status === 401) {
        navigate(`/login?redirect=/region/${regionName}/organizer/${organizerName}/settings`);
      }  if (response.status === 403) {
        navigate(`/region/${regionName}/organizer/${organizerName}`);
      } 
    } catch (error) {
      console.error('Network or other error:', error);
    }
  };
// add, settings, people, dashboard, places,

  return (
    <LoadingScreen isLoading={loading}  message="Fetching organizer settings...">
{organizerData && organizerData.region && organizerData.organizer  &&   <div className='p-4'>


<EditOrganizerBar region={organizerData.region} organizer={organizerData.organizer} active={"/settings"} />
<div className="content flex-grow">

      
<EditOrganizer onChange={()=>fetchOrganizerDetails()} organizer={organizerData.organizer}/>
</div>
 <div className="mt-60">
  <p className='font-bold underline'>Details</p>
  <div>
      <p><strong>UUID:</strong> - <span onClick={() => copyToClipboard(organizerData.organizer.uuid)} style={{ cursor: 'pointer', color: 'blue' }}>{organizerData.organizer.uuid}</span></p>
      <p><strong>ID:</strong> - <span onClick={() => copyToClipboard(organizerData.organizer.id)} style={{ cursor: 'pointer', color: 'blue' }}>{organizerData.organizer.id}</span></p>
      <p><strong>REGION ID:</strong> - <span onClick={() => copyToClipboard(organizerData.region.id)} style={{ cursor: 'pointer', color: 'blue' }}>{organizerData.region.id}</span></p>
      <p><strong>REGION UUID:</strong> - <span onClick={() => copyToClipboard(organizerData.region.uuid)} style={{ cursor: 'pointer', color: 'blue' }}>{organizerData.region.uuid}</span></p>
    </div>
</div> 


    </div>}
    </LoadingScreen>

  );
}

export default EditOrganizerSettings;
