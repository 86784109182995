import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full p-4 bg-black text-white">
      <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0">
        <div className="flex space-x-4 text-center">
          <Link
            to="/about"
            className="text-lg hover:underline focus-visible:no-underline underline-offset"
          >
            Contact
          </Link>
          <span>•</span>
          <Link
            to="/terms"
            className="text-lg hover:underline focus-visible:no-underline underline-offset"
          >
            Terms
          </Link>
          <span>•</span>
          <Link
            to="/privacy"
            className="text-lg hover:underline focus-visible:no-underline underline-offset"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="mt-2 md:mt-0 md:ml-4 text-center">
          <p>&copy; Zeitgeists {currentYear}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
