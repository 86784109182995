import React from 'react';
import { Link } from 'react-router-dom';

const OrganizersCTA = ({ organizer, }) => {
  return (
    <div className="p-4 bg-white shadow-md rounded border-black border mt-2">
      <h2 className="text-xl font-semibold mb-3">Manage {organizer.name}</h2>
      <p className="mb-3">
        Zeitgeists.org provides organizers a platform to claim, manage, and showcase their events. Help keep cultural data accessible and accurate.
      </p>
      <div className="flex flex-col sm:flex-row justify-center gap-3">
        {organizer.owns.length < 1 ? (
          <a 
            href={`mailto:claim@zeitgeists.org?subject=Claim: ${organizer.name}`} 
            className="px-3 py-2 bg-green-200 text-black text-sm font-medium rounded hover:bg-green-500">
            Claim Your Page
          </a>
        ) : (
          <span className="px-3 py-2 bg-green-200 text-black text-sm font-medium rounded">
            Organizer Claimed
          </span>
        )}
        <Link 
          to={`/region/${organizer&&organizer.region.urlName}/organizer/${organizer.urlName}/edit`} 
          className="px-3 py-2 bg-orange-200 text-black text-sm font-medium rounded hover:bg-orange-500">
          Update Listings
        </Link>
      </div>
    </div>
  );
};

export default OrganizersCTA;
