import React, { useEffect, useRef } from 'react';
import {  Link } from 'react-router-dom';
import ProfileImage from '../user/ProfileImage';

import MapComponent from '../utilities/MapComponent';


function LocationInfo({ listing, location, region}) {
  
  return (
      <div className={'m-1 text-center border-2 rounded-lg p-2 border-black' } >
      {location && (
        <div key={location.id} >
                            <h1 className="text-lg font-bold">Location</h1>

                <div className='m-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {location.image  && <ProfileImage url={location.image} alt={location.alt} />}
                </div>   
                <h2 className="text-2xl font-semibold mt-4">{location.name}</h2>
   
          
    {location.url&& <a
      href={location.url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:underline break-all"
    >
      {location.url}
    </a>}



                    {!listing && region  &&
            <p className="mt-2">
              Region:{' '}
              <Link className='text-blue-500 hover:underline' to={`/region/${region.urlName}`}>
                {region.name}
              </Link>
            </p>
          }
                    {location.area && <p className="mt-2">Neighborhood: {location.area}</p>}

                   
         
          {location.description && <p className="mt-2">{location.description}</p>}
          {location.streetAddress && <p className="mt-2">Address: {location.streetAddress}, {location.addressLocality}, {location.addressRegion} {location.postalCode}, {location.addressCountry}</p>     }

        
               {location.latitude && location.longitude &&!isNaN(location.latitude)  && !isNaN(location.longitude)  && (
            <div className="mt-2">
              <MapComponent position={{lat:location.latitude, lng: location.longitude }} />
            </div>
          )}
        </div>
      )}
      </div>
  

  );
}

export default LocationInfo;
