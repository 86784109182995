import React, { useState,useEffect } from 'react';
function ScreeningForm({ setMovieData, movieData,listing}) {
  const [formData, setFormData] = useState({
    worksPresented: [{ name: '', duration: '', director: '', year: '', videoFormat: '' , description: '', inLanguage: '', subtitleLanguage: '', countryOfOrigin: '', productionCompany: '', TMDB: '' , about: '', abstract: '', genre: ''}],
})


const [notice, setNotice] = useState('');
const [lastAddedMovieIndex, setLastAddedMovieIndex] = useState(null);
//map in movie data here

const [currentMovieIndex, setCurrentMovieIndex] = useState(null);
const openMovieDetails = (index) => {
  setCurrentMovieIndex(index);
};

const closeMovieDetails = () => {
  setCurrentMovieIndex(null);
}; 
const [error, setError] = useState(null);


const handleChange = (e) => {
  const { name, value } = e.target;


    const worksPresented = [...formData.worksPresented];
    const [nul,movieIndex, fieldName] = name.split('.');   
      
    if (fieldName === 'videoFormat') {
      if (value === 'custom') {
        // Handle 'custom' format
        worksPresented[movieIndex]['videoFormat'] = value;
        worksPresented[movieIndex]['customFormat'] = '';
      } else {
        // Handle other formats
        worksPresented[movieIndex][fieldName] = value;
        // You may want to reset 'customFormat' here if necessary.
      }
    } else {
      worksPresented[movieIndex][fieldName] = value;
    }
    setFormData({ ...formData, worksPresented });
  
};

const addMovieField = () => {
  const newMovie = {
    name: '',
    duration: '',
    director: '',
    year: '',
    videoFormat: '',
    customFormat: '',
    description: '',
    inLanguage: '',
    subtitleLanguage: '',
    countryOfOrigin: '',
    productionCompany: '',
    TMDB: '',
    genre: '',
    subtitleLanguage: '',
    poster: '',
  };
  setFormData({
    ...formData,
    worksPresented: [...formData.worksPresented, newMovie] ,
  });
};

const removeMovie = (indexToRemove) => {
  if (indexToRemove >= 0 && indexToRemove < formData.worksPresented.length) {
    const worksPresented = [...formData.worksPresented];
    worksPresented.splice(indexToRemove, 1);
    setFormData({ ...formData, worksPresented });
  }
};
useEffect(() => {
  if(movieData) {
    setFormData({
       worksPresented: movieData.worksPresented ? movieData.worksPresented : [{ name: '', duration: '', director: '', year: '', videoFormat: '' , description: '', inLanguage: '', subtitleLanguage: '', countryOfOrigin: '', productionCompany: '', TMDB: '' , about: '', abstract: '', genre: '',poster: ''}],
   })}
}, []);

useEffect(() => {

  setMovieData(formData);
}, [formData]);

useEffect(()=>{
  if(listing) {
    setFormData({
       worksPresented: listing.worksPresented ? listing.worksPresented : [{ name: '', duration: '', director: '', year: '', videoFormat: '' , description: '', inLanguage: '', subtitleLanguage: '', countryOfOrigin: '', productionCompany: '', TMDB: '' , about: '', abstract: '', genre: '', poster: ''}],
   })}

}, [listing])
useEffect(()=>{
  if(movieData){
    
  }
},[movieData])
const [searchQuery, setSearchQuery] = useState('');
const [searchResults, setSearchResults] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const resultsPerPage = 5;
const handleSearch = async () => {
  try {
    const response = await fetch(`/api/tmdbsearch?query=${searchQuery}`, {credentials: 'include'});
    if (!response.ok) throw new Error("Network response was not ok"); 
    const data = await response.json();
    if (data) {
      setSearchResults(data.results);
      setError(null);
    } else {
      setSearchResults([]);
      setError("No results found.");
    }
    setCurrentPage(1);
  } catch (error) {
    console.error('Error fetching search results:', error);
    setSearchResults([]);

    setError('Failed to fetch search results. Please check your network connection. Or add movie manually');
  }
};
const lastResultIndex = currentPage * resultsPerPage;
  const firstResultIndex = lastResultIndex - resultsPerPage;
  const currentResults = searchResults.slice(firstResultIndex, lastResultIndex);

  const totalPages = Math.ceil(searchResults.length / resultsPerPage);

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const handleClear = () => {
    setSearchQuery('');
    setSearchResults([]);
    setCurrentPage(1);
  
  }

  const addMovie = async (movieId) => {
    try {
      const response = await fetch(`/api/tmdbmovie/${movieId}`,{credentials: 'include' });
      const movie = await response.json();
      console.log(movie);
      const newMovie = {
        name: movie.title || '',
        duration: movie.runtime || '',
        director: movie.director || '',
        year: movie.release_date ? movie.release_date.split('-')[0] : '',
        videoFormat: '',
        customFormat: '',
        description: movie.overview || '',
        inLanguage: movie.original_language || '',
        subtitleLanguage: '',
        countryOfOrigin: movie.production_countries && movie.production_countries[0]?.iso_3166_1 || '',
        productionCompany: movie.production_companies && movie.production_companies[0]?.name || '',
        TMDB: movieId,
        genre: movie.genres && movie.genres.map(genre => genre.name).join(', '),
        poster: movie.poster_path ? `https://image.tmdb.org/t/p/original/${movie.poster_path}` :  '',
      };
  
      // Find the index of the first empty movie entry
      const emptyIndex = formData.worksPresented.findIndex(movie => !movie.name);
  
      // Update the first empty entry if it exists, otherwise add a new entry
      const updatedMovies = [...formData.worksPresented];
      if (emptyIndex !== -1) {
        updatedMovies[emptyIndex] = newMovie;
      } else {
        updatedMovies.push(newMovie);
      }
  
      setFormData({
        ...formData,
        worksPresented: updatedMovies,
      });
  
      setNotice('Movie added successfully!');
      setSearchQuery('');
      setSearchResults([]);
      setLastAddedMovieIndex(emptyIndex !== -1 ? emptyIndex : formData.worksPresented.length);
      setTimeout(() => setNotice(''), 3000); // Clear the notice message after 3 seconds
    } catch (error) {
      console.error('Error adding movie:', error);
      setNotice('Failed to add movie.');
      setTimeout(() => setNotice(''), 3000); // Clear the notice message after 3 seconds
    }
  };
  
  
  
  return (
    <div>


        
        <div>
          {<div>           
        {/* Fields for movies */}
        <div className="mb-6">
  <label className="block mb-2 text-sm font-medium text-gray-900">
    Search for a Film:
  </label>
  <p className="text-sm text-gray-600 mb-2">
    This will retrieve information from the TMDB database.
  </p>


  <input
    className="w-full p-2 mb-2 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
    type="text"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search for movies..."
  />
  <button onClick={handleSearch} type="button" className="mr-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
    Search
  </button>
  <button onClick={handleClear} type="button" className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">
    Clear
  </button>
  {notice && <div className="notice">{notice}</div>}
  <div className=" mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
  {error && <div className="error-message">{error}</div>}
  {currentResults.map((movie) => (
    <div key={movie.id} className="max-w-xs rounded overflow-hidden shadow-lg">
      <img className="w-full h-24 object-cover" src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`} alt={movie.title} />
      <div className="px-3 py-2">
        <div className="font-bold text-sm mb-1 truncate">{movie.title}</div>
        <p className="text-xs text-gray-700 ">{movie.overview}</p>
      </div>
      <div className="px-3 py-1 flex justify-between items-center">
        <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700">{movie.release_date}</span>
        <a target='_blank' href={`https://www.themoviedb.org/movie/${movie.id}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 text-xs rounded">
          TMDB
        </a>
        <button
  className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 text-xs rounded"
  type="button"
  onClick={() => addMovie(movie.id)}
>
  Add
</button>
      </div>
    </div>
  ))}
</div>
<div className="flex justify-center space-x-2 mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
          type='button'
            key={index + 1}
            className={`py-1 px-3 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => changePage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>

</div>

<div className="mb-6 text-lg">
  Movies:
  </div>

        {formData.worksPresented.map((movie, index) => (
          <div key={index}>
            <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Title:
      </label>

      <input
      className="input-style"
                type="text"
                value={movie.name || ''}
                name={`worksPresented.${index}.name`}
                onChange={handleChange}
              />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Director(s):
      </label>

      <input
      className="input-style"
                type="text"
                value={movie.director || ''}
                name={`worksPresented.${index}.director`}
                onChange={handleChange}
              />
    </div>
    <div className="mb-6 w-full grid grid-cols-2 gap-4">
      <div className="col-span-2 md:col-span-1">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Duration in Minutes:
      </label>

      <input
            className="input-style"

                type="number"
                min="1"
                max="720"
                value={movie.duration || ''}
                name={`worksPresented.${index}.duration`}
                onChange={handleChange}
              />

    </div>
    <div className="col-span-2 md:col-span-1">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Release Year:
      </label>

      <input
            className="input-style"
            type="number"
            min="1878"
            max="2100"
            value={movie.year || ''}
            name={`worksPresented.${index}.year`}
            onChange={handleChange}
          />

    </div>
    </div>

    
            
            
          <div className="mb-6">
          <label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900 ">
              Movie Format:
              </label>

              <select
                value={movie.videoFormat || ''}
                name={`worksPresented.${index}.videoFormat`}
                onChange={handleChange}
                className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option  disabled value=""> Select a format </option>
                  <option value="Digital">Digital</option>
                <option value="35mm">35mm</option>
                <option value="DCP">DCP</option>
                <option value="70MM">70MM</option>
                <option value="16MM">16MM</option>
                <option value="VCR">VCR</option>
                {/* Add other predefined options */}
                <option value="other">Other</option>
              </select>
            {/* Render custom input if the user selects "Other" */}
            {movie.videoFormat === 'custom' && (
              <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                Format:
                </label>

                <input
                  type="text"
                  className="input-style"
                  value={movie.customFormat || ''}
                  name={`worksPresented.${index}.customFormat`}
                  onChange={handleChange}
                />
                </div>
            )}
            </div>
    
{currentMovieIndex !== index && <div className="mb-6"><button className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" type="button" onClick={() => openMovieDetails(index)}>
                Edit Movie Details
              </button></div>}
                          {currentMovieIndex === index && <div>
                
                <div className="mb-6">
                <label  className="block mb-2 text-sm font-medium text-gray-900 ">
              Description:
              </label>
              <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " name={`worksPresented.${index}.description`}  value={movie.description|| ''} onChange={handleChange} />
                </div>
            <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              Movie Language:
            </label>
            <input className="input-style" type="text" name={`worksPresented.${index}.inLanguage`}  value={movie.inLanguage|| ''} onChange={handleChange} />
              </div>
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              Subtitle Language:
            </label>
            <input className="input-style" type="text" name={`worksPresented.${index}.subtitleLanguage`}  value={movie.subtitleLanguage|| ''} onChange={handleChange} />
              </div>

              
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Movie Country:
            </label>
            <input className="input-style" type="text" name={`worksPresented.${index}.countryOfOrigin`} value={movie.countryOfOrigin|| ''} onChange={handleChange} />
              </div>
            
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Production companies:
            </label>
            <input className="input-style" type="text" name={`worksPresented.${index}.productionCompany`} value={movie.productionCompany|| ''} onChange={handleChange} />
              </div>
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            TMDB:
            </label>
            <input className="input-style" type="text" name={`worksPresented.${index}.TMDB`} value={movie.TMDB|| ''} onChange={handleChange} />
              </div>
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Genre:
            </label>
            <input className="input-style" type="text" name={`worksPresented.${index}.genre`} value={movie.genre|| ''} onChange={handleChange} />
              </div>
           <div className="mb-6">
            <button type="button" onClick={closeMovieDetails} className="text-blue-500 hover:underline">
                    Close Movie Details
                  </button>
                  </div>
                                  </div>}
            {index > 0 && (
              <div className="mb-6">
              <button type="button" onClick={() => removeMovie(index)} 
              className=" hover:shadow  text-white bg-red-600  rounded border-red-600 p-2  border-2"
              >
                Remove Movie
              </button>
              </div>
            )}
  
            {/* Add fields for other movie properties */}
          </div>
        ))}
        <div className="mb-6">
        <button type="button" onClick={addMovieField}       className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
>
          Add Another Movie
        </button>
        </div>
      </div>}
          </div>  

    </div>
  );
}

export default ScreeningForm;
