import React from 'react';
import { Link } from 'react-router-dom';
function ZeitgeistAPIDocumentation() {
  const endpoints = [
    {
        "path": "/regions",
        "method": "GET",
        "description": "Retrieve a list of all regions.",
        "parameters": [],
        "response": {
            "status": "success",
            "message": "Regions data retrieved successfully.",
            "data": "Array of regions"
        }
    },
    {
        "path": "/regions/:id",
        "method": "GET",
        "description": "Retrieve details of a specific region by ID.",
        "parameters": ["id: Region ID"],
        "response": {
            "status": "success",
            "message": "Region data retrieved successfully.",
            "data": "Region details"
        }
    },
    {
        "path": "/regions/:id/places",
        "method": "GET",
        "description": "Retrieve all places within a specific region.",
        "parameters": ["id: Region ID"],
        "response": {
            "status": "success",
            "message": "Region places data retrieved successfully.",
            "data": "Object with region and places details"
        }
    },
    {
        "path": "/regions/:id/organizers",
        "method": "GET",
        "description": "Retrieve all organizers within a specific region.",
        "parameters": ["id: Region ID"],
        "response": {
            "status": "success",
            "message": "Region organizers data retrieved successfully.",
            "data": "Object with region and organizers details"
        }
    },
    {
        "path": "/places/:id",
        "method": "GET",
        "description": "Retrieve details of a specific place by ID.",
        "parameters": ["id: Place ID"],
        "response": {
            "status": "success",
            "message": "Place data retrieved successfully.",
            "data": "Place details"
        }
    },
    {
        "path": "/places/:id/organizers",
        "method": "GET",
        "description": "Retrieve all organizers associated with a specific place.",
        "parameters": ["id: Place ID"],
        "response": {
            "status": "success",
            "message": "Place organizers data retrieved successfully.",
            "data": "Object with place and organizers details"
        }
    },
    {
        "path": "/organizers/:id",
        "method": "GET",
        "description": "Retrieve details of a specific organizer by ID.",
        "parameters": ["id: Organizer ID"],
        "response": {
            "status": "success",
            "message": "Organizer data retrieved successfully.",
            "data": "Organizer details"
        }
    },
    {
        "path": "/organizers/:id/places",
        "method": "GET",
        "description": "Retrieve all places associated with a specific organizer.",
        "parameters": ["id: Organizer ID"],
        "response": {
            "status": "success",
            "message": "Organizer places data retrieved successfully.",
            "data": "Object with organizer and places details"
        }
    },
    {
        "path": "/organizers/:id/locations",
        "method": "GET",
        "description": "Retrieve all locations associated with a specific organizer.",
        "parameters": ["id: Organizer ID"],
        "response": {
            "status": "success",
            "message": "Organizer locations data retrieved successfully.",
            "data": "Object with organizer and locations details"
        }
    },
    {
        "path": "/listings/:id",
        "method": "GET",
        "description": "Retrieve details of a specific listing by ID.",
        "parameters": ["id: Listing ID"],
        "response": {
            "status": "success",
            "message": "Listing data retrieved successfully.",
            "data": "Listing details"
        }
    },
    {
        "path": "/listings/",
        "method": "GET",
        "description": "Retrieve a list of listings with optional filtering, sorting, and pagination.",
        "parameters": [
            "type: Filter listings by type",
            "startDate: Filter listings starting from this date. Format: YYYY-MM-DD",
            "endDate: Filter listings up to this date. Format: YYYY-MM-DD",
            "status: Filter listings by status",
            "regionId: Filter listings by region ID",
            "placeId: Filter listings by place ID",
            "page: Page number for pagination (default: 1)",
            "limit: Number of listings per page (default: 20, max: 20)"
        ],
        "response": {
            "status": "success",
            "message": "Filtered listings retrieved successfully.",
            "data": "Paginated listing data with details"
        }
    }
]

  return (

    <div className="max-w-screen-xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">API Documentation</h1>
      <p className="text-gray-700 mb-4">
        This API provides access to information about regions, places, organizers, events listings.
      </p>
      <h3 className="text-lg font-semibold">API Base URL: zeitgeists.org/api/v1/</h3>

      <h2 className="text-xl font-semibold mt-6">Authentication</h2>
      <p>
        All endpoints require authentication using an API key. Please include your API key in the request headers. Create an API key in your <Link to={`/user`} className="text-blue-500 hover:underline">profile page</Link>
      </p>
      <div className="mt-4">
        <p><strong>Request Headers:</strong></p>
        <p><code>Authorization</code>: Your API key.</p>
      </div>

      <h2 className="text-xl font-semibold mt-6">Endpoints</h2>

      {endpoints.map((endpoint,index)=>(<div key={index} className='m-4 '>
                    <h2 className='text-2xl font-medium'>{endpoint.path} ({endpoint.method})</h2>
                    <p>{endpoint.description}</p>
                    {endpoint.parameters.length > 0 && (
                        <ul>
                          <p className='text-lg font-bold'>Parameters</p>
                            {endpoint.parameters.map((param, idx) => (
                                <li key={idx} className=''>{param}</li>
                            ))}
                        </ul>
                    )}
                    <pre className='text-white bg-slate-600 p-3 rounded-lg text-left m-4 overflow-hidden'>{JSON.stringify(endpoint.response, null, 2)}</pre>
                </div>))}
    
    </div>
  )}


  
export default ZeitgeistAPIDocumentation;