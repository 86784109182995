import React, { useEffect, useState } from 'react';
import RegionInfo from '../../components/region/RegionInfo';
import LoadingScreen from '../../components/utilities/LoadingScreen';
function Regions() {
  const [userRegions, setUserRegions] = useState(null);
  const [regions, setRegions] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = 'Regions - Zeitgeists';
  }, []);
  useEffect(() => {
    fetchRegions();

  }, []);



  const fetchRegions = async () => {
    try {
      const response = await fetch('/api/regions', {
        headers: {
          'Content-Type': 'application.json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setRegions(data);
      } else {
        // Handle error message
      }
      setLoading(false);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <LoadingScreen isLoading={loading} message="Fetching regions...">
    <div className="text-center grid grid-cols-4 gap-4 mt-20">
      <div className="col-span-4">
              <p className="text-3xl font-bold">
    Regions
</p>
</div>
        {regions && (
          regions.map((region) => (
           <div className="col-span-4 md:col-span-2" key={region.id}>
            <RegionInfo region={region} />
           </div>
          ))
          )}
    </div>
    </LoadingScreen>
  );
}

export default Regions;
