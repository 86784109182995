import React, { useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
function LoginForm({ nextPage }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);
  const { login } = useContext(AuthContext);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(email, password, nextPage);
      setStatusMessage({ type: 'success', text: 'Login successful!' });
    } catch (error) {
      if (error.message === 'Email not verified') {
        setStatusMessage({
          type: 'error',
          text: (
            <div>
              Email not verified.
              <p className="mt-4">
                <button className="underline" onClick={newEmail}>
                  Click here to resend confirmation email
                </button>
              </p>
            </div>
          ),
        });
      } else {
        setStatusMessage({ type: 'error', text: 'Login failed. Please try again.' });
        setPassword('');
        setEmail('');
      }
    }
  };

  const newEmail = async () => {
    if (email) {
      try {
        const response = await fetch(`/api/new-confirm-token/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setStatusMessage({
            type: 'success',
            text: `Confirmation email from noreply@zeitgeists.org sent to ${email}.`,
          });
        } else {
          setStatusMessage({ type: 'error', text: 'New confirmation email request failed. Try again.' });
        }
      } catch (error) {
        console.error('Error:', error);
        setStatusMessage({ type: 'error', text: 'An error occurred. Please try again later.' });
      }
    }
  };

  const renderMessage = () => {
    if (!statusMessage) return null;
    const { type, text } = statusMessage;

    const messageClasses = type === 'success'
      ? 'border-green-400 bg-green-100 text-green-700'
      : 'border-red-400 bg-red-100 text-red-700';

    return (
      <div className={`mt-5 border px-4 py-3 ${messageClasses}`}>
        <p>{text}</p>
      </div>
    );
  };

  return (
    <div className="px-6 py-12 lg:px-8 mt-36">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-black">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-black">
                Password
              </label>
              <div className="text-sm">
                <Link to="/forgotpassword/" className="font-semibold text-gray-600 hover:text-gray-500">
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-lg font-semibold text-black bg-white border border-black rounded-md shadow-sm hover:bg-black hover:text-white focus:outline-none focus:ring focus:ring-gray-600"
            >
              Sign in
            </button>
          </div>
        </form>

        {renderMessage()}

        <p className="mt-5 text-center text-lg text-gray-500">
          Don't have an account?{' '}
          <Link to={`/signup/${nextPage || ''}`} className="font-semibold leading-6 text-black hover:text-gray-500">
            Sign up now
          </Link>
        </p>
      </div>
    </div>
  );
}

export default LoginForm;
