import { set } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

function PickPlaceByOrganizer({ control, setValue, watch, regionId }) {
  const [places, setPlaces] = useState([]);
  const organizer = watch('organizer');
const place = watch('place');
  useEffect(() => {
    if (organizer) {
      fetchPlacesByOrganizer(organizer.id);
    }
  }, [organizer]);

  const fetchPlacesByOrganizer = async (organizerId) => {
    try {
      const response = await fetch(`/api/region/${regionId}/organizer/${organizerId}/places`, { credentials: 'include' });
      const data = await response.json();
      setPlaces(data);
    } catch (error) {
      console.error('Failed to fetch places', error);
    }
  };

  const handlePlaceChange = (pid) => {
    setValue('nameLocation', null);
    setValue('place', null); // Reset the place object
    setValue('placeId', null); // Reset the placeId
    setValue('locationId', null); // Reset the location
    // Ensure the types are consistent: Convert pid to a number before comparing
    const selectedPlace = places.find((pp) => pp.id === Number(pid));

    if (selectedPlace) {
      setValue('placeId', pid); // Set the placeId to the selected one
        setValue('place', selectedPlace); // Save the selected place using setValue
    } else {
        console.log("Place not found");
    }
};


  return (
    <div className="mt-4 mb-6">
              <p className='text-md font-medium mt-4'>Add Place</p>

      <Controller
        control={control}
        name="placeId"
        rules={{ required: 'Selecting a place is required' }}
        render={({ field, fieldState: { error } }) => (
          <div>
            <label htmlFor="placeSelect" className="block mb-2 text-md font-medium text-gray-900">
            Select a Place connected to the Organizer:
            </label>
            <select
              id="placeSelect"
              {...field}
              value={field.value ?? ""}  
              onChange={(e) => handlePlaceChange(e.target.value)}
              className="block w-full bg-gray-50 border text-gray-900 border-gray-300 px-4 py-2 rounded"
            >
              <option value="">Select a place</option>
              {places.map((place) => (
                <option key={place.uuid} value={place.id}>
                  {place.name}
                </option>
              ))}
            </select>
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        )}
      />
    </div>
  );
}

export default PickPlaceByOrganizer;
