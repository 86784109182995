import React, { useState,useEffect } from 'react';
import ProfileImage from '../user/ProfileImage';
import { useForm } from "react-hook-form"

function EditOrganizer({onChange,organizer}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage,setErrorMessage] = useState('');
  const { register, handleSubmit, setValue } = useForm();
  function SuccessMessage() {
    return (
      <div className="border rounded text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">

Organizer details edited successfully      
</div>
    );
  }
  function FailMessage() {
    return (
      <div className="border rounded text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
      Error editing organizer.
      </div>
    );
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        // File size is within the limit
        setSelectedFile(file);
      } else {
        // File size exceeds the limit
        alert('File size exceeds the 5MB limit');
      }
    }
  };
  useEffect(() => {
    if (organizer) {
      // Set all values on form initialization
      Object.entries(organizer).forEach(([key, value]) => {
          setValue(key, value);
      });

     
    }
  }, [organizer, setValue]);


  const onSubmit = async (data) => {

    if (window.confirm("Confirm edit organizer")!==true) {
      return;
  }
    try {
      const formDataToSend = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (value !== 'null') {
          formDataToSend.append(key, value);
        }
      }
     

      // Check if a new image was selected
      if (selectedFile) {
        formDataToSend.append('image', selectedFile);
      }
      // Send a POST request to your server to save the data
      const response = await fetch(`/api/region/${organizer.region.urlName}/organizer/${organizer.urlName}`, {
        method: 'POST',
        credentials: 'include', // Include cookies
        body: formDataToSend,
      });

      if (response.ok) {
        // Reset the form after successful submission
        setErrorMessage(<SuccessMessage />);
        onChange();
        // Handle success or navigate to a different page
      } else {
        setErrorMessage(<FailMessage />)
        console.error('Error editing organizer:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage />)

      console.error('Error editing organizer:', error);
    }
  };

  return (
    <div className='m-4'>
            <p className="text-lg pb-4">Settings:</p>

      <form onSubmit={handleSubmit(onSubmit)}  className="flex flex-col space-y-4">
        {/* Render input fields for each property in the schema */}
        <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> Organizer Name: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("name", { required: true })} />   
     </div>

     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url", { required: true })} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Email: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("email")} />   
     </div>
     <div>
          <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 ">
            {organizer.image ? "Select a new organizer image (5MB max):" : "Select organizer image (5MB max):"}
          </label>
          {organizer.image && <div className='text-center'>Current Organizer Image:<div className='flex items-center text-center justify-center'><ProfileImage url={organizer.image} alt={organizer.alt} /></div></div>}

          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleFileChange}
            className="input-style mt-2"
          />
          {selectedFile && (
            <p className="mt-1">Selected File: {selectedFile.name}</p>
          )}
        </div>

        {/* Alt Text */}
        <div>
          <label htmlFor="alt" className="block mb-2 text-sm font-medium text-gray-900 ">
            Alt text for image:
          </label>
          <input
            type="text"
            id="alt"
            name="alt"
            {...register("alt")}
            className="input-style"
          />
        </div>
        <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Description: </label>
        <textarea rows="5" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("description")} />   
     </div>
<div className='text-right'>
<button type="submit" className="button-style  font-bold text-xl border p-3 bg-green-200 rounded hover:bg-green-500 ">
     Update Organizer
        </button>
</div>

      </form>
      {errorMessage}
    </div>
  );
}

export default EditOrganizer;
